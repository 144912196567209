import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const BlogComponent = ({ blog }) => (
  <div className="blog">
    <img src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/blogs/${blog.image}`} alt={blog.title} />
    <h6>{blog.subtitle}</h6>
    <h2>{blog.title}</h2>
    <Link to={{
      pathname: '/blog',
      state: { blog: blog }
    }}><button className='read-more'> Read More </button></Link>
  </div>
);

const PopularBlogs = ({ blogs }) => {
  const [showAll, setShowAll] = useState(false);

  // Filter and slice the blogs based on the 'active' property
  const filteredBlogs = showAll
    ? blogs.filter(blog => blog.popular === 1)
    : blogs.filter((blog, index) => index < 4 && blog.popular === 1);
  return (
<>
        <div className="blogs-container">
          {filteredBlogs.map(blog => (
            <BlogComponent key={blog.id} blog={blog} />
          ))}
          {!showAll && blogs.filter(blog => blog.popular === 1).length > 4 && (
          <button className="show-more-blogs"onClick={() => setShowAll(true)}>Show more</button>
        )}
        </div>
        
     </>
  );
};

export default PopularBlogs;
