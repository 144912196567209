import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.checkLogin();

    this.validator = new SimpleReactValidator();

    this.state = {
      oldFormData: {
        image: '',
        title: '',
        description: '',
        event_datetime: '',
        active: 1,
      },
      newFormData: {
        image: '',
        title: '',
        description: '',
        event_datetime: '',
        active: 1,
      },
    };
  }

  componentDidMount() {
    // Check if the component is in edit mode
    const { match } = this.props;
    const { params } = match;
    const { event_id } = params;

    if (event_id) {
      // Fetch event details for editing
      this.fetchEventData(event_id);
    }
  }
   formatToDatetimeLocal = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add 1 to month because months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  fetchEventData = async (event_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}event/${event_id}`);

      if (response.ok) {
        const result = await response.json();

        if (result.status) {
          const { image, title, description, event_datetime, active } = result.event;

          // Set the state with the fetched event data
          this.setState({
            oldFormData : {
              image: image,
              title : title,
              description: title,
              event_datetime: event_datetime,
            },
            newFormData: {
              image: null,
              title: title,
              description: description,
              event_datetime: event_datetime,
            },
          });
        } else {
          console.error('Failed to fetch event details');
        }
      } else {
        console.error('Failed to communicate with the server');
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };

  checkLogin = () => {
    if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
      window.location = '/admin-login';
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      newFormData: { ...prevState.newFormData, [name]: value },
    }));
  };

  handleFileChange = (e) => {
    const { name, files } = e.target;
    this.setState((prevState) => ({
      newFormData: { ...prevState.newFormData, [name]: files[0] },
    }));
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const value = checked ? 1 : 0;

    this.setState((prevState) => ({
      newFormData: { ...prevState.newFormData, [name]: value },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
      const { newFormData } = this.state;
      const { oldFormData } = this.state;
      const { match } = this.props;
      const { params } = match;
      const { event_id } = params;


      const apiURL = `${process.env.REACT_APP_API_URL}update-event`;
      const requestData = new FormData();

      // Append newFormData fields to FormData
      Object.entries(newFormData).forEach(([key, value]) => {
        requestData.append(`newFormData[${key}]`, value);
      });
  
      // Append oldFormData fields to FormData
      Object.entries(oldFormData).forEach(([key, value]) => {
        requestData.append(`oldFormData[${key}]`, value);
      });

      try {
        const response = await fetch(apiURL, {
          method: 'PUT',
          body: requestData,
        });

        if (response.ok) {
          const result = await response.json();

          if (result.status) {
            this.props.history.push('/admin/events');
          } else {
            console.error('Failed to save event');
          }
        } else {
          console.error('Failed to communicate with the server');
        }
      } catch (error) {
        console.error('Error saving event:', error);
      }
  };

  render() {
    const { newFormData, oldFormData } = this.state;

    return (
      <>
        <div className="add-event container">
          <form onSubmit={this.handleSubmit}>
            <div className="">
              <div className="form-group">
                <label className="form-label">Title:</label>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={newFormData.title}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Description:</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={newFormData.description}
                  onChange={this.handleInputChange}
                  rows="4"
                />
              </div>
              <div style={{paddingLeft:"20px"}}>
              <img  src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/events/${oldFormData.image}`} alt="" width="500" height="300"  />
              </div>
              <div className="form-group">
                <label className="form-label">Image:</label>
                <input
                  className="form-control"
                  type="file"
                  name="image"
                  onChange={this.handleFileChange}
                />
              </div>
            </div>
            <div className="">
              <div className="form-group">
                <label className="form-label">Event Date time:</label>
                <input
                  className="form-control"
                  type="datetime-local"
                  name="event_datetime"
                  value={this.formatToDatetimeLocal(newFormData.event_datetime)}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <Button
              type="submit"
              severity="success"
              icon="pi pi-check"
              className="m-3 rounded p-button font-bold"
              rounded
              label="submit"
            />
            <Link
              to="/admin/events"
              className="m-3 p-button font-bold"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Cancel
            </Link>
          </form>
        </div>
      </>
    );
  }
}

export default EventForm;
