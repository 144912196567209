import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import Slider from "react-slick";
const BannerLogo = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]

    };
    return (
        // <section ref={ref} onChange={(inView) => handleInView(inView)}>
        // <section className='w-auto'>
        <div className="container banner-container" >
            <Slider {...settings}>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/itc.svg`} alt="" />
                </div >
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/walmart.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/ibm.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/tech-mahindra.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/google.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/squarespace.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/uber.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/am.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/intel-logo.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/apple-logo.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/fb-logo.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/m-logo.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/amazon-logo.svg`} alt="" />
                </div>
                <div className='banner-image'>
                    <img src={`${process.env.PUBLIC_URL}/assets/vector/banner-logo/leap-logo.svg`} alt="" />
                </div>
            </Slider>
            </div>
        // </section>
    );
};

export default BannerLogo;
