

import React, { Component } from 'react';

import Footer from '../common/footer/footer';
import HeaderWhite from '../common/headers/header-white';
class PrivacyPolicy extends Component {

    render() {
        return (
            <>
                <HeaderWhite />
                <section>
                    <div class="container mb-3">
                        <div class="services-quality-wrapper">
                            <div class="">
                                <div>
                                    <h3> Privacy Policy for Internz Learn Internship Programs</h3>
                                    <br />
                                    <h4 class="services-quality-title"> <u>Introduction</u></h4>
                                    <p>Internz Learn ("we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you participate in our internship programs. By participating in our programs, you agree to the terms and practices described in this Privacy Policy.</p></div>
                                <br />
                                <h4 class="services-quality-title mb-4"> <u>Information We Collect	</u></h4>
                                <h4>Personal Information</h4>
                                <p>We may collect personal information directly from you or through third-party sources. Personal information may include, but is not limited to:</p>
                                <p><b>Contact Information:</b> Name, address, email address, phone number.</p>

                                <p><b>Educational and Professional Information:</b> Educational history, work experience, resume, transcripts, and portfolio.</p>

                                <p><b>Demographic Information:</b> Age, gender, and other relevant demographic data.</p>
                                <p><b>Payment Information:</b> If applicable, payment information for program fees.	</p>
                                <p><b>Communication Data:</b> Records of communications between you and Internz Learn.</p>
                                <p><b>Feedback and Survey Responses:</b> Responses to surveys, feedback forms, or evaluations.</p>
                                <p><b>Information Automatically Collected: </b>We may collect information about your device, browsing actions, and usage patterns through cookies and similar technologies when you use our website.</p>

                                <br />

                                <h4 class="services-quality-title mb-4"> <u>How We Use Your Information</u></h4>

                                <p>We may use your personal information for the following purposes:</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Program Administration	</h4>
                                <ul style={{marginLeft: "25px", lineHeight: "2.2"}}>
                                    <li>To evaluate your eligibility for our internship programs.</li>
                                    <li>To process your application and enroll you in our programs.</li>
                                    <li>To provide you with program-related information and updates.	</li>
                                    <li>To communicate with you regarding program details, schedules, and changes.</li>
                                </ul>
                                <br />

                                <h4 class="services-quality-title mb-4">Improving Our Programs	</h4>
                                <ul style={{marginLeft: "25px", lineHeight: "2.2"}}>
                                    <li>To gather feedback and conduct surveys to improve our internship programs.</li>
                                    <li>To analyze program performance and make necessary adjustments.</li>

                                </ul>
                                <br />
                                <h4 class="services-quality-title mb-4"> Marketing and Communications	</h4>
                                <ul style={{marginLeft: "25px", lineHeight: "2.2"}}>
                                    <li>To send you promotional materials, newsletters, and information about our services that may be of interest to you.</li>
                                    <li>To customize and personalize your experience with Internz Learn.</li>

                                </ul>

                                <br />

                                <h4 class="services-quality-title mb-4"> Compliance and Legal Obligations	</h4>
                                <ul style={{marginLeft: "25px", lineHeight: "2.2"}}>
                                    <li>To comply with applicable laws, regulations, and legal processes.</li>
                                    <li>To protect our rights, privacy, safety, or property and that of our affiliates, employees, or other users.</li>
                                    <li>Disclosure of Your Information	</li>
                                </ul>
                                <br />
                                <p>We may share your personal information with third parties under the following circumstances:	</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Service Providers	</h4>
                                <p>We may share your personal information with third-party service providers who assist us in delivering our programs, maintaining our website, processing payments, and other program-related activities.</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Educational Institutions</h4>
                                <p>If you are participating in an internship program through your educational institution, we may share relevant information with your institution to facilitate program enrollment, monitoring, and evaluation.</p>
                                <br />
                                <h4 class="services-quality-title mb-4"> Legal Requirements	</h4>
                                <p>We may disclose your personal information to comply with legal obligations, respond to lawful requests from public authorities, and protect our rights, privacy, safety, or property.	</p>
                                <br />
                                <h4 class="services-quality-title mb-4"> Business Transfers	</h4>
                                <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.	</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Your Rights and Choices</h4>
                                <p>You have certain rights regarding your personal information, including the right to access, correct, or delete your data. You can also opt out of receiving marketing communications from us.</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Security	</h4>
                                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
                                    <br />
                                </p>
                                <h4 class="services-quality-title mb-4">Children's Privacy	</h4>
                                <p>Our programs are not intended for individuals under the age of 16. We do not knowingly collect personal information from children under 16 without parental consent.</p>
                                <p></p>	<br />
                                <h4 class="services-quality-title mb-4">Changes to this Privacy Policy	</h4>
                                <p>We may update this Privacy Policy to reflect changes in our practices or for legal and regulatory reasons. We will notify you of any material changes.	</p>
                                <br />
                                <h4 class="services-quality-title mb-4">Contact Us	</h4>
                                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at +91 9666258029,
                                    info@internzlearn.com	</p>

                                <p>By participating in our internship programs, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }



}
export default PrivacyPolicy;