import React, { Component } from 'react';
import Footer from '../common/footer/footer';
import HeaderWhite from '../common/headers/header-white';
class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: null,
    };
  }

  componentDidMount() {
    // Fetch order details based on the order ID from the URL parameter
    const orderId = this.props.match.params.orderId;
    // Assuming you have an API endpoint to fetch order details, replace 'apiEndpoint' with your actual endpoint
    fetch(`${process.env.REACT_APP_API_URL}orderDetails/${orderId}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ orderDetails: data.data });
      })
      .catch((error) => {
        console.error('Error fetching order details:', error);
      });
  }

  render() {
    const { orderDetails } = this.state;

    return (
      <div>
        <HeaderWhite />
        <h1 style={{margin:"50px", textAlign:"center"}}>Thank You! You have been successfully Enrolled</h1>
        {orderDetails ? (
          <div style={{margin:"0px 0px 80px", textAlign:"center"}}>
            <h3 > An email will be sent to you shortly, within 24 hours.</h3>
            <br />
            <br />
            <p>Order ID: {orderDetails.order_id}</p>
            <p>Transaction ID: {orderDetails.transaction_id} </p>
            <p>Payment Status: {orderDetails.status}</p>
            <p>Amount: {orderDetails.amount}</p>

            {/* Add other order details as needed */}
          </div>
        ) : (
          <p>Loading order details...</p>
        )}
        <Footer />
      </div>
    );
  }
}

export default ThankYouPage;
