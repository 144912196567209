import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

const CountUpBanner = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [startCounting, setStartCounting] = useState(false);

  const handleInView = (inView) => {
    if (inView) {
      setStartCounting(true);
    }
  };

  return (
    // <section ref={ref} onChange={(inView) => handleInView(inView)}>
    // <section className='w-auto'>
      <div className="banner container" >
        <div>
          <h4>
            <CountUp start={startCounting ? 0 : null} end={35000} duration={3} />+
          </h4>
          <h6>Fresh Graduates</h6>
        </div>
        <span>|</span>
        <div>
          <h4>
            <CountUp start={startCounting ? 0 : null} end={100} duration={3} />+
          </h4>
          <h6>Brand Partners</h6>
        </div>
        <span>|</span>
        <div>
          <h4>
            <CountUp start={startCounting ? 0 : null} end={10} duration={3} />+
          </h4>
          <h6>Years of Experience</h6>
        </div>
        <span>|</span>
        <div>
          <h4>
            <CountUp start={startCounting ? 0 : null} end={15000} duration={3} />+
          </h4>
          <h6>Happy Clients</h6>
        </div>
      </div>
      // </section>
  );
};

export default CountUpBanner;
