import React, { Component, useState } from 'react';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';

import FAQ from '../common/layout/pap-faq';
import Cookies from 'js-cookie';
import BannerLogo from '../common/layout/banner-logo';
import CouponsModal from '../common/layout/coupons';
import './../../styles/pap-course.css';

class JavaCourse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewMorePathways: false,
            course_id: '',
            type: '',
            coupon_modal_show: false,
            amount: 0,
        };
    }
    viewMorePathways = () => {
        this.setState({
            viewMorePathways: true,
        });
    }
    handleButtonClick = () => {
        // Check if the user is logged in
        if (Cookies.get('token') === undefined) {
            this.props.history.push('/sign-up')
        } else {
            this.downloadPDF();
        }
    };
    downloadPDF = () => {
        // Replace 'your-pdf-file.pdf' with the actual path to your PDF file
        const pdfPath = process.env.PUBLIC_URL + '/assets/document/pap/curriculum/automation-testing-pap.pdf';

        // Create a temporary anchor element
        const link = document.createElement('a');

        // Set the download attribute and href of the anchor element
        link.download = 'automation-testing-pap.pdf';
        link.href = pdfPath;

        // Trigger a click on the anchor element to start the download
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the document
        document.body.removeChild(link);
    };
    scrollToPricing = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pricing');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    openCouponModal = (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        this.setState({
          amount: amount,
          course_id: course_id,
          type: type,
        }, () => {
          this.setState({
            coupon_modal_show: true,
          });
        });
      };

    closeCouponModal = () => {
        this.setState({
            coupon_modal_show: false,
        })
    };

    startPayment =(amount) =>{
        this.initiatePayment(amount, this.state.course_id, this.state.type)
    }
     

    initiatePayment = async (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        // const { enqData } = this.state;
        // const amountString = document.getElementById(type).innerText
        const amountFloat = parseFloat(amount).toFixed(2);
        // const amountAsNumber = parseFloat(amountFloat);
        const paymentData = {
            user_id: user_id,
            amount: amountFloat,
            course_id: course_id,
            type: type,
        }
        try {
            fetch(`${process.env.REACT_APP_API_URL}` + 'initiate-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            })
                .then(response => response.json())
                .then(data => {
                    // if (data.status) {
                        console.log(data)
                    // }
                    const form = document.createElement('form');
                    form.method = 'post';
                    form.name= 'redirect';
                    form.id = 'nonseamless';
                    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
                    const encRequestInput = document.createElement('input');
                    encRequestInput.type = 'hidden';
                    encRequestInput.name = 'encRequest';
                    encRequestInput.id = 'encRequest';
                    encRequestInput.value = data.data;
                    const accessCodeInput = document.createElement('input');
                    accessCodeInput.type = 'hidden';
                    accessCodeInput.name = 'access_code';
                    accessCodeInput.id = 'access_code';
                    accessCodeInput.value = 'AVDK13KI30BD04KDDB'
                    form.appendChild(encRequestInput);
                    form.appendChild(accessCodeInput)
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }
    render() {
        const { coupon_modal_show, amount } = this.state
        const style = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/auto-test/first.svg)`,
            backgroundSize: 'cover',  // or 'contain' depending on your preference
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return (
            <div style={{ backgroundColor: "#000" }}>
                <HeaderBlack />
                <CouponsModal isOpen={coupon_modal_show} onPay={this.startPayment} amount={amount} onClose={this.closeCouponModal} />
                <section className='pap-first-section' style={style}>
                    <div className="container">
                        <div className="first-container pt-5">
                            <div className='left-container'>
                                <h6 className=''>Placement Assurance Program </h6>
                                <h2>AUTOMATION TESTING</h2>
                                <p>Embark on a captivating expedition through the domain of Automation Testing, uncovering the enchantment of crafting advanced, responsive, and feature-rich applications. Master cutting-edge testing frameworks that refine user experiences and explore back-end technologies ensuring flawless data management and security. Join us as we navigate the intricate web of code and innovation in the realm of Automation Testing, where precision meets creativity.</p>
                                <div className='details-box'>
                                    <div className='details-box-div'>
                                        <h5>Batch <img style={{ marginLeft: "2rem" }} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/batch.svg`} alt="" /></h5>
                                        <h4>Monthly</h4>
                                    </div>
                                    <div className='details-box-div'>
                                        <h5>Available Seats <img style={{ marginLeft: "2rem" }} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/seat.svg`} alt="" /></h5>
                                        <h4>8/60</h4>
                                    </div>
                                </div>

                            </div>
                            <div className='right-container'>
                                <div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/auto-test/first-video-img.svg`} alt="" />
                                    </div>
                                    <button onClick={() => this.scrollToPricing()}>Apply Now </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='second-section'>
                        <div className="company-banner">
                            <p>Hiring Partner's -</p>
                            <div>
                            <BannerLogo />
                            </div>
                        </div>
                </section> 
                <section className='pap-second-section' style={{ backgroundColor: "#000" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>KEY FEATURES</h2>
                            <div className="features-box">
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/first.svg`} alt="" />
                                        <h6>Job Placement Guarantee</h6>
                                        <p>Participants who successfully complete the program are guaranteed assistance in finding a suitable job within a specific time frame.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Customized Training</h6>
                                        <p>The training curriculum is designed to enhance participants' skills and make them job-ready.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/language.svg`} alt="" />
                                        <h6>Skill Development</h6>
                                        <p>This includes technical skills, soft skills, communication skills, and other abilities necessary for a successful career.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Placement Cell</h6>
                                        <p>The placement cells or teams responsible for liaising with companies, arranging interviews, and facilitating job placements for participants.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/teacher.svg`} alt="" />
                                        <h6>Mentorship</h6>
                                        <p>Mentors will be of 10 to 15 years experienced in indutry. Participants will be having 2 mentors dor the doubts clearing sessiosn.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/clock.svg`} alt="" />
                                        <h6>Resume Building and Interview Preparation</h6>
                                        <p>Guidance on creating effective resumes and conducting successful job interviews. This includes mock interview sessions and feedback to help participants present themselves well to potential employers.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certificate.svg`} alt="" />
                                        <h6>Internship Opportunities</h6>
                                        <p>Hands-on experience through internships can enhance participants practical skills and increase their chances of getting hired.</p>
                                    </div>
                                </div>
                                <div className='images'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/highlight-main.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="career-pathways" className='pap-third-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>Career Pathways</h2>
                            <div className="content-area">
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Master the art of automating testing processes, ensuring the reliability and efficiency of software applications through automated testing frameworks.</p>
                                        <h6>Automation Testing Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Design and implement comprehensive test automation architectures, providing strategic direction for efficient and scalable automated testing solutions.</p>
                                        <h6>Test Automation Architect</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Focus on continuous testing methodologies, ensuring seamless integration of automated tests throughout the software development lifecycle for rapid and reliable releases.</p>
                                        <h6>Continuous Testing Specialist</h6>
                                    </div>
                                </div>
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Specialize in Selenium automation, implementing and maintaining automated test scripts for web applications, ensuring consistent functionality and performance.</p>
                                        <h6>Selenium Automation Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Concentrate on automating API testing, ensuring the functionality, reliability, and security of API endpoints through automated testing frameworks.</p>
                                        <h6>API Test Automation Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Excel in performance testing, evaluating the scalability and responsiveness of software applications under varying conditions, ensuring optimal performance.</p>
                                        <h6>Performance Testing Specialist</h6>
                                    </div>
                                </div>
                                {this.state.viewMorePathways ? <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Specialize in automating testing processes for mobile applications, ensuring consistent functionality, performance, and user experience across diverse mobile devices and platforms.</p>
                                        <h6>Mobile App Automation Tester</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Bridge the gap between development and testing in DevOps environments, focusing on automated testing processes for seamless integration and deployment.</p>
                                        <h6>DevOps Test Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Integrate security measures into the test automation pipeline, ensuring secure software development and deployment practices through automated security testing.</p>
                                        <h6>Security Test Automation Engineer</h6>
                                    </div>
                                </div> : <></>}
                                {!this.state.viewMorePathways ? <button className='view-more' onClick={() => this.viewMorePathways()}>view more pathways</button> : <></>}
                            </div>

                        </div>
                    </div>
                </section>
                <section className='pap-fourth-section'>
                    <div className="container">

                        <div className='first-container'>
                            <h2>Learning Journey</h2>
                            <img className='images' src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/learning.png`} alt="" />
                            <div className="content-area">

                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 1</p>
                                            <h6>Begin your journey in Automation Testing</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Begin your journey in Automation Testing</h6>
                                            <p>Initiate your exploration into Automation Testing, delving into the dynamic realm of efficient software testing, laying the groundwork for specialized expertise.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 2</p>
                                            <h6>Data Structures and Algorithms</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Data Structures and Algorithms</h6>
                                            <p>Explore the fundamental elements of efficient coding and problem-solving within the context of Automation Testing, focusing on data structures and algorithms essential for mastering this testing domain.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 3</p>
                                            <h6>Database</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Database</h6>
                                            <p>Immerse yourself in database intricacies tailored for Automation Testing, crucial for efficient data management. Understand core concepts to elevate your coding proficiency within this specialized field.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 6</p>
                                            <h6>Build and Present Capstone Projects</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Build and Present Capstone Projects</h6>
                                            <p>Apply your skills to real-world scenarios by constructing and presenting impactful Test Automation projects. Showcase your technical prowess and creativity in comprehensive testing ventures.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 5</p>
                                            <h6>Back-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Back-end Development</h6>
                                            <p>Dive into the server-side intricacies of Automation Testing, mastering databases, server architecture, and application logic. Ensure seamless functionality behind the scenes, enhancing your testing capabilities.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 4</p>
                                            <h6>Front-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Front-end Development</h6>
                                            <p>Immerse yourself in the captivating world of front-end development for Automation Testing. Merge creativity with functionality to design visually appealing and user-friendly interfaces tailored for testing environments.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 7</p>
                                            <h6>Coding & Hackathons</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Coding & Hackathons</h6>
                                            <p>Hone your coding skills and problem-solving acumen through dynamic coding sessions and Test Automation challenges. Experience the thrill of real-time testing scenarios.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 8</p>
                                            <h6>Preparing for Placement Opportunities</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Preparing for Placement Opportunities</h6>
                                            <p>Equip yourself with the essential skills and knowledge to excel in Automation Testing opportunities. Prepare for interviews, assessments, and a successful career in the dynamic testing industry.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 9</p>
                                            <h6>Participation in Placement Drives</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Participation in Placement Drives</h6>
                                            <p>Engage in testing drives to connect with potential employers within the Automation Testing industry. Highlight your skills, explore job opportunities, and take the first stride towards a thriving career in Automation Testing.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => this.handleButtonClick()}>Download Curriculum</button>
                        </div>

                    </div>
                </section >
                <section className='pap-fifth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Capstone Projects</h2>
                                <p>Utilising the knowledge you gained from the training, create a application from scratch on your own. Learn how to effectively plan and carry out projects. Get helpful input by presenting your project to a group of industry evaluators. Add the project to your GitHub project portfolio.</p>

                                <h2>What Will You Achieve?</h2>
                                <p>Through our Full Stack Development program, you'll attain the knowledge and practical experience to accomplish the following.</p>

                                <h2>Develop an End-to-End Application:</h2>
                                <p>You'll have the ability to design, build, and deploy a comprehensive application that encompasses both the front-end and back-end components. This hands-on experience ensures you're well-versed in creating user-centric, functional web applications.</p>

                                <h2>Gain Confidence in Your Skills by Presenting the Project to Industry Experts:</h2>
                                <p>As you complete your project, you'll have the opportunity to present it to industry experts, receiving valuable feedback and insights. This exposure boosts your confidence and helps you refine your skills to meet industry standards and expectations.</p>
                                <p>With our Full Stack Development program, you not only learn the ropes of development but also gain the confidence to showcase your abilities to professionals in the field. It's a stepping stone towards a successful and rewarding career in the world of technology.</p>
                            </div>
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/books.svg`} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-sixth-section'>
                    <h2>PROJECTS</h2>
                    <div className="container">
                        <div className="grid-container">
                            <div className="item1 item-box">
                                <h2>E-Commerce Website Testing:</h2>
                                <p>Conduct comprehensive testing for e-commerce websites, ensuring smooth user experience, secure transactions, and functionality across different devices.</p>
                            </div>
                            <div className="item2 item-box">
                                <h2>Travel Booking Website Testing:</h2>
                                <p>Ensure reliable travel booking websites with secure payment processing, itinerary management, and real-time availability checks.</p>
                            </div>
                            <div className="item3 item-box">
                                <h2>Cross-Browser Compatibility Testing:</h2>
                                <p>Verify the compatibility of websites and applications across different web browsers, ensuring a consistent and optimal user experience regardless of the browser used.</p>
                            </div>
                            <div className="item4 item-box">
                                <h2>Financial Application Testing:</h2>
                                <p>Conduct thorough testing of financial applications, focusing on security, accuracy, and regulatory compliance to ensure the integrity of financial transactions and data</p>
                            </div>
                            <div className="item5 ">
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/startup.svg`} alt="" />
                            </div>
                            <div className="item6 item-box">
                                <h2>Mobile App Testing Automation:</h2>
                                <p>Automate mobile app testing for consistent functionality, performance, and user experience across diverse devices and platforms.</p>
                            </div>
                            <div className="item7 item-box">
                                <h2>Social Media Platform Testing:</h2>
                                <p>Perform rigorous testing on social media platforms, validating features like user interactions, content sharing, and real-time updates for seamless user engagement.</p>
                            </div>
                            <div className="item8 item-box">
                                <h2>Healthcare Management System Testing:</h2>
                                <p>Validate healthcare management systems, prioritizing patient data security, appointment scheduling, and medical record management.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-eight-section'>
                    <h2>WHY REMOTE PROGRAM</h2>
                    <div className="container">
                        <div className="first-container">
                            <div className='images'><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/w-r-p-image.svg`} alt="" /></div>
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-2.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Flexibility:</h2>
                                <p>This flexibility is particularly beneficial for individuals who have busy schedules, live in different time zones, or prefer to learn at their own pace.</p>

                                <h2>Cost-Effectiveness:</h2>
                                <p>Both learners and training providers can save money with remote training.</p>

                                <h2>Global Reach:</h2>
                                <p>Individuals from different parts of the world can participate in the same training program, leading to diverse perspectives and experiences being shared.</p>

                                <h2>basic Learning:</h2>
                                <p>Learners can progress through the content at their own speed, allowing them to grasp concepts thoroughly before moving on.</p>
                            </div> */}
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-1.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Personalized Learning:</h2>
                                <p>This personalized approach ensures that learners receive the most relevant and effective training.</p>

                                <h2>Continuous Learning:</h2>
                                <p>Remote training enables participants to access training materials after the live sessions have ended.</p>

                                <h2>Lifelong Learning: </h2>
                                <p>Remote training encourages a culture of lifelong learning, as individuals can easily enroll in courses, webinars, and workshops to stay up-to-date with the latest industry trends.</p>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className='pap-ninth-section'>
                    <div className="container">
                        <div className="first-container">
                            {/* <div className='first-row'> */}
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/auto-test/tools-used/tools.svg`} alt='' />
                            {/* </div> */}
                        </div>
                    </div>
                </section>
                <section className='pap-tenth-section'>
                    <div className="container">
                        <div className="first-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certifications.svg`} alt="" />
                        </div>

                    </div>
                </section>
                <section id="pricing" className='pap-eleventh-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container pricing">
                        <h2>Our Expert-Led Courses</h2>
                        <div className='pricing-box'>
                            <div className="left-box">
                                <div className='basic'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/pricing-logo1.svg`} alt="" />
                                    <h4>Basic</h4>
                                    <p>Our basic Placement Assurance Program focuses on thorough training and comprehensive support, ensuring you're well-prepared for a successful career launch.</p>
                                    <div className="row-container">
                                        <div className='basic-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-userfocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-calendar.svg`} alt="" />
                                                <p>5 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='basic-benefit'>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>

                                        </div>

                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                    <h5>₹59999/-</h5>
                                        <button onClick={()=>this.openCouponModal(59999, 'pap-auto-test', 'basic')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="right-box">
                                <div className='mentor-led'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/pricing-logo2.svg`} alt="" />
                                    <h4>Premium</h4>
                                    <p>Experience live classes, expert mentor guidance, and enhanced placement opportunities with our mentor-led program, ensuring personalized and interactive learning</p>
                                    <div className="row-container">
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/UserFocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Calendar.svg`} alt="" />
                                                <p>9 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ClockCounterClockwise.svg`} alt="" />
                                                <p>Interview Help and Job Placement Support</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Money.svg`} alt="" />
                                                <p>Up to 12 LPA Salary Package</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ChalkboardTeacher.svg`} alt="" />
                                                <p>100% Placement Assurance</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                    <h5>₹79999/-</h5>
                                        <button onClick={()=>this.openCouponModal(79999, 'pap-auto-test', 'premium')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className='pap-twelfth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Meet the Face behind InternzLearn</h2>
                                <p>Our mentors are full-time traders and economics graduates who have learnt to manage all their finances themselves. They not only understand the human psychology necessary to build a plethora of wealth but also know the financial markets inside out to provide you with tailored support & expert advice.</p>
                                <button>Get in Touch</button>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Saksham Ahuja<span></span></h6>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Richit Bid<span></span></h6>
                            </div>
                        </div>
                    </div>
                </section> */}
                <FAQ />
                <Footer />

            </div >
        )
    };
};
export default JavaCourse;