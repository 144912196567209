

import React, { Component } from 'react';

import Footer from '../common/footer/footer';
import HeaderWhite from '../common/headers/header-white';
class TermsAndConditions extends Component {

    render() {
        return (
            <>
                <HeaderWhite />
                <section>
                    <div class="container mb-3">

                        <div class="services-quality-wrapper">

                            <div class="">
                                <div>
                                    <h3> Terms And Conditions for Internz Learn Internship Programs</h3>

                                    <br />

                                    <p>Please carefully read and understand these terms and conditions ("Terms") before applying for or participating in the Internz Learn Internship Program ("Program"). By applying for and participating in the Program, you agree to comply with these Terms, which constitute a legally binding agreement between you and Internz Learn. If you do not agree with these Terms, please do not apply for or participate in the Program.</p>
                                    <br />

                                    <h4 class="services-quality-title"> 1.<u> Eligibility:	</u></h4>
                                    <p>1.1. To be eligible for the Program, you must be at least 18 years of age at the time of application.</p>

                                    <p>1.2. You must meet any additional eligibility criteria specified in the Program application materials.	</p>
                                    <br />

                                    <h4 class="services-quality-title mb-4">2. <u> Application Process:</u></h4>

                                    <p>2.1. You agree to provide accurate and complete information during the application process.	</p>
                                    <p>2.2. Internz Learn reserves the right to reject any application for any reason, without providing an explanation.</p>
                                    <br />

                                    <h4 class="services-quality-title mb-4">3. <u> Internship Placement:</u></h4>

                                    <p>
                                        3.1. Internz Learn will make reasonable efforts to match you with a suitable internship based on your qualifications and preferences. However, we do not guarantee internship placement.	</p>
                                    <p>3.2. Internship placements are subject to availability and the discretion of the host company.	</p>
                                    <br />
                                    <h4 class="services-quality-title mb-4">4. <u>Program Fees:</u>	</h4>
                                    <p>4.1. You may be required to pay a program fee as specified in the Program application materials. This fee covers program administration, support services, and other associated costs.
                                    </p><p>4.2. The program fee is non-refundable, except in cases where Internz Learn fails to provide an internship placement as described in these Terms.</p>
                                    <br />

                                    <h4 class="services-quality-title mb-4">5. <u>Internship Duration:</u>			</h4>
                                    <p>5.1. The duration of the internship will be specified in your internship offer. You are expected to complete the entire internship as agreed upon.	</p>

                                    <br />
                                    <h4 class="services-quality-title mb-4"> 6.<u> Responsibilities:</u></h4>
                                    <p>6.1. You are responsible for complying with the rules and regulations of the host company during your internship.	</p>
                                    <p>6.2. You must conduct yourself professionally and ethically throughout the internship.</p>
                                    <p>6.3. Any issues or concerns during the internship should be promptly reported to Internz Learn.	</p>
                                    <br />


                                    <h4 class="services-quality-title mb-4"> 7.<u>  Termination:</u></h4>
                                    <p>7.1. Internz Learn reserves the right to terminate your participation in the Program at any time, for any reason, without prior notice.	</p>
                                    <p>7.2. You may terminate your participation in the Program by providing written notice to Internz Learn. Refunds, if applicable, will be subject to the terms outlined in section 4 of these Terms.</p>


                                    <br />


                                    <h4 class="services-quality-title mb-4"> 8.<u>Confidentiality:	</u></h4>
                                    <p>8.1. You may be exposed to confidential information during your internship. You agree to maintain the confidentiality of any such information and not disclose it to unauthorized parties.	</p>


                                    <br />


                                    <h4 class="services-quality-title mb-4"> 9.<u> Liability:	</u></h4>
                                    <p>9.1. Internz Learn is not responsible for any harm, injury, or damage that you may incur during your internship.																						</p>
                                    <p>
                                        9.2. You are responsible for your own safety and well-being during the Program.	</p>


                                    <br />

                                    <h4 class="services-quality-title mb-4"> 10.<u> Privacy:	</u></h4>
                                    <p>10.1. By participating in the Program, you consent to the collection and use of your personal information as described in Internz Learn's Privacy Policy.	</p>


                                    <br />


                                    <h4 class="services-quality-title mb-4"> 11.<u>  Modification of Terms:		</u></h4>
                                    <p>11.1. Internz Learn reserves the right to modify these Terms at any time. Any changes will be communicated to participants.	</p>

                                    <br />



                                    <h4 class="services-quality-title mb-4"> 12.<u> Governing Law:</u></h4>
                                    <p>12.1. These Terms are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.	</p>

                                    <br />

                                    <h4 class="services-quality-title mb-4"> 13.<u> Contact Information:</u></h4>
                                    <p>13.1. For inquiries or concerns regarding the Program or these Terms, please contact Internz Learn at info@internzlearn.com	</p>

                                    <p>By applying for and participating in the Internz Learn Internship Program, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
                                    <br />


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }



}
export default TermsAndConditions;