import React, { useState, Component } from 'react';
import Cookies from 'js-cookie';
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import './../../styles/modal.css'; // Import your CSS file for styling

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailOrPhone: '',
            loginPassword: '',
            loginMessage: '',
            loginStatus: false,
            login: false,
            showModal: false,
        };
    }
    handleLoginClick = () => {
        this.setState({
            showModal: true,
        })
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
        })
    };
    handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    handleLoginSubmit = (e) => {
        e.preventDefault();

        // Add API call logic here
        const logInData = {
            emailOrPhone: this.state.emailOrPhone,
            password: this.state.loginPassword,
        };

        // Example API call using fetch
        fetch(`${process.env.REACT_APP_API_URL}`+'login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logInData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == true) {
                    this.setState({
                        login: true,
                        loginStatus: true,
                        loginMessage: data.message,
                    })
                    const expirationDays = 7; // Set the desired expiration in days
                    const currentDate = new Date();
                    currentDate.setDate(currentDate.getDate() + expirationDays);
                    Cookies.set('token', data.token, { expires: currentDate });
                    Cookies.set('username', data.name, { expires: currentDate });
                    this.props.history.push('/')
                } else {
                    this.setState({
                        login: true,
                        loginStatus: false,
                        loginMessage: data.message,
                    })
                }
            })
            .catch(error => {
                console.error('Error login: ', error);
                // Handle error or display an error message to the user
            });
    };


    render() {
        return (
            <div className='login-modal'>
                <button className='login-redirect'>Already have an account? <span  onClick={() => this.handleLoginClick()}>Log in</span></button>

                {this.state.showModal && (
                    <div className="modal-overlay auth-page-section-login">
                        <div className="modal-content">
                            <span className="close-button" onClick={()=>this.handleModalClose()}>&times;</span>
                            {/* Add your login form or content here */}
                            <h2 className='login'>Login</h2>
                            <form onSubmit={this.handleLoginSubmit}>
                                <div className="login-box">
                                    <div className="form-group">
                                        <label className='form-label'>
                                            Email / Phone:
                                        </label>
                                        <input
                                            type="text"
                                            name="emailOrPhone"
                                            className='form-control'
                                            value={this.state.emailOrPhone}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className='form-label'>
                                            Password:
                                        </label>
                                        <input
                                            type="password"
                                            name="loginPassword"
                                            className='form-control'
                                            value={this.state.loginPassword}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <button type="submit">Log In</button>
                                    {/* <h6>Forgot Password?</h6> */}
                                    <h5>Not a member?<span onClick={()=>this.handleModalClose()}>Sign up</span></h5>
                                    {/* <div class="alert alert-success" style={this.state.login ? this.state.loginStatus ? show : hide : hide} role="alert">
                                        {this.state.loginMessage}
                                    </div>
                                    <div class="alert alert-danger" style={this.state.login ? this.state.loginStatus ? hide : show : hide} role="alert">
                                        {this.state.loginMessage}
                                    </div> */}
                                </div>

                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    };
};
export default withRouter(LoginModal);
// export default LoginModal;
