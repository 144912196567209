import React, { Component, useState } from 'react';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';
import PeerLearning from './../common/layout/peer-learning';
import Grow from './../common/layout/grow';
import Unique from '../common/layout/unique';
import Testimonials from '../common/layout/testimonials';
import FAQ from '../common/layout/faq';
import Pathways from '../common/layout/pathways';
import PapFirstSection from '../common/layout/pap-first-section';
import BannerLogo from '../common/layout/banner-logo';
import PapFirstSectionMobile from '../common/layout/pap-first-section-mobile';
// import './../../styles/pap-main.css';   
class Main extends Component {
    render() {
        return (
            <div style={{ backgroundColor: "#000"}}>
                <HeaderBlack />
                <PapFirstSection /> 
                <PapFirstSectionMobile />
                <section className='second-section'>
                        <div className="company-banner">
                            <p>Hiring Partner's -</p>
                            <div>
                            <BannerLogo />
                            </div>
                        </div>
                </section> 
                <Grow />
                <PeerLearning />
                <Unique />
                <Pathways />
                <Testimonials />
                <FAQ />
                <Footer />
            </div>

        )
    };
};
export default Main;