import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Cookies from 'js-cookie';
import axios from 'axios';

class CourseForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.validator = new SimpleReactValidator();

        this.state = {
            categories: [],
            newFormData: {
                course_name: '',
                duration: 0,
                mentor_name: '',
                mentor_image: '',
                profession: "",
                students: 0,
                image: '',
                background_image: '',
                category: '',
                category_id: '',
                description: '',
                next_batch: '',
                total_seat: 0,
                available_seat: 0,
                project: "",
                about_this_course: [''],
                apply_your_knowledge: "",
                who_is_the_course_for: "",
                video: "",
                course_curriculum: [{ head: '', desc: '' }],
                course_curriculum_image: '',
                tools_used: '',
                self_paced_amount: 0,
                mentor_led_amount: 0,
            },
            oldFormData: {
                mentor_image: null,
                image: null,
                background_image: null,
                project: null,
                video: null,
                course_curriculum_image: null,
                tools_used: null,
            },
        };
        this.getCategories();
    }
    componentDidMount() {
        // Check if the component is in edit mode
        const { match } = this.props;
        const { params } = match;
        const { course_id } = params;

        if (course_id) {
            // Fetch event details for editing
            this.fetchCourseData(course_id);
        }
    }
    fetchCourseData = async (course_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}course/${course_id}`);

            if (response.ok) {
                const result = await response.json();

                if (result.status) {
                    let data = result.course;

                    // Set the state with the fetched event data
                    this.setState({
                        newFormData: {
                            course_name: data.course_name,
                            duration: data.duration,
                            mentor_name: data.mentor_name,
                            mentor_image: null,
                            profession: data.profession,
                            students: data.students,
                            image: null,
                            background_image: null,
                            description: data.description,
                            next_batch: data.next_batch,
                            total_seat: data.total_seat,
                            available_seat: data.available_seat,
                            project: null,
                            about_this_course: JSON.parse(data.about_this_course),
                            apply_your_knowledge: data.apply_your_knowledge,
                            who_is_the_course_for: data.who_is_the_course_for,
                            video: null,
                            course_curriculum: JSON.parse(data.course_curriculum),
                            course_curriculum_image: null,
                            tools_used: null,
                            self_paced_amount: data.self_paced_amount,
                            mentor_led_amount: data.mentor_led_amount,
                        },
                        oldFormData: {
                            mentor_image: data.mentor_image,
                            image: data.image,
                            background_image: data.background_image,
                            project: data.project,
                            video: data.video,
                            course_curriculum_image: data.course_curriculum_image,
                            tools_used: data.tools_used,
                        },
                    });
                } else {
                    console.error('Failed to fetch event details');
                }
            } else {
                console.error('Failed to communicate with the server');
            }
        } catch (error) {
            console.error('Error fetching event details:', error);
        }
    };
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    getCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'categories', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                categories: response.data.categories,
            });
        }
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: value },
        }));
    };

    handleContentChange = (index, field, value) => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                course_curriculum: prevState.newFormData.course_curriculum.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        }));
    };
    addContent = () => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                course_curriculum: [...prevState.newFormData.course_curriculum, { head: '', desc: '' }],
            },
        }));
    };

    removeContent = (index) => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                course_curriculum: prevState.newFormData.course_curriculum.filter((item, i) => i !== index),
            },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: files[0] },
        }));
    };
    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: value },
        }));
    };

    handleSelectChange =(e) =>{
        const { name, value } = e.target;
        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: value },
        }));
    };

    handleSelectCategoryChange = (e) => {
        const { name, value } = e.target;
        const category = this.state.categories.find((cat) => cat.category_id === value);
    
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                category_id: value,
                category: category ? category.category : '', // Use the name property if category is found
            },
        }));
    };

    handleArrayInputChange = (index, value) => {
        const aboutCourse = [...this.state.newFormData.about_this_course];
        aboutCourse[index] = value;

        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                about_this_course: aboutCourse,
            },
        }));
    };
    addArrayInput = () => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                about_this_course: [...prevState.newFormData.about_this_course, ''], // Add a new empty input
            },
        }));
    };
    removeArrayInput = (index) => {
        const aboutCourse = [...this.state.newFormData.about_this_course];
        aboutCourse.splice(index, 1);

        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                about_this_course: aboutCourse,
            },
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { match } = this.props;
        const { params } = match;
        const { course_id } = params;

        if (this.validator.allValid()) {
            const apiURL = `${process.env.REACT_APP_API_URL}update-course/${course_id}`;
            const requestData = new FormData();

            // Append each field to newFormData
            Object.entries(this.state.newFormData).forEach(([key, value]) => {
                if (key === 'course_curriculum') {
                    requestData.append(`newFormData[${key}]`, JSON.stringify(value));
                } else if(key === 'about_this_course'){
                    requestData.append(`newFormData[${key}]`, JSON.stringify(value));
                }else {
                    requestData.append(`newFormData[${key}]`, value);
                }
            });

            Object.entries(this.state.oldFormData).forEach(([key, value]) => {
                if (key === 'course_curriculum') {
                    requestData.append(`oldFormData[${key}]`, JSON.stringify(value));
                } else if(key === 'about_this_course'){
                    requestData.append(`oldFormData[${key}]`, JSON.stringify(value));
                } else {
                    requestData.append(`oldFormData[${key}]`, value);
                }
            });

            try {
                const response = await fetch(apiURL, {
                    method: 'PUT',
                    body: requestData,
                });
                if (response.ok) {
                    const result = await response.json();
                    if (result.status) {
                        this.props.history.push("/admin/courses")
                    } else {
                        console.error('Failed to add course');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding course:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { categories,newFormData, oldFormData } = this.state;

        return (
            <>
                <div className='add-course container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Course Name:</label>
                                <input className='form-control' type="text" name="course_name" value={newFormData.course_name} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Duration:</label>
                                <input className='form-control' type="text" name="duration" value={newFormData.duration} onChange={this.handleInputChange} />
                                
                            </div>
                            <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.image}`} alt="" width="500" height="300" />
                            <div className="form-group">
                                <label className='form-label'>Image:</label>
                                <input className='form-control' type="file" name="image" onChange={this.handleFileChange} />
                                
                            </div>
                            <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.background_image}`} alt="" width="500" height="300" />
                            <div className="form-group">
                                <label className='form-label'>Background Image:</label>
                                <input className='form-control' type="file" name="background_image" onChange={this.handleFileChange} />
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Mentor Name:</label>
                                <input className='form-control' type="text" name="mentor_name" value={newFormData.mentor_name} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Mentor Profession:</label>
                                <input className='form-control' type="text" name="profession" value={newFormData.profession} onChange={this.handleInputChange} />
                            </div>
                            <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.mentor_image}`} alt="" width="50" height="50" />
                            <div className="form-group">
                                <label className='form-label'>Mentor Image:</label>
                                <input className='form-control' type="file" name="mentor_image" onChange={this.handleFileChange} />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Students:</label>
                                <input className='form-control' type="number" name="students" value={newFormData.students} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Description:</label>
                            <textarea className='form-control' name="description" value={newFormData.description} onChange={this.handleInputChange} rows="4" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Next Batch</label>
                            <select class="form-select" name="next_batch" onChange={this.handleSelectChange} id="">
                                <option value={newFormData.next_batch} selected>{newFormData.next_batch}</option>
                                <option value="">Select Batch</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Total Seat:</label>
                            <input className='form-control' type="number" name="total_seat" value={newFormData.total_seat} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Available Seat:</label>
                            <input className='form-control' type="number" name="available_seat" value={newFormData.available_seat} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Self Placed Amount:</label>
                            <input className='form-control' type="number" name="self_paced_amount" value={newFormData.self_paced_amount} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Mentor Led Amount:</label>
                            <input className='form-control' type="number" name="mentor_led_amount" value={newFormData.mentor_led_amount} onChange={this.handleInputChange} />
                        </div>
                        <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.project}`} alt="" width="500" height="300" />
                        <div className="form-group">
                            <label className='form-label'>Project Image:</label>
                            <input className='form-control' type="file" name="project" onChange={this.handleFileChange} />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Apply Your Knowledge:</label>
                            <input className='form-control' type="text" name="apply_your_knowledge" value={newFormData.apply_your_knowledge} onChange={this.handleInputChange} />
                        </div>

                        <div className="form-group">
                            <label className='form-label'>Who is the course for:</label>
                            <input className='form-control' type="text" name="who_is_the_course_for" value={newFormData.who_is_the_course_for} onChange={this.handleInputChange} />
                        </div>
                        <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.video}`} alt="" width="500" height="300" />
                        <div className="form-group">
                            <label className='form-label'>Video Image:</label>
                            <input className='form-control' type="file" name="video" onChange={this.handleFileChange} />
                        </div>
                        <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.course_curriculum_image}`} alt="" width="500" height="300" />
                        <div className="form-group">
                            <label className='form-label'>Course Curriculum Image:</label>
                            <input className='form-control' type="file" name="course_curriculum_image" onChange={this.handleFileChange} />
                        </div>
                        <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${oldFormData.tools_used}`} alt="" width="500" height="300" />
                        <div className="form-group">
                            <label className='form-label'>Tools Used Image:</label>
                            <input className='form-control' type="file" name="tools_used" onChange={this.handleFileChange} />
                        </div>

                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>About this Course:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addArrayInput} style={{ borderRadius: "50%" }} />
                        </div>
                        {newFormData.about_this_course.map((name, index) => (
                            <div className='content-box' key={index} style={{ marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    value={name}
                                    className='form-control'
                                    onChange={(e) => this.handleArrayInputChange(index, e.target.value)}
                                />
                                <Button type="button" severity="danger" icon="pi pi-times" style={{ borderRadius: "50%" }} className='mt-1' onClick={() => this.removeArrayInput(index)} />
                            </div>
                        ))}


                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>Course Curriculum:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addContent} style={{ borderRadius: "50%" }} />
                        </div>

                        {newFormData.course_curriculum.map((item, index) => (
                            <div key={index} className='content-box'>
                                <div>
                                    <label className='form-label'>Heading:</label>
                                    <input className='form-control' type="text" value={item.head} onChange={(e) => this.handleContentChange(index, 'head', e.target.value)} />
                                </div>
                                <div>
                                    <label className='form-label'>Description:</label>
                                    <textarea className='form-control' value={item.desc} onChange={(e) => this.handleContentChange(index, 'desc', e.target.value)} />
                                </div>
                                <Button type="button" severity="danger" icon="pi pi-times" style={{ borderRadius: "50%" }} className='mt-1' onClick={() => this.removeContent(index)} />
                            </div>
                        ))}

                        <Button type="submit" severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/courses" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white" }}>
                            Cancel
                        </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default CourseForm;
