import React, { Component, useState } from 'react';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';
import FAQ from '../common/layout/pap-faq';
import Cookies from 'js-cookie';
import BannerLogo from '../common/layout/banner-logo';
import CouponsModal from '../common/layout/coupons';
import './../../styles/pap-course.css';

class PythonCourse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewMorePathways: false,
            course_id: '',
            type: '',
            coupon_modal_show: false,
            amount: 0,
        };
    }
    viewMorePathways = () => {
        this.setState({
            viewMorePathways: true,
        });
    }
    handleButtonClick = () => {
        // Check if the user is logged in
        if (Cookies.get('token') === undefined) {
            this.props.history.push('/sign-up')
        } else {
            this.downloadPDF();
        }
    };
    downloadPDF = () => {
        // Replace 'your-pdf-file.pdf' with the actual path to your PDF file
        const pdfPath = process.env.PUBLIC_URL + '/assets/document/pap/curriculum/python-pap.pdf';

        // Create a temporary anchor element
        const link = document.createElement('a');

        // Set the download attribute and href of the anchor element
        link.download = 'python-pap.pdf';
        link.href = pdfPath;

        // Trigger a click on the anchor element to start the download
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the document
        document.body.removeChild(link);
    };
    scrollToPricing = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pricing');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    openCouponModal = (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        this.setState({
          amount: amount,
          course_id: course_id,
          type: type,
        }, () => {
          this.setState({
            coupon_modal_show: true,
          });
        });
      };

    closeCouponModal = () => {
        this.setState({
            coupon_modal_show: false,
        })
    };

    startPayment =(amount) =>{
        this.initiatePayment(amount, this.state.course_id, this.state.type)
    }
     

    initiatePayment = async (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        // const { enqData } = this.state;
        // const amountString = document.getElementById(type).innerText
        const amountFloat = parseFloat(amount).toFixed(2);
        // const amountAsNumber = parseFloat(amountFloat);
        const paymentData = {
            user_id: user_id,
            amount: amountFloat,
            course_id: course_id,
            type: type,
        }
        try {
            fetch(`${process.env.REACT_APP_API_URL}` + 'initiate-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            })
                .then(response => response.json())
                .then(data => {
                    // if (data.status) {
                        console.log(data)
                    // }
                    const form = document.createElement('form');
                    form.method = 'post';
                    form.name= 'redirect';
                    form.id = 'nonseamless';
                    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
                    const encRequestInput = document.createElement('input');
                    encRequestInput.type = 'hidden';
                    encRequestInput.name = 'encRequest';
                    encRequestInput.id = 'encRequest';
                    encRequestInput.value = data.data;
                    const accessCodeInput = document.createElement('input');
                    accessCodeInput.type = 'hidden';
                    accessCodeInput.name = 'access_code';
                    accessCodeInput.id = 'access_code';
                    accessCodeInput.value = 'AVDK13KI30BD04KDDB'
                    form.appendChild(encRequestInput);
                    form.appendChild(accessCodeInput)
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }
    render() {
        const { coupon_modal_show, amount } = this.state
        const style = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/python/first.svg)`,
            backgroundSize: 'cover',  // or 'contain' depending on your preference
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return (
            <div style={{ backgroundColor: "#000" }}>
                <HeaderBlack />
                <CouponsModal isOpen={coupon_modal_show} onPay={this.startPayment} amount={amount} onClose={this.closeCouponModal} />
                <section className='pap-first-section' style={style}>
                    <div className="container">
                        <div className="first-container">
                            <div className='left-container'>
                                <h6>Placement Assurance Program </h6>
                                <h2>PYTHON FULL STACK</h2>
                                <p>Embark on a voyage into the world of Python Full Stack Development, unlocking the art of crafting cutting-edge, responsive, and robust web applications. Dive into front-end frameworks for shaping seamless user experiences and explore back-end technologies for efficient data management and security. Join us in navigating the dynamic fusion of code and creativity in the realm of Python Full Stack Development.</p>
                                <div className='details-box'>
                                    <div className='details-box-div'>
                                        <h5>Batch <img style={{ marginLeft: "2rem" }} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/batch.svg`} alt="" /></h5>
                                        <h4>Monthly</h4>
                                    </div>
                                    <div className='details-box-div'>
                                        <h5>Available Seats <img style={{ marginLeft: "2rem" }} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/seat.svg`} alt="" /></h5>
                                        <h4>8/60</h4>
                                    </div>
                                </div>

                            </div>
                            <div className='right-container'>
                                <div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/python/first-video-img.svg`} alt="" />
                                    </div>
                                    <button onClick={() => this.scrollToPricing()}>Apply Now </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='second-section'>
                    <div className="company-banner">
                        <p>Hiring Partner's -</p>
                        <div>
                            <BannerLogo />
                        </div>
                    </div>
                </section>
                <section className='pap-second-section' style={{ backgroundColor: "#000" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>KEY FEATURES</h2>
                            <div className="features-box">
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/first.svg`} alt="" />
                                        <h6>Job Placement Guarantee</h6>
                                        <p>Participants who successfully complete the program are guaranteed assistance in finding a suitable job within a specific time frame.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Customized Training</h6>
                                        <p>The training curriculum is designed to enhance participants' skills and make them job-ready.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/language.svg`} alt="" />
                                        <h6>Skill Development</h6>
                                        <p>This includes technical skills, soft skills, communication skills, and other abilities necessary for a successful career.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Placement Cell</h6>
                                        <p>The placement cells or teams responsible for liaising with companies, arranging interviews, and facilitating job placements for participants.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/teacher.svg`} alt="" />
                                        <h6>Mentorship</h6>
                                        <p>Mentors will be of 10 to 15 years experienced in indutry. Participants will be having 2 mentors dor the doubts clearing sessiosn.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/clock.svg`} alt="" />
                                        <h6>Resume Building and Interview Preparation</h6>
                                        <p>Guidance on creating effective resumes and conducting successful job interviews. This includes mock interview sessions and feedback to help participants present themselves well to potential employers.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certificate.svg`} alt="" />
                                        <h6>Internship Opportunities</h6>
                                        <p>Hands-on experience through internships can enhance participants practical skills and increase their chances of getting hired.</p>
                                    </div>
                                </div>
                                <div className='images'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/highlight-main.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="career-pathways" className='pap-third-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>Career Pathways</h2>
                            <div className="content-area">
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Master both the front-end and back-end aspects of web development. Full Stack Developers have the versatility to handle the entire development process, from user interface to server-side scripting.</p>
                                        <h6>Python Full Stack Developer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Craft visually stunning and user-friendly interfaces, specializing in shaping the look and feel of web applications for seamless user experiences with a focus on aesthetics.</p>
                                        <h6>Front-End Python Developer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Specialize in the server-side of web development, managing databases, server architecture, and application logic for smooth web application functionality, optimizing performance.</p>
                                        <h6>Back-End Python Developer</h6>
                                    </div>
                                </div>
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Focus on developing robust and scalable web applications using Python, utilizing frameworks and libraries to enhance functionality, ensuring efficiency and reliability.</p>
                                        <h6>Python Web Application Developer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Specialize in building Application Programming Interfaces (APIs) using Python, facilitating communication between different software applications, promoting seamless integration.</p>
                                        <h6>Python API Developer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Leverage Python for data-focused development, working on data processing, storage, and retrieval to support data-driven applications and insights, contributing to informed decision-making.</p>
                                        <h6>Python Data Engineer</h6>
                                    </div>
                                </div>
                                {this.state.viewMorePathways ? <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Design and implement high-level structures for complex software systems, ensuring the alignment of architectural decisions with business goals in Python-based projects.</p>
                                        <h6>Python Software Architect</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Bridge the gap between development and operations, automating processes, and ensuring seamless deployment of Python applications for efficient and reliable systems.</p>
                                        <h6>Python DevOps Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Combine Python programming skills with machine learning expertise, developing and deploying intelligent algorithms for data analysis and predictive modeling.</p>
                                        <h6>Python Machine Learning Engineer</h6>
                                    </div>
                                </div> : <></>}
                                {!this.state.viewMorePathways ? <button className='view-more' onClick={() => this.viewMorePathways()}>view more pathways</button> : <></>}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-fourth-section'>
                    <div className="container">

                        <div className='first-container'>
                            <h2>Learning Journey</h2>
                            <img className='images' src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/learning.png`} alt="" />
                            <div className="content-area">

                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 1</p>
                                            <h6>Begin your journey with Python</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Begin your journey with Python</h6>
                                            <p>Embark on your Python learning odyssey, delving into the world of this versatile programming language, setting the stage for your coding expertise.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 2</p>
                                            <h6>Data Structures and Algorithms</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Data Structures and Algorithms</h6>
                                            <p>Explore the fundamental building blocks of efficient coding and problem-solving with a focus on data structures and algorithms, crucial for programming mastery.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 3</p>
                                            <h6>Database</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Database</h6>
                                            <p>Immerse yourself in the intricacies of databases, essential for efficient data management. Understand the core concepts to enhance your coding proficiency.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 6</p>
                                            <h6>Build and Present Capstone Projects</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Build and Present Capstone Projects</h6>
                                            <p>Apply your skills to real-world scenarios by constructing and presenting impactful capstone projects. Showcase your technical prowess and creativity in comprehensive development ventures.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 5</p>
                                            <h6>Back-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Back-end Development</h6>
                                            <p>Journey into the server-side of web development, mastering databases, server architecture, and application logic. Ensure seamless functionality behind the scenes.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 4</p>
                                            <h6>Front-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Front-end Development</h6>
                                            <p>Immerse yourself in the captivating realm of front-end development. Merge creativity with functionality to craft visually appealing and user-friendly interfaces.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 7</p>
                                            <h6>Coding & Hackathons</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Coding & Hackathons</h6>
                                            <p>Sharpen your coding skills and problem-solving acumen through dynamic coding sessions and hackathons. Experience the excitement of real-time coding challenges.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 8</p>
                                            <h6>Preparing for Placement Opportunities</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Preparing for Placement Opportunities</h6>
                                            <p>Equip yourself with the essential skills and knowledge to excel in placement opportunities. Prepare for interviews, assessments, and a successful career in the dynamic tech industry.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 9</p>
                                            <h6>Participation in Placement Drives</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Participation in Placement Drives</h6>
                                            <p>Engage in placement drives to connect with potential employers. Highlight your skills, explore job opportunities, and take the first stride towards a thriving career in technology.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => this.handleButtonClick()}>Download Curriculum</button>
                        </div>

                    </div>
                </section >
                <section className='pap-fifth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Capstone Projects</h2>
                                <p>Utilising the knowledge you gained from the training, create a application from scratch on your own. Learn how to effectively plan and carry out projects. Get helpful input by presenting your project to a group of industry evaluators. Add the project to your GitHub project portfolio.</p>

                                <h2>What Will You Achieve?</h2>
                                <p>Through our Full Stack Development program, you'll attain the knowledge and practical experience to accomplish the following.</p>

                                <h2>Develop an End-to-End Application:</h2>
                                <p>You'll have the ability to design, build, and deploy a comprehensive application that encompasses both the front-end and back-end components. This hands-on experience ensures you're well-versed in creating user-centric, functional web applications.</p>

                                <h2>Gain Confidence in Your Skills by Presenting the Project to Industry Experts:</h2>
                                <p>As you complete your project, you'll have the opportunity to present it to industry experts, receiving valuable feedback and insights. This exposure boosts your confidence and helps you refine your skills to meet industry standards and expectations.</p>
                                <p>With our Full Stack Development program, you not only learn the ropes of development but also gain the confidence to showcase your abilities to professionals in the field. It's a stepping stone towards a successful and rewarding career in the world of technology.</p>
                            </div>
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/books.svg`} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-sixth-section'>
                    <h2>PROJECTS</h2>
                    <div className="container">
                        <div className="grid-container">
                            <div className="item1 item-box">
                                <h2>Blog Platform:</h2>
                                <p>Develop a dynamic and user-friendly blog platform, allowing users to create, publish, and share their thoughts and ideas.</p>
                            </div>
                            <div className="item2 item-box">
                                <h2>Personal Finance Tracker:</h2>
                                <p>Develop a personal finance tracker app to help users manage budgets, track expenses, and achieve financial goals effectively.</p>
                            </div>
                            <div className="item3 item-box">
                                <h2>Recipe Sharing App:</h2>
                                <p>Develop a community-driven platform for sharing and discovering recipes, enhancing the culinary experiences of users.</p>
                            </div>
                            <div className="item4 item-box">
                                <h2>Online Marketplace:</h2>
                                <p>Create a versatile online marketplace connecting buyers and sellers, facilitating smooth transactions and a wide range of product offerings.</p>
                            </div>
                            <div className="item5 ">
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/startup.svg`} alt="" />
                            </div>
                            <div className="item6 item-box">
                                <h2>Task Management Application:</h2>
                                <p>Design a task management tool to help users organize their to-do lists, set deadlines, and collaborate on projects efficiently.</p>
                            </div>
                            <div className="item7 item-box">
                                <h2>Real Estate Listings:</h2>
                                <p>Create a comprehensive real estate listings application, providing users with a seamless platform to explore and list properties.</p>
                            </div>
                            <div className="item8 item-box">
                                <h2>Health and Fitness Tracker:</h2>
                                <p>Build an intuitive app to track health and fitness goals, monitor progress, and provide personalized insights for a healthier lifestyle.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-eight-section'>
                    <h2>WHY REMOTE PROGRAM</h2>
                    <div className="container">
                        <div className="first-container">
                            <div className='images'><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/w-r-p-image.svg`} alt="" /></div>
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-2.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Flexibility:</h2>
                                <p>This flexibility is particularly beneficial for individuals who have busy schedules, live in different time zones, or prefer to learn at their own pace.</p>

                                <h2>Cost-Effectiveness:</h2>
                                <p>Both learners and training providers can save money with remote training.</p>

                                <h2>Global Reach:</h2>
                                <p>Individuals from different parts of the world can participate in the same training program, leading to diverse perspectives and experiences being shared.</p>

                                <h2>basic Learning:</h2>
                                <p>Learners can progress through the content at their own speed, allowing them to grasp concepts thoroughly before moving on.</p>
                            </div> */}
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-1.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Personalized Learning:</h2>
                                <p>This personalized approach ensures that learners receive the most relevant and effective training.</p>

                                <h2>Continuous Learning:</h2>
                                <p>Remote training enables participants to access training materials after the live sessions have ended.</p>

                                <h2>Lifelong Learning: </h2>
                                <p>Remote training encourages a culture of lifelong learning, as individuals can easily enroll in courses, webinars, and workshops to stay up-to-date with the latest industry trends.</p>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className='pap-ninth-section'>
                    <div className="container">
                        <div className="first-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/python/tools-used/tools.svg`} alt='' />
                        </div>
                    </div>
                </section>
                <section className='pap-tenth-section'>
                    <div className="container">
                        <div className="first-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certifications.svg`} alt="" />
                        </div>

                    </div>
                </section>
                <section id="pricing" className='pap-eleventh-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container pricing">
                        <h2>Our Expert-Led Courses</h2>
                        <div className='pricing-box'>
                            <div className="left-box">
                                <div className='basic'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/pricing-logo1.svg`} alt="" />
                                    <h4>Basic</h4>
                                    <p>Our basic Placement Assurance Program focuses on thorough training and comprehensive support, ensuring you're well-prepared for a successful career launch.</p>
                                    <div className="row-container">
                                        <div className='basic-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-userfocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-calendar.svg`} alt="" />
                                                <p>5 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='basic-benefit'>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>

                                        </div>

                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                        <h5>₹59999/-</h5>
                                        <button onClick={() => this.openCouponModal(59999, 'pap-python', 'basic')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="right-box">
                                <div className='mentor-led'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/pricing-logo2.svg`} alt="" />
                                    <h4>Premium</h4>
                                    <p>Experience live classes, expert mentor guidance, and enhanced placement opportunities with our mentor-led program, ensuring personalized and interactive learning</p>
                                    <div className="row-container">
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/UserFocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Calendar.svg`} alt="" />
                                                <p>9 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ClockCounterClockwise.svg`} alt="" />
                                                <p>Interview Help and Job Placement Support</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Money.svg`} alt="" />
                                                <p>Up to 12 LPA Salary Package</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ChalkboardTeacher.svg`} alt="" />
                                                <p>100% Placement Assurance</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                        <h5>₹79999/-</h5>
                                        <button onClick={() => this.openCouponModal(79999, 'pap-python', 'premium')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className='pap-twelfth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Meet the Face behind InternzLearn</h2>
                                <p>Our mentors are full-time traders and economics graduates who have learnt to manage all their finances themselves. They not only understand the human psychology necessary to build a plethora of wealth but also know the financial markets inside out to provide you with tailored support & expert advice.</p>
                                <button>Get in Touch</button>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Saksham Ahuja<span></span></h6>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Richit Bid<span></span></h6>
                            </div>
                        </div>
                    </div>
                </section> */}
                <FAQ />
                <Footer />

            </div >
        )
    };
};
export default PythonCourse;