import React, { useState } from 'react';
import Testimonials from './../common/layout/testimonials';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';
import Cookies from 'js-cookie';
import './../admin/layouts/sidebars'
import './styles/admin.css'
const Sidebar = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [name, setName]= useState("Profile");
    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };
   const style={

   }
    const logout =()=>{
        Cookies.remove("ghpackedfghpstfxdexh");
        Cookies.remove("username");
        window.location ="/admin/login/"
    }
    return (
        <>

            <div >
                <div className='text-white bg-dark'>
                    <button className="btn btn-dark p-2 m-2" onClick={toggleDrawer} >
                        <i class="fa-solid fa-bars" style={{fontSize:"20px"}}></i>
                    </button>
                </div>
                <div className={`sidebar-container d-flex flex-column flex-shrink-0 p-3 text-white bg-dark ${isDrawerOpen ? 'open' : ''}`} style={{style, width: "280px", minHeight: "100vh" }}>
                    <a href="/admin" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-4">INTERNZLEARN</span>
                    </a>

                    <hr />
                    <ul className="nav nav-pills flex-column mb-auto">
                        <li className="nav-item">
                            <a href="/admin/" className="nav-link active" aria-current="page">
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a href="/admin/list" className="nav-link text-white">
                                Admins
                            </a>
                        </li>
                        <li>
                            <a href="/admin/users" className="nav-link text-white">
                                Users
                            </a>
                        </li>
                        <li>
                            <a href="/admin/blogs" className="nav-link text-white">
                                Blogs
                            </a>
                        </li>
                        <li>
                            <a href="/admin/events" className="nav-link text-white">
                                Events
                            </a>
                        </li>
                        <li>
                            <a href="/admin/enrolled-events" className="nav-link text-white">
                                Enrolled Events
                            </a>
                        </li>
                        <li>
                            <a href="/admin/category" className="nav-link text-white">
                                Category
                            </a>
                        </li>
                        <li>
                            <a href="/admin/courses" className="nav-link text-white">
                                Courses
                            </a>
                        </li>
                        <li>
                            <a href="/admin/enquiries" className="nav-link text-white">
                                Enquiries
                            </a>
                        </li>
                        <li>
                            <a href="/admin/job-application" className="nav-link text-white">
                                Job Application
                            </a>
                        </li>
                        <li>
                            <a href="/admin/coupons" className="nav-link text-white">
                                Coupons
                            </a>
                        </li>
                        <li>
                            <a href="/admin/transactions" className="nav-link text-white">
                                Transaction Enquiries
                            </a>
                        </li>
                        <li>
                            <a href="/admin/subscribers" className="nav-link text-white">
                                Subscribers
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <div className="dropdown dropdown-flex">
                        <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                            <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                            <strong>{name}</strong>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                            {/* <li><a className="dropdown-item" href="#">New project...</a></li>
                            <li><a className="dropdown-item" href="#">Settings</a></li>
                            <li><a className="dropdown-item" href="#">Profile</a></li> */}
                            {/* <li><hr className="dropdown-divider" /></li> */}
                            <li><a className="dropdown-item" href="#" onClick={()=>logout()}>Sign out</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    )
}


export default Sidebar;