import React, { Component } from 'react';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';

class EnquiryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enqData: {
                name: '',
                email: '',
                reason: '',
                message: '',
            },
            buttonValue: 'Send a message',
        };

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
        });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            enqData: {
                ...prevState.enqData,
                [name]: value,
            },
        }));
        this.validator.showMessageFor(name);
    };

    handleSubmitEnquiry = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const { enqData } = this.state;
            const enquiryFormData = new FormData();
            enquiryFormData.append('name', enqData.name);
            enquiryFormData.append('email', enqData.email);
            enquiryFormData.append('reason', enqData.reason);
            enquiryFormData.append('message', enqData.message);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}`+'submit-enquiries', enquiryFormData);

                if (response.status === 200) {
                    this.setState({
                        buttonValue: 'Sent!',
                    });
                    // You might want to redirect or show a success message here
                } else {
                    console.error('Failed to submit data');
                }
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        } else {
            this.validator.showMessages();
        }
    };

    render() {
        const { enqData, buttonValue } = this.state;
        const { dark } =this.props;
        return (
            <>
            <div>
                <div className="form-group">
                    <label htmlFor="">Full Name</label>
                    <input
                        type="text"
                        className={`${dark?"black-input":""} form-control ${this.validator.message('name', enqData.name, 'required')}`}
                        value={enqData.name}
                        onChange={this.handleInputChange}
                        name="name"
                        placeholder="Type in your full name"
                    />
                    {this.validator.message('name', enqData.name, 'required', { className: 'text-danger' })}

                    <label htmlFor="">Email id</label>
                    <input
                        type="text"
                        className={`${dark?"black-input":""}  form-control ${this.validator.message('email', enqData.email, 'required|email')}`}
                        value={enqData.email}
                        onChange={this.handleInputChange}
                        name="email"
                        placeholder="Type in your email"
                    />
                    {this.validator.message('email', enqData.email, 'required|email', { className: 'text-danger' })}

                    <label htmlFor="">Reason</label>
                    <input
                        type="text"
                        className={`${dark?"black-input":""}  form-control ${this.validator.message('reason', enqData.reason, 'required')}`}
                        value={enqData.reason}
                        onChange={this.handleInputChange}
                        name="reason"
                        placeholder="Type in your reason"
                    />
                    {this.validator.message('reason', enqData.reason, 'required', { className: 'text-danger' })}

                    <label htmlFor="">Message</label>
                    <textarea
                        type="textarea"
                        className={`${dark?"black-input":""}  form-control ${this.validator.message('message', enqData.message, 'required')}`}
                        value={enqData.message}
                        onChange={this.handleInputChange}
                        name="message"
                        rows="4"
                        placeholder="Tell us what we can help you with"
                    />
                    {this.validator.message('message', enqData.message, 'required', { className: 'text-danger' })}


                    
                </div>
               
            </div>
             <button onClick={this.handleSubmitEnquiry} disabled={buttonValue === 'Sent!'}>
             {buttonValue}
         </button>
         </>
        );
    }
}

export default EnquiryForm;
