import React, { Component } from 'react'
import HeaderWhite from '../common/headers/header-white';
import Footer from '../common/footer/footer';
import EventList from './events';
import BlogList from './blog-list';
import PopularBlogs from './popular-blogs';
import './../../styles/blogs.css';
import EnquiryForm from '../common/layout/enquiry-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
class Blogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 1,
            staticBlogs: [
                {
                    "id": 1,
                    "image": `${process.env.PUBLIC_URL}/assets/vector/blogs/staticBlogs/first.svg`,
                    "subtitle": "Unraveling the AI Frontier",
                    "title": "A Dazzling Odyssey into the Realm of Artificial Intelligence",
                    "description": "In an era characterized by technological marvels, artificial intelligence (AI) emerges as the protagonist in our journey through the digital landscape. As aspiring students, you stand on the threshold of an exhilarating adventure into the heart of AI, a realm where machines learn, think, and create. Join us as we unveil the wonders of artificial intelligence, exploring real-life breakthroughs, including the rise of self-driving cars and the fascinating world of AI-generated art, promising a future filled with innovation and endless possibilities.",
                    "author_name": "Aman Singh",
                    "author_image": `${process.env.PUBLIC_URL}/assets/vector/profile/male.svg`,
                    "date": "2023-10-07 20:49:26",
                    "tag": "Artificial Intelligence",
                    "popular": 1,
                    "active": 1,
                    "content": JSON.stringify([
                        {
                            "heading": "The Marvels of Machine Learning",
                            "description": "Picture a world where machines not only perform tasks but also learn and adapt, mimicking the human capacity for intelligence. Machine learning, a cornerstone of artificial intelligence, empowers computers to analyze data, recognize patterns, and make decisions. From recommendation algorithms shaping our online experiences to predictive models revolutionizing healthcare, machine learning is the engine driving AI's transformative power.  In this era of intelligent systems, machine learning becomes the wizard behind the curtain, enabling computers to evolve and refine their abilities without explicit programming. As we traverse this landscape, we encounter real-life tales of machines mastering complex tasks, outperforming their human counterparts, and reshaping industries.",
                        },
                        {
                            "heading": "The Rise of Self-Driving Cars",
                            "description": "Embark on a thrilling ride into the world of autonomous vehicles, where AI takes the driver's seat. The rise of self-driving cars is not just a technological feat; it's a testament to the symbiotic relationship between AI and transportation. Witness the fusion of sensors, cameras, and sophisticated algorithms, creating a seamless dance of perception, decision-making, and action.  From Tesla's Autopilot to Waymo's driverless taxis, the race to achieve fully autonomous vehicles is transforming the way we navigate our world. AI-driven cars promise safer roads, reduced traffic congestion, and newfound freedom for those unable to drive. The journey towards self-driving cars is a testament to AI's ability to revolutionize not just industries but also the very fabric of our daily lives.",
                        },
                        {
                            "heading": "AI-Generated Art: A Canvas of Creativity",
                            "description": "Step into the realm where AI transcends its analytical prowess to become a true artist. AI-generated art, from paintings to music compositions, showcases the fusion of technology and creativity. Explore the algorithms that analyze artistic styles, generate unique pieces, and challenge our perception of what it means to create.  Artificial intelligence collaborates with human artists, pushing the boundaries of imagination and producing works that blur the line between man and machine. Dive into the fascinating stories of AI creating poetry, visual masterpieces, and even original music compositions. The synergy between AI and human creativity promises an era where artistic expression knows no bounds.",
                        },
                        {
                            "heading": "The Ethical Dilemmas: Unveiling the Shadows",
                            "description": "As we navigate the captivating world of AI, shadows cast by ethical dilemmas emerge. The power of artificial intelligence raises questions about privacy, bias, and the consequences of relying on machines to make decisions. Explore the complexities of AI ethics, from facial recognition algorithms perpetuating biases to the moral implications of autonomous weapons.  In the quest for technological advancement, it becomes imperative to grapple with ethical considerations. As stewards of the AI frontier, we must navigate these moral landscapes with care, ensuring that the brilliance of AI serves humanity responsibly and ethically.",
                        },
                        {
                            "heading": "Career Opportunities in the AI Galaxy",
                            "description": "Now, let's explore the vast galaxy of career opportunities that AI unveils before you. From machine learning engineers and data scientists to AI researchers and ethicists, the AI ecosystem beckons with a myriad of roles. Industries, from healthcare to finance, are clamoring for skilled individuals who can harness the power of AI to solve complex problems and drive innovation.  Embarking on a career in AI opens doors to a universe of possibilities. Whether you're passionate about developing algorithms, creating AI-driven applications, or delving into the ethical implications of AI, the opportunities are as diverse as the applications of artificial intelligence itself.",
                        },
                        {
                            "heading": "Navigating the AI Odyssey: A Roadmap",
                            "description": "Embarking on the AI odyssey requires a roadmap. Start by building a strong foundation in mathematics, programming, and data analysis. Dive into machine learning frameworks like TensorFlow and PyTorch, and enhance your skills through hands-on projects. Certifications such as Google's TensorFlow Developer Certificate and Microsoft's Certified: Azure AI Engineer Associate can add valuable feathers to your AI cap.  The AI roadmap is a journey of continuous learning, staying abreast of the latest advancements, and engaging with the AI community. Internships, research projects, and participation in AI competitions contribute to honing practical skills and gaining real-world experience. As you navigate this exciting frontier, remember that the quest for AI mastery is not just about technology; it's about shaping the future of intelligent machines.",
                        },
                        {
                            "heading": "The Future of Artificial Intelligence",
                            "description": "As we hurtle towards a future shaped by intelligent machines, the significance of artificial intelligence will only intensify. Emerging technologies like reinforcement learning, natural language processing, and quantum computing promise to elevate AI to new heights. The integration of AI into healthcare, education, and everyday life heralds a future where intelligent systems enhance human capabilities and redefine what's possible.",
                        },
                        {
                            "heading": "Conclusion",
                            "description": "In the realm of artificial intelligence, every discovery unveils a new horizon, every breakthrough opens a door to uncharted territories. The thrill of witnessing machines learn, create, and evolve resonates in the very fabric of our digital existence. As you embark on this dazzling odyssey into the realm of AI, remember that you're not just learning about technology; you're becoming a pioneer, shaping the future of intelligent machines. The adventure awaits – are you ready to embark on it?",
                        },
                    ])
                },
                {
                    "id": 2,
                    "image": `${process.env.PUBLIC_URL}/assets/vector/blogs/staticBlogs/second.svg`,
                    "subtitle": "Crafting the Digital Tapestry",
                    "title": "A Creative Odyssey into the World of Web Development",
                    "description": "In the vast expanse of the digital universe, web development stands as the master weaver, shaping the intricate tapestry of the internet. As aspiring web developers, you are poised on the brink of a creative odyssey, ready to breathe life into pixels, lines of code, and user experiences. Join us on this exhilarating journey into the heart of web development, where innovation, creativity, and the ever-evolving nature of technology converge.",
                    "author_name": "Monika Soni",
                    "author_image": `${process.env.PUBLIC_URL}/assets/vector/profile/female.svg`,
                    "date": "2023-11-23 20:49:26",
                    "tag": "Web Development",
                    "popular": 1,
                    "active": 1,
                    "content": JSON.stringify([
                        {
                            "heading": "The Canvas of Code",
                            "description": "Envision a blank canvas awaiting your creative touch – that's the essence of web development. At its core, web development is the art and science of building websites and web applications that captivate, engage, and serve a purpose. Dive into the world of HTML, CSS, and JavaScript, the foundational languages that weave the visual and interactive elements of the digital canvas. As you embark on this creative journey, witness the power of coding languages that transform static pages into dynamic, responsive, and visually stunning web experiences. The canvas of code is where your imagination takes flight, and the possibilities are as vast as the digital horizon.",
                        },
                        {
                            "heading": "Responsive Design: Crafting Experiences Across Devices",
                            "description": "In the era of diverse devices and screen sizes, the art of responsive design takes center stage. Explore the techniques that enable your creations to adapt seamlessly to the screens of smartphones, tablets, and desktops. Responsive design is not just about aesthetics; it's about ensuring that your web creations deliver a consistent and engaging experience across the ever-expanding spectrum of devices. Embark on the journey of fluid grids, flexible images, and media queries, as you sculpt designs that gracefully respond to the unique characteristics of each device. The canvas of responsive design invites you to be both artist and architect, harmonizing visual appeal with functional adaptability.",
                        },
                        {
                            "heading": "JavaScript Magic: Bringing Interactivity to Life",
                            "description": "Delve into the enchanting realm of JavaScript, the wizardry behind interactive and dynamic web experiences. From creating smooth animations to building feature-rich web applications, JavaScript is the spellbook that empowers web developers to breathe life into their creations. Uncover the secrets of libraries and frameworks like React, Angular, and Vue.js, which amplify the capabilities of JavaScript and streamline the development process. The magic of JavaScript lies in its ability to transform static pages into dynamic, user-centric experiences, inviting visitors to engage and immerse themselves in the digital enchantment you've crafted.",
                        },
                        {
                            "heading": "Back-End Sorcery: Powering the Magic Behind the Scenes",
                            "description": "Behind every captivating web experience lies the realm of back-end development, where servers, databases, and server-side scripting bring functionality to the forefront. Explore the languages and frameworks that enable you to create robust, scalable, and secure web applications. From the enchanting realms of Node.js to the expressive elegance of Python with Django, the back-end sorcery allows you to wield the power of databases, authentication, and server logic. Unravel the mysteries of RESTful APIs, as you seamlessly connect the front-end and back-end, orchestrating a symphony of functionality that transforms your web creations into fully-fledged applications.",
                        },
                        {
                            "heading": "User Experience Alchemy: Crafting Delightful Journeys",
                            "description": "In the alchemy of web development, user experience (UX) design is the art of crafting delightful journeys for visitors. Walk the path of user research, wireframing, and prototyping as you create interfaces that are intuitive, engaging, and seamlessly guide users through the digital landscape. The alchemy extends to user interface (UI) design, where colors, typography, and visual elements converge to create visually stunning and emotionally resonant experiences. As a web developer, you become both architect and artist, weaving together the technical and creative aspects to shape experiences that leave a lasting impression.",
                        },
                        {
                            "heading": "Career Opportunities: Navigating the Digital Frontier",
                            "description": "The world of web development unfolds a vast array of career opportunities before you. From front-end developers who specialize in creating captivating user interfaces to back-end developers orchestrating the server-side magic, the opportunities are as diverse as the digital projects you can imagine. Full-stack developers, equipped with both front-end and back-end skills, are the architects of comprehensive web solutions. UI/UX designers craft visually appealing and user-friendly interfaces, while web development project managers orchestrate the collaborative efforts of diverse teams. As you step into the world of web development, you become a versatile explorer, navigating the digital frontier and sculpting the future of the internet.",
                        },
                        {
                            "heading": "Embarking on the Web Development Odyssey: A Roadmap",
                            "description": "To embark on the odyssey of web development, you need a roadmap. Start by mastering the foundational languages – HTML, CSS, and JavaScript. Dive into frameworks like React, Angular, or Vue.js to enhance your front-end capabilities. Explore back-end languages such as Node.js, Python, or Ruby, and familiarize yourself with databases like MySQL or MongoDB. As you journey through the digital landscape, engage in real-world projects, contribute to open-source communities, and participate in hackathons to sharpen your skills. Certifications, such as the Google Developer Certification for Mobile Web Specialists or AWS Certified Developer, can add valuable credentials to your toolkit.",
                        },
                        {
                            "heading": "The Future of Web Development: Pioneering the Next Frontier",
                            "description": "As we gaze towards the future, the evolution of web development promises exciting frontiers. Progressive web apps, immersive augmented reality experiences, and the integration of artificial intelligence into web applications mark the next chapter in the ongoing saga of web development. WebAssembly, a low-level bytecode that runs in browsers, opens new possibilities for performance-intensive applications, while the ever-growing importance of web accessibility ensures that digital experiences are inclusive and available to all. Pioneering the next frontier in web development requires a mindset of continuous learning, adaptability, and a passion for pushing the boundaries of what's possible.",
                        },
                        {
                            "heading": "Conclusion",
                            "description": "In the realm of web development, each line of code is a stroke on the canvas, each project a masterpiece waiting to be unveiled. The thrill of creating seamless experiences, the joy of solving complex challenges, and the ever-expanding landscape of technology make web development an exhilarating odyssey. As you embark on this creative journey, remember that you're not just learning to code; you're becoming a digital artisan, shaping the online world for generations to come. The adventure awaits – are you ready to embark on it?",
                        }
                        
                    ])
                },
                {
                    "id": 3,
                    "image": `${process.env.PUBLIC_URL}/assets/vector/blogs/staticBlogs/third.svg`,
                    "subtitle": "Unveiling the Cybersecurity Enigma",
                    "title": "A Thrilling Odyssey into the Digital Realm",
                    "description": "In an era dominated by technology, where the digital landscape is both our playground and battlefield, the need for cybersecurity has never been more crucial. As students, you are on the brink of a thrilling journey into the heart of this enigmatic domain that safeguards our virtual existence. In this blog, we'll unravel the mysteries of cybersecurity, exploring real-life examples, including an intriguing episode of Indian hackers taking down Pakistani government websites, enticing you with the promise of an exciting and impactful career.",
                    "author_name": "Prasanjit Pattanaik",
                    "author_image": `${process.env.PUBLIC_URL}/assets/vector/profile/male.svg`,
                    "date": "2023-12-19 20:49:26",
                    "tag": "Cyber Security",
                    "popular": 1,
                    "active": 1,
                    "content": JSON.stringify([
                        {
                            "heading": "The Digital Battlefield",
                            "description": "Imagine a world where information flows like a river, connecting us all. Now, envision the dark underbelly of this vast network, where cybercriminals lurk, seeking to exploit vulnerabilities and wreak havoc. Cybersecurity is the knight in shining armor, defending against the unseen threats that can compromise our privacy, finances, and even national security. In this digital age, where our lives are intricately woven into the fabric of the internet, cybersecurity becomes paramount. It's not just about protecting data; it's about safeguarding the very essence of our interconnected society. The digital battlefield is dynamic and ever-evolving, with new threats emerging daily, making cybersecurity a constant and critical necessity.",
                        },
                        {
                            "heading": "Real-Life Cyber Tales",
                            "description": "Let's delve into some real-life cyber sagas that read like gripping thrillers. The Stuxnet worm, a digital weapon unleashed to sabotage Iran's nuclear program, showcases the power and sophistication of cyber warfare. This tale unravels a covert mission executed in the realm of ones and zeros, underlining the potential for cyber tools to shape geopolitical landscapes. Now, consider the story of Kevin Mitnick, once the world's most-wanted hacker. His journey from the shadows to becoming a cybersecurity consultant demonstrates the transformative power that lies within this dynamic field. Mitnick's story is a testament to the dual nature of hacking skills – they can be used for nefarious purposes or redirected to protect and secure.",
                        },
                        {
                            "heading": "Indian Hackers and the Cyber Showdown",
                            "description": "Within the realm of international cyber conflicts, an episode unfolds where Indian hackers, driven by nationalistic fervor, orchestrated a takedown of several Pakistani government websites. This act of digital defiance not only showcased the capabilities of cyber warriors but also brought to light the geopolitical implications of cyber warfare. During this cyber showdown, Indian hackers utilized a combination of technical prowess and strategic thinking to execute a distributed denial-of-service (DDoS) attack. By overwhelming the targeted websites with traffic, they successfully disrupted access, highlighting the potential impact of cyber actions on a global scale. This episode serves as a stark reminder of the real-world consequences that can result from the clash of cyber forces.",
                        },
                        {
                            "heading": "The Cat-and-Mouse Game",
                            "description": "Cybersecurity is not just about defending; it's a perpetual cat-and-mouse game where defenders evolve to outsmart the attackers. The constant innovation, the adrenaline-pumping chase to stay one step ahead, makes this domain a playground for those with a passion for problem-solving and strategic thinking. In this ongoing battle, cybersecurity professionals employ a myriad of tactics to outmaneuver cybercriminals. From sophisticated encryption algorithms to artificial intelligence-driven threat detection, each move is a calculated response to an ever-changing digital landscape. The cat-and-mouse game is not just a technical challenge but a psychological one, where understanding the motives and strategies of adversaries is as crucial as having robust defense mechanisms.",
                        },
                        {
                            "heading": "Career Opportunities",
                            "description": "Now, let's talk about the vast realm of career opportunities that cybersecurity unfolds before you. From ethical hacking and penetration testing to security analysis and incident response, the options are as diverse as the threats themselves. The demand for cybersecurity professionals is soaring, with industries, governments, and organizations seeking skilled individuals to fortify their digital defenses. Embarking on a cybersecurity career opens doors to a multitude of roles, each contributing to the broader mission of securing our digital future. Ethical hackers, also known as white-hat hackers, play a crucial role in identifying vulnerabilities before malicious actors can exploit them. Penetration testers simulate cyberattacks to assess the resilience of systems, providing valuable insights for strengthening defenses. Security analysts continuously monitor and respond to threats, acting as the frontline defenders in the digital war.",
                        },
                        {
                            "heading": "Roadmap to Cyber Mastery",
                            "description": "Embarking on a cybersecurity journey requires a roadmap. Begin by building a strong foundation in computer science, networking, and programming. Certifications like Certified Ethical Hacker (CEH), Certified Information Systems Security Professional (CISSP), and Offensive Security Certified Professional (OSCP) can add feathers to your cyber cap. Engage in hands-on experiences through internships, capture the flag (CTF) competitions, and real-world projects to sharpen your skills. The roadmap to cyber mastery is a continuous learning journey. It involves staying abreast of the latest technologies, understanding emerging threats, and honing practical skills through real-world applications. Internships provide a bridge between theoretical knowledge and hands-on experience, while participation in CTF competitions cultivates a hacker mindset, essential for navigating the complexities of cybersecurity.",
                        },
                        {
                            "heading": "The Future of Cybersecurity",
                            "description": "As we hurtle towards an increasingly digitized future, the importance of cybersecurity will only intensify. Emerging technologies like artificial intelligence, quantum computing, and the Internet of Things (IoT) bring both opportunities and challenges, necessitating a new breed of cybersecurity professionals to navigate this uncharted territory. Artificial intelligence, for instance, holds the potential to revolutionize threat detection and response, automating repetitive tasks and enabling cybersecurity experts to focus on strategic decision-making. Quantum computing, on the other hand, poses both a threat and an opportunity, as it could break existing encryption methods while also offering new cryptographic techniques. The integration of IoT devices into our daily lives brings a new frontier of vulnerabilities that cybersecurity professionals must navigate, ensuring the security and privacy of interconnected systems.",
                        },
                        {
                            "heading": "Conclusion",
                            "description": "In the realm of cybersecurity, each day presents a new puzzle to solve, a new challenge to conquer. The thrill of defending against digital marauders, the satisfaction of outsmarting sophisticated threats, and the ever-evolving nature of the field make cybersecurity an enticing domain for those with a hunger for knowledge and a passion for protecting the digital world. As you step into this exciting journey, remember: you're not just learning cybersecurity; you're becoming the guardian of the digital realm. The adventure awaits – are you ready to embark on it?",
                        }
                    ])
                },
            ],
            // events: [
            //     {
            //         "id": "1",
            //         "event_id": "abc",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "2",
            //         "event_id": "abc",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "event_id": "abc",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },
            //     {
            //         "id": "3",
            //         "date_time": "Sun, June 11, 2023 5:30pm",
            //         "image": "event.svg",
            //         "title": "Human First",
            //         "description": "Our finance mentors are dedicated to providing tailored support & expert advice to help you.",
            //         "active": 1,
            //     },

            // ],
            // blogs: [
            //     {
            //         "id": "1",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "2",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "3",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "4",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "5",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "6",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "7",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "8",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     },
            //     {
            //         "id": "9",
            //         "image": "event.svg",
            //         "subtitle": "REAL ESTATE PHOTOGRAPHY",
            //         "title": "Top 10 Virtual Staging Software & Companies For Real Estate In 2021",
            //         "description": "The collective marketplaces and exchanges where regular transactions involving the purchase, sale, and issue of shares of publicly traded firms take place is known as the stock market. A stock market is a marketplace where investors may purchase and sell ownership of such investible assets. Stocks, often referred to as equities, represent a portion of ownership in a corporation. Such financial operations are carried out through institutionalised official exchanges or over-the-counter (OTC) markets that follow a clear set of rules. They create a platform for smooth share exchange on the stock market.",
            //         "author_name": "Aman Singh",
            //         "author_image": "author_image.svg",
            //         "date": "AUGUST 13TH 2023",
            //         "tag": "Digital Market",
            //         "popular": 1,
            //         "active": 1,
            //         "content": [
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             },
            //             {
            //                 "heading": "abcd",
            //                 "description": "abcd",
            //             }
            //         ]
            //     }
            // ]
            events: [],
            blogs: [],
        };
        this.getBlogs();
        this.getEvents();
    }

    getBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'blogs', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {
            this.setState({
                blogs: response.data.blogs,
            });

        }
    };
    getEvents = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'events', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {
            this.setState({
                events: response.data.events,
            });

        }
    };

    handleNext = () =>{
        let id= this.state.id;
        if (id < 3){
            this.setState({
                id: id+1,
            })
        } else{
            this.setState({
                id: 1,
            })
        }
    }
    handlePrev = () =>{
        let id= this.state.id;
        if (id > 1){
            this.setState({
                id: id-1,
            })
        } else{
            this.setState({
                id: 3,
            })
        }
    }
    render() {
        const { id,staticBlogs }= this.state;
        return (
            <>
                <HeaderWhite />
                <section className='first-blogs-section'>
                    <div className="container">
                        {staticBlogs ? staticBlogs.map(blog => (
                            <div className={`blog-area ${id!=blog.id?"hide-blog":""}`}>
                                <div className='blogs-flex-row'>
                                    <div className="content m-2">
                                        <h6>{blog.tag}</h6>
                                        <h5>{blog.subtitle}:</h5>
                                        <h4>{blog.title}</h4>
                                        <p>"{blog.description}"</p>
                                        <Link to={{
                                            pathname: '/blog',
                                            state: { blog: blog }
                                        }}><button className='read-now'>Read now</button></Link>
                                    </div>
                                    <div className="content-image m-2">
                                        <img src={blog.image} className='' alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="slide-button">
                                        <button onClick={()=>this.handlePrev()}><i class="fa-solid fa-angle-left"></i></button>
                                        <div className={`${id==1?"selected":""}`}></div>
                                        <div className={`${id==2?"selected":""}`}></div>
                                        <div className={`${id==3?"selected":""}`}></div>
                                        <button onClick={()=>this.handleNext()}><i class="fa-solid fa-angle-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        )) : ''}

                    </div>
                </section>
                <section className='events-section' style={{ backgroundColor: "#F6F6F6" }}>
                    <div className="container">
                        <h2>Our upcoming events</h2>
                        <EventList events={this.state.events} />
                    </div>
                </section>

                <section className='popular-blogs-section'>
                    <div className="container">
                        <h2>Popular Blogs</h2>
                        <PopularBlogs blogs={this.state.blogs} />
                    </div>
                </section>

                <section className='blogs-section'>
                    <div className="container">
                        <h2>All Blogs</h2>
                        <BlogList blogs={this.state.blogs} />
                    </div>
                </section>

                <section className='eigth-section container'>
                    <div className="chat-box">
                        <div className="contact">
                            <h5>Chat to our friendly team</h5>
                            <p>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
                            <div className='flex'>
                                <i className="fa-regular fa-envelope"></i>
                                <div>
                                    <h6>Email</h6>
                                    <p>Our friendly team is here to help.</p>
                                    <h6>support@internzlearn.com</h6>
                                </div>
                            </div>
                            <div className='flex'>
                                <i className="fa-solid fa-phone"></i>
                                <div>
                                    <h6>Phone</h6>
                                    <h6>+91 9606904398</h6>
                                    <h6>+91 9606904399</h6>
                                    <h6>+91 9606904363</h6>
                                </div>
                            </div>
                            <div className='social'>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/fb.svg`} alt="" />
                                </div>
                                <div>
                                    <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/insta.svg`} alt="" /></a>
                                </div>
                                {/* <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/twitter.svg`} alt="" />
                                    </div> */}
                                <div>
                                    <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/linkedin.svg`} alt="" /></a>
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/youtube.svg`} alt="" />
                                </div>


                            </div>

                        </div>
                        <div className="connect">
                            <EnquiryForm />
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
export default Blogs;