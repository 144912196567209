import React, { Component } from 'react';
import axios from 'axios';
import AddBlog from './add-blog';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import 'primeicons/primeicons.css';
class Blogs extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.state = {
            blogs: [],
        };
        this.getBlogs();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }

    getBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'blogs', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                blogs: response.data.blogs,
            });
        }
    };

    setPopularChecked = (id, checked) => {
        let check = checked ? 1 : 0;
        const popularData = {
            "id": id,
            "popular": check,
        }
        fetch(`${process.env.REACT_APP_API_URL}`+'blog/popular', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(popularData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getBlogs();
                }
            })
            .catch(error => {
                console.error('Error:', error);

            });
    };

    setActiveChecked = (id, checked) => {
        let check = checked ? 1 : 0;
        const activeData = {
            "id": id,
            "popular": check,
        }
        fetch(`${process.env.REACT_APP_API_URL}`+'blog/active', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(activeData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getBlogs();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    checkBoxPopularBodyTemplate = (rowData) => {
        return (
            <Checkbox
                onChange={(e) => this.setPopularChecked(rowData.id, e.checked)}
                checked={rowData.popular === 1}
            />
        );
    };

    checkBoxActiveBodyTemplate = (rowData) => {
        return (
            <Checkbox
                onChange={(e) => this.setActiveChecked(rowData.id, e.checked)}
                checked={rowData.active === 1}
            />
        );
    };

    deleteBlog = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}`+'blog/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getBlogs();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    deleteBodyTemplate =(rowData) =>{
        return(
            <Button icon="pi pi-times" rounded text raised severity="danger" onClick={()=>this.deleteBlog(rowData.id)} style={{borderRadius:"50%"}} aria-label="Cancel" />
        )
    }
    editBlog = (id) => {
        this.props.history.push(`/admin/edit-blog/${id}`)
    }
    editBodyTemplate =(rowData) =>{
        return(
            <Button icon="pi pi-file-edit" rounded text raised severity="warning" onClick={()=>this.editBlog(rowData.id)} style={{borderRadius:"50%"}} aria-label="Cancel" />
        )
    }
    render() {
        const columns = [
            { field: 'id', header: 'ID' },
            { field: 'title', header: 'Title' },
            { field: 'subtitle', header: 'Sub Title' },
            { field: 'description', header: 'Description' },
            { field: 'popular', header: 'Popular' },
            { field: 'active', header: 'Active' },
            { field: 'edit', header: 'Edit' },
            { field: 'action', header: 'Action' },
        ];

        return (
            <>
                <div className='container'>
                    <Link to="/admin/add-blog" className="p-button font-bold mt-2 mb-2" style={{textDecoration:"none",color:"white"}}>
                        Add Blog
                    </Link>
                    <DataTable
                        value={this.state.blogs}
                        stripedRows
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column field={columns[0].field} header={columns[0].header} />
                        <Column field={columns[1].field} header={columns[1].header} />
                        <Column field={columns[2].field} header={columns[2].header} />
                        <Column field={columns[3].field} header={columns[3].header} />
                        <Column
                            field={columns[4].field}
                            header={columns[4].header}
                            body={this.checkBoxPopularBodyTemplate}
                        />
                        <Column
                            field={columns[5].field}
                            header={columns[5].header}
                            body={this.checkBoxActiveBodyTemplate}
                        />
                        <Column field={columns[6].field} header={columns[6].header} body={this.editBodyTemplate} />
                        <Column field={columns[7].field} header={columns[7].header} body={this.deleteBodyTemplate} />
                    </DataTable>
                </div>
            </>
        );
    }
}

export default Blogs;
