import React, { Component } from 'react';
import NewsletterForm from '../layout/newsLetterForm';
import { Link } from 'react-router-dom';
import './../../../styles/footer.css'

class Footer extends Component {

    render(){
        return(
            <>
            <section className='footer-area'>
                        <div className='newsletter'>
                            <NewsletterForm />
                        </div>

                        <div className="main-footer">
                                 <div className="show-in-mobile">
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/logo/logo-black.svg`} alt="" />
                                    <h5 >Design amazing digital experiences that create more happy in the world.</h5>
                                </div>
                            <div className="first-container container">
                                <div className="first hide-in-mobile">
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/logo/logo-black.svg`} alt="" />
                                    <h5 >Design amazing digital experiences that create more happy in the world.</h5>
                                </div>
                                {/* <div className='flex'> */}
                                <div className="second">
                                    <p>Placement Assurance</p>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/pap-python-course`}>Python</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/pap-automation-testing-course`}>Automation Testing</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/pap-java-course`}>JAVA</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/pap-dev-ops-course`}>DevOps</Link></h6>
                                </div>
                                {/* <div className="third"> */}
                                <div className="third">
                                    <p>Company</p>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/#testimonials`}>Testimonials</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/#chat-with-us`}>Contact Us</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>Privacy Policy</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/refund-policy`}>Refund Policy</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>Terms & Conditions</Link></h6>
                                </div>
                                <div className="fourth">
                                    <p>Resources</p>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/blogs`}>Blogs</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/blogs/#events`}>Webinars</Link></h6>
                                    <h6><Link to={`${process.env.PUBLIC_URL}/#chat-with-us`}>Support</Link></h6>
                                </div>
                            </div>
                            
                            <div className="second-container container">
                            <hr />
                                    <div className='social-box'>
                                        <h5>© 2023 . All rights reserved.</h5>
                                        <div className='social'>
                                        <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/footer/fb.svg`} alt="" /></a>
                                        <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/footer/insta.svg`} alt="" /></a>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/vector/main/footer/twitter.svg`} alt="" /> */}
                                        <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/footer/linkedin.svg`} alt="" /></a>
                                        <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/footer/youtube.svg`} alt="" /></a>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </section>
            </>
        )
    }
}
export default Footer;