import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Login from './log-in';
import './../../styles/auth.css'
import SimpleReactValidator from 'simple-react-validator';
class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            dateOfBirth: '',
            password: '',
            confirmPassword: '',
            emailOrPhone: '',
            loginPassword: '',
            rememberMe: false,
            signupMessage: '',
            signup: false,
            signupStatus: false,
            loginMessage: '',
            loginStatus: false,
            login: false,
        };
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
        });
    }

    handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
        this.validator.showMessageFor(name);
    };

    handleSubmit = (e) => {
        e.preventDefault();

        // Add API call logic here
        const signUpData = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone_no: this.state.phoneNo,
            date_of_birth: this.state.dateOfBirth,
            password: this.state.password,
        };
        if (this.validator.allValid()) {
        // Example API call using fetch
        fetch(`${process.env.REACT_APP_API_URL}`+'signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signUpData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == true) {
                    this.setState({
                        signup: true,
                        signupStatus: true,
                        signupMessage: data.message,
                    })
                } else {
                    this.setState({
                        signup: true,
                        signupStatus: false,
                        signupMessage: data.message,
                    })
                }
                // console.log('Sign up successful:', data);
                // Handle success or redirect to a success page
            })
            .catch(error => {
                console.error('Error signing up:', error);
                // Handle error or display an error message to the user
            });
        } else {
            this.validator.showMessages();
        }
    };
    handleRedirect =() =>{
        this.props.history.push("/")
    }
    render() {
        var show = {
            display: "block"
        }
        var hide = {
            display: "none"
        }

        return (
            <>
                <section className='auth-page-section'>
                <span className="close-button" onClick={()=>this.handleRedirect()}><i class="fa-solid fa-xmark"></i></span>
                    <div className="main-container">
                    
                        <div className="hide-in-mobile">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/BG.svg`} alt="" />
                        </div>
                        <div className="content-area">
                            <div className='content-box'>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/internz-learn.png`} alt="" />
                                <div className="heading">
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/ .svg`} alt="" />
                                    <h6>Create account</h6>
                                    <p>For business, band or celebrity.</p>
                                </div>
                                <form onSubmit={this.handleSubmit} className='auth-page-section-signup'>
                                    <div className="signup-box">
                                        <div className="form-group">
                                            <label className='form-label'>First Name:</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                className={`form-control  ${this.validator.message('firstName', this.state.firstName, 'required')} `}
                                                value={this.state.firstName}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('firstName', this.state.firstName, 'required', { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group">

                                            <label className='form-label'>Last Name:</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                className={`form-control  ${this.validator.message('lastName', this.state.lastName, 'required')} `}
                                                value={this.state.lastName}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('lastName', this.state.lastName, 'required', { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>Email:</label>
                                            <input
                                                type="text"
                                                name="email"
                                                className={`form-control  ${this.validator.message('email', this.state.email, 'required|email')} `}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>Phone No:</label>
                                            <input
                                                type="text"
                                                name="phoneNo"
                                                className={`form-control  ${this.validator.message('phoneNo', this.state.phoneNo, 'required|size:10')} `}
                                                value={this.state.phoneNo}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('phoneNo', this.state.phoneNo, 'required|size:10', { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>Date of Birth: </label>
                                            <input
                                                type="date"
                                                name="dateOfBirth"
                                                className={`form-control  ${this.validator.message('dateOfBirth', this.state.dateOfBirth, 'required')} `}
                                                value={this.state.dateOfBirth}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('dateOfBirth', this.state.dateOfBirth, 'required', { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>Password:</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className={`form-control  ${this.validator.message('password', this.state.password, 'required')} `}
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            {this.validator.message('password', this.state.password, 'required', { className: 'text-danger' })}
                                        </div>
                                        {/* <div className="form-group">
                                            <label className='form-label'>Confirm Password:</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                className='form-control'
                                                value={this.state.confirmPassword}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                        <div className='form-group'></div> */}
                                        {/* <div className="form-group">
                                            <input
                                                type="checkbox"
                                                name="rememberMe"
                                                className='form-check-input'
                                                checked={this.state.rememberMe}
                                                onChange={this.handleChange}
                                            />
                                            <label className='form-check-label'> &nbsp;Remember Me  </label>

                                        </div> */}
                                        {/* <div className='form-group'></div> */}
                                        

                                        <button type="submit">Create Account</button>
                                    </div>
                                    <div class="alert alert-success" style={this.state.signup ? this.state.signupStatus ? show : hide : hide} role="alert">
                                            {this.state.signupMessage}
                                        </div>
                                        <div class="alert alert-danger" style={this.state.signup ? this.state.signupStatus ? hide : show : hide} role="alert">
                                            {this.state.signupMessage}
                                        </div>
                                </form>
                                <Login />
                            </div>
                        </div>

                    </div>
                </section>



            </>

        );
    }
}

export default SignUpForm;
