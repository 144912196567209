// Navbar.js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../../../styles/navbar.css';
import LogoImage from './black-logo';
import Cookies from 'js-cookie';
import axios from 'axios';
class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      name: '',
      login: false,
    };
    this.getCourses();
  }
  componentDidMount = () => {
    if (Cookies.get('token') !== undefined) {
      let name = Cookies.get('username');
      this.setState({
        login: true,
        name: name,
      });
    }
  }
  getCourses = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'courses', {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });
    if (response.data != null) {
      this.setState({
        categories: response.data.data,
      });
    }
  };

  logout = () => {
    Cookies.remove("token");
    Cookies.remove("username");
    this.setState({
      login: false,
    });
  }
  convertSpaceAndLowercase = (inputString) => {
    // Replace spaces with dashes
    let modifiedString = inputString.replace(/ /g, '-');

    // Convert letters to lowercase
    modifiedString = modifiedString.toLowerCase();

    return modifiedString;
}
  render() {
    const { categories, login, name } = this.state;
    return (
      <div className="container"  id="navbarBlack" style={{ padding: "0px" }}>
        <nav className="navbar navbar-expand-lg black-navbar navbar-dark bg-dark">
          <a className="navbar-brand" href="#"><LogoImage /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul className="navbar-nav navbar-nav-ul">
              <li className="nav-item navbar-li active">
                <Link to="/">Home</Link>
              </li>
              <li className="nav-item navbar-li">
                <Link to="/about-us">About Us</Link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Upskilling Programs </a>
                <ul class="dropdown-menu" style={{ top: "40px" }} aria-labelledby="navbarDropdownMenuLink">
                  {categories.map((category, index) => (
                    <li key={index} class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">{category.category}</a>
                      <ul class="dropdown-menu">
                        {category.courses.map((course, courseIndex) => (
                          <Link key={courseIndex} className="dropdown-item"  to={{pathname:`/course/${course.course_id}/${this.convertSpaceAndLowercase(course.course_name)}`, state: { course: course }}}>
                          {course.course_name}
                        </Link>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
              <li class="nav-item dropdown dropdown-flex">
                <Link className="nav-link" to="/pap-main">Placement Assurance </Link>
                {/* <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#"></a>
              <ul class="dropdown-menu" style={{ top: "30px" }} aria-labelledby="navbarDropdownMenuLink">
              <Link className="dropdown-item" to="/pap-java-course">Java Full Stack</Link>
                <Link className="dropdown-item" to="/pap-python-course">Python Full Stack</Link>
                <Link className="dropdown-item" to="/pap-dev-ops-course">DevOps</Link>
                <Link className="dropdown-item" to="/pap-automation-testing-course">Automation Testing</Link>
              </ul> */}
              </li>
              <li className="nav-item navbar-li">
                <Link to="/corporate-representative">Corporate Representative</Link>
              </li>
              <li className="nav-item navbar-li">
                <Link to="/blogs">Free Blogs</Link>
              </li>
              {login ? <li className="nav-item navbar-li login-profile-dark">
                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/male.svg`} alt="" height="40px" width="40px" class="dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                <ul class="dropdown-menu" style={{ top: "60px" }} aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" onClick={() => this.logout()}>
                    Log out
                  </Link>
                </ul>
                <h6>{name}</h6>
              </li> : <li className="nav-item navbar-li">
                <Link to="/sign-up" className="signup-btn">Sign Up</Link>
              </li>}
            </ul>

          </div>
        </nav>
      </div>
    )
  }
}
export default NavBar;
