import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Sidebar from './sidebar';
import Cookies from 'js-cookie';
class BlogForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();

        this.validator = new SimpleReactValidator();

        this.state = {
            formData: {
                image: '',
                subtitle: '',
                title: '',
                description: '',
                author_name: '',
                author_image: '',
                date: '',
                tag: '',
                popular: 0,
                active: 1,
                content: [{ heading: '', description: '' }],
            },
        };
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleContentChange = (index, field, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                content: prevState.formData.content.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        }));
    };
    addContent = () => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                content: [...prevState.formData.content, { heading: '', description: '' }],
            },
        }));
    };

    removeContent = (index) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                content: prevState.formData.content.filter((item, i) => i !== index),
            },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: files[0] },
        }));
    };
    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };



    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const formData = new FormData();

            // Append each field to FormData
            Object.entries(this.state.formData).forEach(([key, value]) => {
                if (key === 'content') {
                    // Append content array as JSON string
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            });

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'add-blog', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.status) {
                        // this.props.history.push("/admin/blogs");
                        this.props.history.push("/admin/blogs")
                        // You can handle success actions, such as redirecting or showing a success message.
                    } else {
                        console.error('Failed to add blog');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding blog:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { formData } = this.state;

        return (
            <>
                <div className='add-blog container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Title:</label>
                                <input className='form-control' type="text" name="title" value={formData.title} onChange={this.handleInputChange} />
                                {this.validator.message('title', formData.title, 'required|title')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Subtitle:</label>
                                <input className='form-control' type="text" name="subtitle" value={formData.subtitle} onChange={this.handleInputChange} />
                                {this.validator.message('subtitle', formData.subtitle, 'required|subtitle')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Image:</label>
                                <input className='form-control' type="file" name="image" onChange={this.handleFileChange} />
                                {this.validator.message('image', formData.image, 'required|image')}
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Author Name:</label>
                                <input className='form-control' type="text" name="author_name" value={formData.author_name} onChange={this.handleInputChange} />
                                {this.validator.message('author_name', formData.author_name, 'required|author_name')}
                            </div>

                            <div className="form-group">
                                <label className='form-label'>Author Image:</label>
                                <input className='form-control' type="file" name="author_image" onChange={this.handleFileChange} />
                            </div>

                            <div className="form-group">
                                <label className='form-label'>Date:</label>
                                <input className='form-control' type="date" name="date" value={formData.date} onChange={this.handleInputChange} />
                                
                            </div>
                        </div>

                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Description:</label>
                                <textarea className='form-control' name="description" value={formData.description} onChange={this.handleInputChange} rows="4" />
                                {this.validator.message('description', formData.description, 'required|description')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Tag:</label>
                                <input className='form-control' type="text" name="tag" value={formData.tag} onChange={this.handleInputChange} />
                                {this.validator.message('tag', formData.subtitle, 'required|tag')}
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="popular" checked={formData.popular === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Popular
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="active" checked={formData.active === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Active
                                </label>
                            </div>
                        </div>
                        <div className="flex-row" style={{alignItems:"center"}}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>Content:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addContent} style={{borderRadius:"50%"}} />
                        </div>

                        {formData.content.map((item, index) => (
                            <div key={index} className='content-box'>
                                <div>
                                    <label className='form-label'>Heading:</label>
                                    <input className='form-control' type="text" value={item.heading} onChange={(e) => this.handleContentChange(index, 'heading', e.target.value)} />
                                </div>
                                <div>
                                    <label className='form-label'>Description:</label>
                                    <textarea className='form-control' value={item.description} onChange={(e) => this.handleContentChange(index, 'description', e.target.value)} />
                                </div>
                                <Button type="button"  severity="danger" icon="pi pi-times" style={{borderRadius:"50%"}}className='mt-1' onClick={() => this.removeContent(index)} />
                            </div>
                        ))}
                        <Button type="submit"  severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/blogs" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white"}}>
                        Cancel
                    </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default BlogForm;
