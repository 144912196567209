import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Sidebar from './sidebar';
import { Tag } from 'primereact/tag';
import Cookies from 'js-cookie';

class BlogForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();

        this.validator = new SimpleReactValidator();

        // Initialize state with default values or values from props when editing
        const { blog } = this.props.location.state || { blog: null };

        this.state = {
            newFormData: {
                image: '',
                subtitle: '',
                title: '',
                description: '',
                author_name: '',
                author_image: '',
                date: '',
                tag: '',
                content: [{ heading: '', description: '' }],
            },
            oldFormData: {
                image: null,
                subtitle: '',
                title: '',
                description: '',
                author_name: '',
                author_image: '',
                date: '',
                tag: '',
                content: [{ heading: '', description: '' }],
            },
        };
    }

    checkLogin = () => {
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
            window.location = '/admin-login';
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: value },
        }));
    };

    handleContentChange = (index, field, value) => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.newFormData,
                content: prevState.newFormData.content.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        }));
    };

    addContent = () => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.formData,
                content: [...prevState.newFormData.content, { heading: '', description: '' }],
            },
        }));
    };

    removeContent = (index) => {
        this.setState((prevState) => ({
            newFormData: {
                ...prevState.formData,
                content: prevState.newFormData.content.filter((item, i) => i !== index),
            },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: files[0] },
        }));
    };

    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            newFormData: { ...prevState.newFormData, [name]: value },
        }));
    };
    componentDidMount() {
        // Check if the component is in edit mode
        const { match } = this.props;
        const { params } = match;
        const { id } = params;

        if (id) {
            // Fetch event details for editing
            this.fetchBlogData(id);
        }
    }
    fetchBlogData = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}blog/${id}`);

            if (response.ok) {
                const result = await response.json();

                if (result.status) {
                    let data = result.blog;

                    // Set the state with the fetched event data
                    this.setState({
                        newFormData: {
                            image: null,
                            subtitle: data.subtitle,
                            title: data.title,
                            description: data.description,
                            author_name: data.author_name,
                            author_image: null,
                            date: data.date,
                            tag: data.tag,
                            content: JSON.parse(data.content),
                        },
                        oldFormData: {
                            image: data.image,
                            author_image: data.author_image,
                        },
                    });
                } else {
                    console.error('Failed to fetch event details');
                }
            } else {
                console.error('Failed to communicate with the server');
            }
        } catch (error) {
            console.error('Error fetching event details:', error);
        }
    };

    formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); 
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { match } = this.props;
        const { params } = match;
        const { id } = params;


        // if (this.validator.allValid()) {
            const apiURL = `${process.env.REACT_APP_API_URL}update-blog/${id}`;
            const requestData = new FormData();

            // Append each field to FormData
            Object.entries(this.state.newFormData).forEach(([key, value]) => {
                if (key === 'content') {
                    // Append content array as JSON string
                    requestData.append(`newFormData[${key}]`, JSON.stringify(value));
                } else {
                    requestData.append(`newFormData[${key}]`, value);
                }
            });

            Object.entries(this.state.oldFormData).forEach(([key, value]) => {
                if (key === 'content') {
                    // Append content array as JSON string
                    requestData.append(`oldFormData[${key}]`, JSON.stringify(value));
                } else {
                    requestData.append(`oldFormData[${key}]`, value);
                }
            });

            try {
                const response = await fetch(apiURL, {
                    method: 'PUT',
                    body: requestData,
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.status) {
                        this.props.history.push('/admin/blogs');
                        // You can handle success actions, such as redirecting or showing a success message.
                    } else {
                        console.error('Failed to save blog');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding/editing blog:', error);
            }
        // } else {
        //     this.validator.showMessages();
        //     this.forceUpdate();
        // }
    };

    render() {
        const { newFormData, oldFormData } = this.state;

        return (
            <>
                <div className="add-blog container">
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Title:</label>
                                <input className='form-control' type="text" name="title" value={newFormData.title} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Subtitle:</label>
                                <input className='form-control' type="text" name="subtitle" value={newFormData.subtitle} onChange={this.handleInputChange} />
                            </div>
                            <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/blogs/${oldFormData.image}`} alt="" width="500" height="300" />
                            <div className="form-group">

                                <label className='form-label'>Image:</label>
                                <input className='form-control' type="file" name="image" onChange={this.handleFileChange} />
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Author Name:</label>
                                <input className='form-control' type="text" name="author_name" value={newFormData.author_name} onChange={this.handleInputChange} />
                            </div>
                            <img style={{ marginLeft: "20px" }} src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/blogs/${oldFormData.author_image}`} alt="" width="50" height="50" />
                            <div className="form-group">

                                <label className='form-label'>Author Image:</label>
                                <input className='form-control' type="file" name="author_image" onChange={this.handleFileChange} />
                            </div>

                            <div className="form-group">
                                <label className='form-label'>Date:</label>
                                <input className='form-control' type="date" name="date" value={this.formatDateForInput(newFormData.date)} onChange={this.handleInputChange} />

                            </div>
                        </div>

                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Description:</label>
                                <textarea className='form-control' name="description" value={newFormData.description} onChange={this.handleInputChange} rows="4" />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Tag:</label>
                                <input className='form-control' type="text" name="tag" value={newFormData.tag} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>Content:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addContent} style={{ borderRadius: "50%" }} />
                        </div>

                        {newFormData.content.map((item, index) => (
                            <div key={index} className='content-box'>
                                <div>
                                    <label className='form-label'>Heading:</label>
                                    <input className='form-control' type="text" value={item.heading} onChange={(e) => this.handleContentChange(index, 'heading', e.target.value)} />
                                </div>
                                <div>
                                    <label className='form-label'>Description:</label>
                                    <textarea className='form-control' value={item.description} onChange={(e) => this.handleContentChange(index, 'description', e.target.value)} />
                                </div>
                                <Button type="button" severity="danger" icon="pi pi-times" style={{ borderRadius: "50%" }} className='mt-1' onClick={() => this.removeContent(index)} />
                            </div>
                        ))}
                        <Button type="submit" severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/blogs" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white" }}>
                            Cancel
                        </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default BlogForm;
