import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Cookies from 'js-cookie';
import Sidebar from './sidebar';

class EventForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.validator = new SimpleReactValidator();

        this.state = {
            formData: {
                name: '',
                username: '',
                email: '',
                password: '',
                blocked: 0,
            },
        };
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };


    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const formData = this.state.formData

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'add-admin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.status) {
                        this.props.history.push("/admin/list")
                    } else {
                        console.error('Failed to add admin');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding event:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { formData } = this.state;

        return (
            <>
                <div className='add-event container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Name:</label>
                                <input className='form-control' type="text" name="name" value={formData.name} onChange={this.handleInputChange} />
                                {this.validator.message('name', formData.name, 'required|name')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Username:</label>
                                <input className='form-control' type="text" name="username" value={formData.username} onChange={this.handleInputChange} />
                                {this.validator.message('username', formData.username, 'required|username')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Email:</label>
                                <input className='form-control' type="email" value={formData.email} name="email" onChange={this.handleInputChange} />
                                {this.validator.message('email', formData.email, 'required|email')}
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Password:</label>
                                <input className='form-control' type="text" name="password" value={formData.password} onChange={this.handleInputChange} />
                                {this.validator.message('password', formData.password, 'required|password')}
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="blocked" checked={formData.blocked === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Blocked
                                </label>
                            </div>
                        </div>
                        <Button type="submit"  severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/list" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white"}}>
                        Cancel
                    </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default EventForm;
