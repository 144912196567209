import React, { Component } from 'react';
import Header from './../common/headers/header-white'
import Footer from './../common/footer/footer'
import axios from 'axios';
import EnquiryForm from '../common/layout/enquiry-form';
import SimpleReactValidator from 'simple-react-validator';
import './../../styles/corporate.css'
class Corporate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonValue: 'Apply',
            formData: {
                name: '',
                email: '',
                number: '',
                worktype: '',
                resume: null,
            },
        };
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
        });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
        this.validator.showMessageFor(name);
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: files[0] },
        }));
        this.validator.showMessageFor(name);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const { formData } = this.state;
            const apiFormData = new FormData();
            apiFormData.append('name', formData.name);
            apiFormData.append('email', formData.email);
            apiFormData.append('number', formData.number);
            apiFormData.append('worktype', formData.worktype);
            apiFormData.append('resume', formData.resume);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'submit-form', {
                    method: 'POST',
                    body: apiFormData,
                });

                if (response.ok) {
                    this.setState({
                        buttonValue: "Applied!",
                    })
                    // You might want to redirect or show a success message here
                } else {
                    console.error('Failed to submit data');
                }
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        } else {
            this.validator.showMessages();
        }
    };

    render() {
        const { formData, button } = this.state;
        const style = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/corporate/first-image.svg)`,
            backgroundSize: 'cover',  // or 'contain' depending on your preference
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return (
            <>
                <Header />
                <section className='first-corporate-section' style={style}>
                    <div className="container">
                        <div className="flex-row">
                            <div className='content-section'>
                                <h1>Become an  Authorised Corporate Representative</h1>
                            </div>
                            <div className='image-section hide-in-mobile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/corporate/first-image-section.svg`} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='second-corporate-section'>
                    <div className="container">
                        <h3>Who is Corporate Representative?</h3>
                        <h6>Explore the world of Campus Ambassador Programs—your gateway to becoming a brand representative, gaining exposure, connecting with peers, and earning alongside your education. Uncover the benefits, acquire valuable skills, and discover how you can join.</h6>
                        <div className="container content-area flex-row" style={{maxWidth:"100%"}}>
                        <img  src={`${process.env.PUBLIC_URL}/assets/vector/corporate/unlock.svg`} alt="" />
                        <img className="hide-in-mobile" src={`${process.env.PUBLIC_URL}/assets/vector/corporate/second-image.svg`} alt="" />
                        </div>
                    </div> 
                </section>

                
                <section className='third-corporate-section'>
                    <div className="container">
                        <h2>Job Application</h2>
                        {/* <form action=""> */}
                        <div className='form-box'>
                            <div className="form-group">
                                <label className="form-label">Full Name</label>
                                <input className={`form-control  ${this.validator.message('name', formData.name, 'required')}`} value={formData.name} onChange={this.handleInputChange} type="text" name="name" id="" />
                                {this.validator.message('name', formData.name, 'required', { className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email</label>
                                <input className={`form-control  ${this.validator.message('email', formData.email, 'required')}`} value={formData.email} onChange={this.handleInputChange} type="email" name="email" id="" />
                                {this.validator.message('email', formData.email, 'required|email', { className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Contact No.</label>
                                <input className={`form-control  ${this.validator.message('number', formData.number, 'required|size:10')}`} value={formData.number} onChange={this.handleInputChange} type="number" name="number" id="" />
                                {this.validator.message('number', formData.number, 'required|size:10', { className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Are You Student / Graduated / Wroking? </label>
                                <select className={`form-control  ${this.validator.message('worktype', formData.worktype, 'required')}`} value={formData.worktype} onChange={this.handleInputChange} name="worktype">
                                    <option value="">Select</option>
                                    <option value="student">Student</option>
                                    <option value="graduated">Graduated</option>
                                    <option value="working-professional">Working professional</option>
                                </select>
                                {this.validator.message('worktype', formData.worktype, 'required', { className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Upload CV</label>
                                <input className={`form-control  ${this.validator.message('resume', formData.resume, 'required')}`} name="resume" onChange={this.handleFileChange} type="file" id="" />
                                {this.validator.message('resume', formData.resume, 'required', { className: 'text-danger' })}
                            </div>
                        </div>
                        <button className='apply-button' onClick={(e) => this.handleSubmit(e)} disabled={this.state.buttonValue == "Applied!"}>{this.state.buttonValue}</button>
                        {/* </form> */}
                    </div>
                </section>
                <section className='eigth-section container'>
                    <div className="chat-box">
                        <div className="contact">
                            <h5>Chat to our friendly team</h5>
                            <p>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
                            <div className='flex'>
                                <i className="fa-regular fa-envelope"></i>
                                <div>
                                    <h6>Email</h6>
                                    <p>Our friendly team is here to help.</p>
                                    <h6>support@internzlearn.com</h6>
                                </div>
                            </div>
                            <div className='flex'>
                                <i className="fa-solid fa-phone"></i>
                                <div>
                                    <h6>Phone</h6>
                                    <h6>+91 9606904398</h6>
                                    <h6>+91 9606904399</h6>
                                    <h6>+91 9606904363</h6>
                                </div>
                            </div>
                            <div className='social'>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/fb.svg`} alt="" />
                                </div>
                                <div>
                                    <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/insta.svg`} alt="" /></a>
                                </div>
                                {/* <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/twitter.svg`} alt="" />
                                    </div> */}
                                <div>
                                    <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/linkedin.svg`} alt="" /></a>
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/youtube.svg`} alt="" />
                                </div>


                            </div>

                        </div>
                        <div className="connect">
                            <EnquiryForm />
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}


export default Corporate;