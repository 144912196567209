import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import axios from 'axios';
import './styles/admin.css';
import Cookies from 'js-cookie';

class EventForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();

        this.validator = new SimpleReactValidator();

        this.state = {
            formData: {
                code: '',
                coupon_description: '',
                amount: '',
                user_id: '',
                single: 0,
                active: 1,
            },
            users: [],
        };
        this.getUsers();
    }
    checkLogin = () => {
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
            window.location = '/admin-login'
        }
    }
    getUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'users', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data.status == true) {
            this.setState({
                users: response.data.results,
            });
        }
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: files[0] },
        }));
    };
    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const formData = this.state.formData;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}` + 'add-coupon', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.status) {
                        // this.props.history.push("/admin/events");
                        this.props.history.push("/admin/coupons")
                        // You can handle success actions, such as redirecting or showing a success message.
                    } else {
                        console.error('Failed to add event');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding event:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { formData, users } = this.state;

        return (
            <>
                <div className='add-event container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Code: </label>
                                <input className='form-control' type="text" name="code" value={formData.code} onChange={this.handleInputChange} />
                                {this.validator.message('code', formData.code, 'required|code')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Description: </label>
                                <input className='form-control' type="text" name="coupon_description" value={formData.coupon_description} onChange={this.handleInputChange} />
                                {this.validator.message('coupon_description', formData.coupon_description, 'required|coupon_description')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Amount: </label>
                                <input className='form-control' type="number" name="amount" value={formData.amount} onChange={this.handleInputChange} />
                                {this.validator.message('amount', formData.amount, 'required|amount')}
                            </div>

                        </div>
                        <div className="">
                            <div class="form-check">
                                <input class="form-check-input" name="single" checked={formData.single === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Single User
                                </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className='form-label'>User</label>
                                <select class="form-select" name="user_id" id="" onChange={this.handleInputChange}>
                                    <option value="">Select User</option>
                                    {users.map((user, index) => (
                                        <option key={index} value={user.user_id}>{user.phone_no +" | "+ user.email}</option>
                                    ))}
                                </select>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" name="active" checked={formData.active === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Active
                                </label>
                            </div>
                        </div>
                        <Button type="submit" severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/events" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white" }}>
                            Cancel
                        </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default EventForm;
