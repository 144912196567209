import React, { useState } from 'react';

const StarRating = ({ color }) => {
  const [rating, setRating] = useState(generateRandomRating());

  function generateRandomRating() {
    const ratings = [4, 4.5, 5];
    const randomIndex = Math.floor(Math.random() * ratings.length);
    return ratings[randomIndex];
  }

  function renderStars() {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={i} className="fa-solid fa-star"></i>);
    }

    if (hasHalfStar) {
      stars.push(<i key="half" className="fa-solid fa-star-half-stroke"></i>);
    }

    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<i key={`empty-${i}`} className="fa-regular fa-star"></i>);
    }

    return stars;
  }

  return (
    <div className={color=="orange"?"orange-rating-star":"rating-star"} onClick={() => setRating(generateRandomRating())}>
      {renderStars()}
    </div>
  );
};

export default StarRating;

