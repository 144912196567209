import React, { Component } from 'react';
import axios from 'axios';
import AddBlog from './add-event';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import 'primeicons/primeicons.css';
class Events extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.state = {
            events: [],
        };
        this.getAdmin();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    getAdmin = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'admins', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                events: response.data.results,
            });
        }
    };
    

    setActiveChecked = (id, checked) => {
        let check = checked ? 1 : 0;
        const activeData = {
            "id": id,
            "blocked": check,
        }
        fetch(`${process.env.REACT_APP_API_URL}`+'admin/blocked', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(activeData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getAdmin();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    checkBoxActiveBodyTemplate = (rowData) => {
        return (
            <Checkbox disabled={rowData.id==1?true:false}
                onChange={(e) => this.setActiveChecked(rowData.id, e.checked)}
                checked={rowData.blocked === 1}
            />
        );
    };

    render() {
        const columns = [
            { field: 'id', header: 'ID' },
            { field: 'name', header: 'Name' },
            { field: 'username', header: 'Username' },
            { field: 'email', header: 'Email' },
            { field: 'blocked', header: 'Block' },
        ];

        return (
            <>
                <div className='container'>
                    <Link to="/admin/add-admin" className="p-button font-bold mt-2 mb-2" style={{textDecoration:"none",color:"white"}}>
                        Add Admin
                    </Link>
                    <DataTable
                        value={this.state.events}
                        stripedRows
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column field={columns[0].field} header={columns[0].header} />
                        <Column field={columns[1].field} header={columns[1].header} />
                        <Column field={columns[2].field} header={columns[2].header} />
                        <Column field={columns[3].field} header={columns[3].header} />
                        <Column
                            field={columns[4].field}
                            header={columns[4].header}
                            body={this.checkBoxActiveBodyTemplate}
                        />
                    </DataTable>
                </div>
            </>
        );
    }
}

export default Events;
