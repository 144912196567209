import React ,{ useState}from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";


const DynamicTable = ({ columns, data }) => {
  return (
    <DataTable value={data} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 50, 100]}>
      {columns.map((column, index) => (
        <Column key={index} field={column.field} header={column.header} />
      ))}
    </DataTable>
  );
};

export default DynamicTable;