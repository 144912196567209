

import React, { Component } from 'react';

import Footer from '../common/footer/footer';
import HeaderWhite from '../common/headers/header-white';
class RefundPolicy extends Component {

    render() {
        return (
            <>
                <HeaderWhite />
                <section>
                    <div class="container mb-3">
                        <div class="services-quality-wrapper">
                            <div class="">
                                <div>
                                    <br />
                                    <h4 class="services-quality-title"> <u>  Payment and Refund</u></h4>
                                    <p>The Platform is a paid service and the payments made by You shall be according to the plans opted by You through the Platform. You explicitly agree to pay the fees for the courses/ plans that you purchase, and you authorize the Company to charge you as per the applicable payment mode opted by you. All payments shall be through the payment mechanism put in place by the Company and You shall be responsible for paying all fees and applicable taxes in a timely manner as per the mechanism associated with the Plan availed by You. Users have been given various options to choose and proceed with the payment for the Services via payment mechanisms including but not limited to Card Payment facilities, Online Bank Transfer and Wallet Payment. With reference to Section 6, the payment gateway mechanisms are governed by the terms and conditions of the third-party providers as listed on the Platform and the User agrees to be bound by those terms.</p> <br />

                                    <p>Valid Credit / Debit/ Cash Card/ online bank transfers and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms agreed to between the Users and the respective issuing bank and payment instrument issuing company. We shall not be liable for any unauthorized use, fraud, payment refunds, lost amount etc. in the transaction. We are nor offering any refunds for the workshops conducted.	 </p><br />
                                    <p>We reserve the right to change any fees at any time at our sole discretion and any change, update, or modification in the fee shall become effective immediately upon the same being posted/uploaded or notified on our website.</p>
                                    <br />
                                    <h4 class="services-quality-title mb-4"><u>Refund Policy</u></h4>

                                    <p>We are not offering any refunds for the Courses provided by InternzLearn . All payments once made are final and not subject to any cancellations or refunds. The User is advised to go through the exhaustive information of the  Course and only then proceed towards the payment getaway. There will be no refunds made from our end.	</p>
                                    <p>By making payments You accept the no-refund policy along with other terms and conditions mentioned on the website/platforms.	</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }



}
export default RefundPolicy;