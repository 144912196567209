import React, { Component } from 'react';
import "./../../../styles/coupons.css";
import Cookies from 'js-cookie';
class CouponsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enteredCoupon: '',
            existingCoupons: [],
            amount: 0,
            wrongCouponCode: false,
            couponCodeApplied: false,
            applyButton: true,
        };
    }
    

    // componentDidMount() {
    //     const userId = Cookies.get("token")
    //     fetch(`${process.env.REACT_APP_API_URL}` + 'existing-coupons/' + userId)
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.status == true) {
    //                 this.setState({
    //                     existingCoupons: data.coupons
    //                 })
    //             }
    //         })
    //         .catch(error => console.error('Error fetching coupons:', error));
    // }
    componentDidUpdate(prevProps) {
        // Check if the amount prop has changed
        if (prevProps.amount !== this.props.amount) {
            this.setState({
                amount: this.props.amount,
            });
        }
    }
    setupInitialAmount = () =>{
            this.setState({
                amount: this.props.amount,
            });
    }
    applyExistingCoupon = (amount) => {
        this.setState({
            amount: this.props.amount - amount,
            couponCodeApplied: true,
            wrongCouponCode: false,
        })
    };
    handleCouponInput = (event) => {
        if (event.target.value != ""){
            this.setState({
                applyButton: false
            })
        }
        this.setState({ enteredCoupon: event.target.value });
    };

    verifyCoupon = async () => {
        this.setupInitialAmount()
     
        let formData = {
            couponCode: this.state.enteredCoupon,
            userId: "amans"
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}` + 'verify-coupon', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status) {
                    this.setState({
                        amount: this.state.amount-result.amount,
                        wrongCouponCode: false,
                        couponCodeApplied: true,
                    })
                } else {
                    this.setState({
                        wrongCouponCode: true,
                        couponCodeApplied: false,
                    })
                }
            } else {
                console.error('Failed to communicate with the server');
            }
        } catch (error) {
            console.error('Error adding event:', error);
        }
    }

    pay = () => {
        const { amount } = this.state;
        this.props.onPay(amount);
    };

    render() {
        const { isOpen, onClose } = this.props;
        const { amount } = this.state;
        const { enteredCoupon, existingCoupons, wrongCouponCode, couponCodeApplied } = this.state;

        return (
            <div className={`coupons ${isOpen ? 'open' : 'close'}`}>
                <div className={`modal modal-overlay`}>
                    <div className="modal-content">
                        <span className="close" onClick={onClose}>Cancel</span>
                        <h2>Do you have any coupon?</h2>
                        <div className="coupon-input-box flex-row">
                            <input type="text" className='form-control' placeholder="Enter Coupon Code" value={enteredCoupon} onChange={this.handleCouponInput} />
                            <button className='btn btn-success' onClick={this.verifyCoupon} disabled={this.state.applyButton}>Apply</button>
                        </div>
                        <div className={`alert-danger ${wrongCouponCode? "": "wrong-hide"}`} style={{textAlign:"center"}}>
                            Invalid Coupon Code!
                        </div>
                        <div className={`alert-success ${couponCodeApplied? "": "wrong-hide"}`} style={{textAlign:"center"}}>
                            Coupon Applied!
                        </div>

                        {/* Display existing coupons */}
                       {/* <h4>Existing Coupons</h4>
                        <div className="existing-coupons">
                            {existingCoupons.slice(0,4).map((coupon, index) => (
                                <div key={coupon.id} className={`existing-coupon-box ${index % 2 === 0 ? 'grey' : ""}`}>
                                    <div><h6 >{coupon.code} </h6> <p>{coupon.coupon_description}</p></div>
                                    <button className='btn btn-sm btn-secondary' onClick={() => this.applyExistingCoupon(coupon.amount)} >Apply</button>
                                </div>

                            ))}
                        </div>
                            */}
                        <div>
                            <h6 className='total-fee-amount'>Total Amount: <span>{amount}</span></h6>
                            <button className='apply-button' onClick={this.pay}>Continue to Pay</button>
                        </div>

                    </div>
                </div>
            </div >
        );
    }
}

export default CouponsModal;
