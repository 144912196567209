import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Cookies from 'js-cookie';
import Sidebar from './sidebar';

class EventForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();

        this.validator = new SimpleReactValidator();

        this.state = {
            formData: {
                image: '',
                title: '',
                description: '',
                event_datetime: '',
                active: 1,
            },
        };
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: files[0] },
        }));
    };
    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const formData = new FormData();

            // Append each field to FormData
            Object.entries(this.state.formData).forEach(([key, value]) => {
                if (key === 'content') {
                    // Append content array as JSON string
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            });

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'add-event', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.status) {
                        // this.props.history.push("/admin/events");
                        this.props.history.push("/admin/events")
                        // You can handle success actions, such as redirecting or showing a success message.
                    } else {
                        console.error('Failed to add event');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding event:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { formData } = this.state;

        return (
            <>
                <div className='add-event container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Title:</label>
                                <input className='form-control' type="text" name="title" value={formData.title} onChange={this.handleInputChange} />
                                {this.validator.message('title', formData.title, 'required|title')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Description:</label>
                                <textarea className='form-control' name="description" value={formData.description} onChange={this.handleInputChange} rows="4" />
                                {this.validator.message('description', formData.description, 'required|description')}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Image:</label>
                                <input className='form-control' type="file" name="image" onChange={this.handleFileChange} />
                                {this.validator.message('image', formData.image, 'required|image')}
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Event Date time:</label>
                                <input className='form-control' type="datetime-local" name="event_datetime" value={formData.event_datetime} onChange={this.handleInputChange} />
                                {this.validator.message('event_datetime', formData.event_datetime, 'required|event_datetime')}
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="active" checked={formData.active === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                                <label class="form-check-label" for="">
                                    Active
                                </label>
                            </div>
                        </div>
                        <Button type="submit"  severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/events" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white"}}>
                        Cancel
                    </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default EventForm;
