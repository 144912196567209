import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Sidebar from './sidebar';
import axios from 'axios';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.checkLogin()
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    initiatePayment = async () =>{

        // const user_id= Cookies.get("token")
        const user_id= "aman";
            // const { enqData } = this.state;
         const amount = 1.00;
         const course_id ="amanan"
         const type="pap-java"
         const paymentData={
            user_id: user_id,
            amount: amount,
            course_id: course_id,
            type: type,
         }
         try {
          fetch(`${process.env.REACT_APP_API_URL}`+'initiate-payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    // console.log(data.data)
                    // this.getCourses();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

           
                // const response = await axios.get(`${process.env.REACT_APP_API_URL}`+'initiate-payment', JSON.stringify(paymentData));

                // if (response.status === 200) {
                //     this.setState({
                //         buttonValue: 'Sent!',
                //     });
                //     // You might want to redirect or show a success message here
                // } else {
                //     console.error('Failed to submit data');
                // }
            } catch (error) {
                console.error('Error submitting data:', error);
            } 
            const form = document.createElement('form');
  form.method = 'post';
  form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

  // Add hidden input fields for the required parameters
  const encRequestInput = document.createElement('input');
  encRequestInput.type = 'hidden';
  encRequestInput.name = 'encRequest';
  encRequestInput.value = '521450c3d2b1bdb003c4055f081698858852f6347d05d7e4d2cc4c273792d1448cf601d1308457e44f4f5cc852021c35cad611e65b2da153ef7d439da4332f86939b4dc9defc466182956a76b931826f91ac7f9093ebdc278da4aa9a9c07f01877f80a98fd659638171a2b92d938538b0be4a9e7490f853d1c93ea4a9af9cf47521ff1f70133ea82452023acbd87b6e473bfc3ee16a5ecf7d7125ceefadcf3cc6e05f7d62429d9d622580478ba30d2b91958415160c49f047746a9ff5702d6f9'; // Replace with the actual encrypted order data
  form.appendChild(encRequestInput);

  // Add any other required parameters as hidden input fields

  // Append the form to the document
  document.body.appendChild(form);

  // Submit the form
//   form.submit();

  // Remove the form from the document (optional)
  document.body.removeChild(form);     
    }
    render(){
        
        return (
            <>
            <Sidebar />
            {/* <button onClick={()=>this.initiatePayment()}>
                pay
            </button> */}
            
            </>
        )
    }
}


export default Dashboard;