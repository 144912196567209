import React, { Component, useState } from 'react';
import axios from 'axios';
import Header from './common/headers/header-white'
import './../styles/main.css'
import Footer from './common/footer/footer'
import RatingStar from './common/layout/rating-star'
import CountUpBanner from './common/layout/count-up-banner'
import BannerLogo from './common/layout/banner-logo';
import Slider from "react-slick";
import EnquiryForm from './common/layout/enquiry-form';
import { Link } from 'react-router-dom';
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            courses: [],
            selectedCategory: "Computer Science",
            showAllCourses: false,
            displayedCourses: 3,
            enqData: {
                name: '',
                email: '',
                reason: '',
                message: '',
              },
              buttonValue: 'Send a message',
        };
        this.getCourses();

    }
    scrollToSection = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('courses');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    handleShowMore = () => {
        this.setState({
            showAllCourses: true,
            displayedCourses: this.state.courses.length,
        });
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
          enqData: { ...prevState.enqData, [name]: value },
        }));
      };
    getCourses = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'courses', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {
            this.setState({
                categories: response.data.data,
                courses: this.filterCoursesByCategory(response.data.data)
            });
            //  this.setState({
            //     categories: response.data.categories,
            //     courses: this.filterCoursesByCategory(response.data.categories)
            // });
        }
    };

    handleCategoryClick = (category) => {
        this.setState({
            selectedCategory: category,
            showAllCourses: false,
        }, () => {
            // Use the callback to ensure you're working with the latest state
            const updatedCourses = this.filterCoursesByCategory(this.state.categories);
            this.setState({
                courses: updatedCourses,
            });
        });

    };

    handleSubmitEnquiry = async (e) => {
        e.preventDefault();
    
        const { enqData } = this.state;
        const enquiryFormData = new FormData();
        enquiryFormData.append('name', enqData.name);
        enquiryFormData.append('email', enqData.email);
        enquiryFormData.append('reason', enqData.reason);
        enquiryFormData.append('message', enqData.message);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/submit-enquiries`, {
                method: 'POST',
                body: enquiryFormData,
            });
            if (response.ok) {
                this.setState({
                    buttonValue: "Sent!",
                });
                // You might want to redirect or show a success message here
            } else {
                console.error('Failed to submit data');
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };
 
    filterCoursesByCategory = (categories) => {
        const { selectedCategory } = this.state;
        const selectedCategoryObject = categories.find(category => category.category === selectedCategory);
        const courses = selectedCategoryObject ? selectedCategoryObject.courses : [];
        return courses;
    };
     convertSpaceAndLowercase = (inputString) => {
        // Replace spaces with dashes
        let modifiedString = inputString.replace(/ /g, '-');
    
        // Convert letters to lowercase
        modifiedString = modifiedString.toLowerCase();
    
        return modifiedString;
    }
    

    render() {
        const { categories, courses, enqData } = this.state;
        const { showAllCourses, displayedCourses } = this.state;
        const coursesToShow = showAllCourses
            ? this.state.courses
            : this.state.courses.slice(0, displayedCourses);
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                  }
                ]

        };
        let isDisplay = this.state.showAllCourses ? "none" : "block";
        const style = {
            display: isDisplay
        }

        return (
            < >
                <Header/>
                {/* <div  style={{backgroundColor:"blue"}}> */}
                <div>
                    <section className='container main-first-section'>
                        <div className="row">
                            <div className="left-card-box col-md-6">
                                <h1>Launching Careers to New Heights</h1>
                                <h4>Discover a path to success like no other.
                                    At InternzLearn, we're on a mission to empower you
                                    with game-changing skills, real-world experience,
                                    and boundless opportunities.
                                    Your career's next chapter begins here</h4>
                                <button onClick={this.scrollToSection}>Explore courses now <i className="fa-solid fa-angles-down"></i></button>
                                <div className='circle-parent-container'>
                                    <div className="circle-container">
                                    <div className="circle" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse1.svg)`,backgroundSize:"cover"}}></div>
                                <div className="circle" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse2.svg)`,backgroundSize:"cover"}}></div>
                                <div className="circle" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse3.svg)`,backgroundSize:"cover"}}></div>
                                        <div className="circle-text">15000+ Happy Students</div>
                                    </div>
                                </div>

                            </div>
                            <div className="right-card-box col-md-6">
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/main/first-section/HomeVectorTop.svg`} alt="" width={"100%"} />
                            </div>
                        </div>
                    </section>

                    <section className='w-100' style={{ "backgroundColor": "#000" }}>
                        <CountUpBanner />
                    </section>
                    <section className=' second-section'>

                        <div className="company-banner">
                            <p>Our learner's Placed at-</p>
                            <div>
                            <BannerLogo />
                            </div>
                        </div>
                    </section>
                    <div className="container-fluid bg-color-f0f0f0">
                        <section className='third-section container'>
                            <h2>Why Choose Us?</h2>
                            <div className='content-area'>
                                <div className='left-box'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/card0_vector.png`} alt="" className='card-0' />
                                </div>
                                <div className="right-box">
                                    <div className="card-box ">
                                        <div>
                                            <div className='row card-box-head'>
                                                <p>Expert </p>
                                                <p>Faculty</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/card1_vector.png`} alt="" />
                                        </div>
                                        <div className="card-box-content mt-2">
                                            <p>Learn from seasoned professionals and industry leaders who bring their real-world experience to the classroom.</p>
                                        </div>

                                    </div>
                                    <div className="card-box ">
                                        <div>
                                            <div className="row card-box-head">
                                                <p>Flexible </p>
                                                <p>Learning</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/card2_vector.png`} alt="" />
                                        </div>
                                        <div className="card-box-content mt-2">
                                            <p>Our online and in-person training options cater to diverse learning preferences and schedules.</p>
                                        </div>

                                    </div>
                                    <div className="card-box ">
                                        <div>
                                            <div className="row card-box-head">
                                                <p>Hands-on</p>
                                                <p>Experience</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/card3_vector.png`} alt="" />
                                        </div>
                                        <div className="card-box-content mt-2">
                                            <p>Gain practical skills through projects, workshops, and industry internships.</p>
                                        </div>

                                    </div>
                                    <div className="card-box ">
                                        <div>
                                            <div className="row card-box-head">
                                                <p>Industry</p>
                                                <p>Relevant </p>
                                                <p>Curriculum</p>
                                            </div>

                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/card4_vector.png`} alt="" />
                                        </div>
                                        <div className="card-box-content mt-2">
                                            <p>Our courses are regularly updated to align with the latest industry trends and demands.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className='fourth-section container mb-5'>
                        <div className='fourth-card-box mt-5'>
                            <div className='fourth-card-content-box col-md-8'>
                                <h3>Equipping You for Success with <span className='first-span'>Internz</span><span className='second-span'>Learn</span></h3>
                                <p>At Internz Learn, our commitment extends beyond traditional education. We provide a dynamic learning environment that not only equips you with essential skills but also fosters a mindset for continuous growth. Join us to experience a holistic approach to education, ensuring you not only achieve professional excellence but also thrive in your dream career.</p>
                                <a href="/about-us"><button>Know more</button></a>
                            </div>
                            <div className='fourth-card-content-box-image col-md-4'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/main/fourth-section/learnfrombest.svg`} alt="" />
                            </div>
                        </div>
                    </section>
                    <div className="container-fluid bg-color-f0f0f0">
                        <section className='fifth-section ' id="courses">
                        <div className="courses-area mb-5 container">
                            <h2>Popular Courses</h2>
                            <div className='details-box container'>
                                <div className='box'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/fifth-section/Flag.svg`} alt="" />
                                    <div>
                                        <p>Skill Level</p>
                                        <h6>Begineer</h6>
                                    </div>
                                </div>
                                <div className='box'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/fifth-section/CalendarCheck.svg`} alt="" />
                                    <div >
                                        <p>Duration</p>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                                <div className='box'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/fifth-section/Trophy.svg`} alt="" />
                                    <div>
                                        <p>Certificate</p>
                                        <h6>Worldwide</h6>
                                    </div>
                                </div>
                                <div className='hide-in-mobile box'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/fifth-section/ListChecks.svg`} alt="" />
                                    <div>
                                        <p>Prerequisite</p>
                                        <h6>None</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='courses-parent-box'>
                                <div className='courses-box'>
                                    {categories.map((category, index) => (
                                        <button
                                            key={index}
                                            className={`courses-button ${this.state.selectedCategory === category.category ? 'active' : ''}`}
                                            onClick={() => this.handleCategoryClick(category.category)}
                                        >
                                            {category.category}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className='course-parent-box'>
                                {coursesToShow ? coursesToShow.map((course, index) => (

                                    <div className='course-box' key={index}>
                                        <img src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.image}`} alt="" />
                                        <div>
                                            <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <h4 style={{ "display": "flex" }}><i className="fa-solid fa-play " style={{ "paddingTop": "2px" }}></i>&nbsp; {course.duration} Months</h4>
                                                <h4><span>{course.category}</span></h4>
                                            </div>
                                            <h5>{course.course_name}</h5>
                                            <hr />
                                            <div className='course-box-mentor'>
                                                <img src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.mentor_image}`} alt="" />
                                                <div>
                                                    <h6>{course.mentor_name}</h6>
                                                    <p>{course.profession}</p>
                                                </div>
                                                <h5 className='m-auto'>{course.students} Students</h5>

                                            </div>
                                            <hr />
                                            <div className='course-box-footer'>
                                                <RatingStar color="orange" />
                                                <Link to={{pathname:`/course/${course.course_id}/${this.convertSpaceAndLowercase(course.course_name)}`, state: { course: course }}} style={{ "border": "none", "background": "#FFF", "color":"#000","paddingRight":"10px" }}><u>Know More</u></Link>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div></div>}
                            </div>
                        </div>
                        <div className='explore-button-div'>
                            <button style={style} onClick={() => this.handleShowMore()}>Explore all Courses</button>
                        </div>
                    </section>
                    </div>
                    <section className='sixth-section container mb-5'>
                        <div className='discover-heading'><h3>Discover what makes us exceptional</h3></div>
                        <div className="container">
                            <div className="discover-card-box">
                                <Slider {...settings}>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image1.svg`} alt="" />
                                            <h6>Certificates</h6>
                                            <p>Celebrate your accomplishments with shareable certificates as you reach milestones on your journey</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image2.svg`} alt="" />
                                            <h6>Gamified Lessons</h6>
                                            <p>Learn with interactive exercises, challenges, and projects, crafted by learning experts and backed by research</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image3.svg`} alt="" />
                                            <h6>Real-World Projects</h6>
                                            <p>Build projects that apply your coding skills in real-life scenarios</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image4.svg`} alt="" />
                                            <h6>Leaderboards</h6>
                                            <p>Compete with fellow learners and climb the ranks as you complete exercises, challenges, and projects</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image5.svg`} alt="" />
                                            <h6>Playgrounds</h6>
                                            <p>Unleash your creativity, from trying out concepts to prototyping your ideas and realizing your projects.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="disc-card">
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/main/sixth-section/image6.svg`} alt="" />
                                            <h6>Community</h6>
                                            <p>Connect with the community to get guidance, share insights, and collaborate with your fellow learners</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </section>
                    {/* <Discover /> */}
                    <div className="container-fluid bg-color-f0f0f0">
                        <section className='testimonial-section container mb-5' id="testimonials">
                            {/* <div className=' container'> */}
                            <div className='testimonial-heading'><h6>Don't Just Take Our Word for It, Hear from Our Students</h6></div>
                            <div className="testimonial-card">
                                <div className="">
                                    <RatingStar />
                                    <p>InternzLearn has been a game-changer for my career. The mentorship and guidance I received were instrumental in shaping my skills and making me industry-ready. A heartfelt thank you for the incredible support!</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/girl-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Mansi</h4>
                                            <h5>Web Development</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>InternzLearn exceeded my expectations with their outstanding mentorship and guidance. The commitment to making students industry-ready is truly remarkable. Thank you for helping me pave the way for a successful career.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Harshit Singh</h4>
                                            <h5>Cyber Security</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>I can't express my gratitude enough for the mentorship and guidance provided by InternzLearn. Their commitment to student success is evident. Thank you for the invaluable experience. A heartfelt thank you.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Rahul Kumar</h4>
                                            <h5>Artificial Intelligence</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>InternzLearn exceeded my expectations with their outstanding mentorship and guidance. The commitment to making students industry-ready is truly remarkable. Thank you for helping me pave the way for a successful career.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Suraj Sharma</h4>
                                            <h5>Data Science</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>Thank you, InternzLearn, for the exceptional mentorship and guidance that played a pivotal role in my career development. The commitment to making students industry-ready. Grateful for the valuable experience.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Rohit</h4>
                                            <h5>VLSI</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>InternzLearn has been my guiding light in the professional realm. The mentorship and guidance received were beyond valuable. A heartfelt thank you for your commitment to shaping future industry leaders.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Harendra</h4>
                                            <h5>Sales & Marketing</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>My sincere gratitude to InternzLearn for the exceptional mentorship and guidance. Their commitment to making students industry-ready is evident in every aspect of their program. Thank you for being a catalyst in my career journey.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Yash</h4>
                                            <h5>Cloud Computing</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>InternzLearn's mentorship and guidance have been a cornerstone in my career growth. The commitment to student success is unwavering, and I am truly grateful for the invaluable experience. Thank you.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Apurv</h4>
                                            <h5>Python</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="">
                                    <RatingStar />
                                    <p>Thank you, InternzLearn, for the transformative mentorship and guidance. Your commitment to making students industry-ready sets you apart. Grateful for the skills and knowledge gained under your mentorship.</p>
                                    <div className='profile'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                        <div>
                                            <h4>Yashpal</h4>
                                            <h5>Digital Marketing</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* </div> */}
                        </section>
                    </div>
                    <section className='eigth-section container' id="chat-with-us">
                        <div className="chat-box">
                            <div className="contact">
                                <h5>Chat to our friendly team</h5>
                                <p>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
                                <div className='flex'>
                                    <i className="fa-regular fa-envelope"></i>
                                    <div>
                                        <h6>Email</h6>
                                        <p>Our friendly team is here to help.</p>
                                        <h6>support@internzlearn.com</h6>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <i className="fa-solid fa-phone"></i>
                                    <div>
                                        <h6>Phone</h6>
                                        <h6>+91 9606904398</h6>
                                        <h6>+91 9606904399</h6>
                                        <h6>+91 9606904363</h6>
                                    </div>
                                </div>
                                <div className='social'>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/fb.svg`} alt="" />
                                    </div>
                                    <div>
                                        <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/insta.svg`} alt="" /></a>
                                    </div>
                                    {/* <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/twitter.svg`} alt="" />
                                    </div> */}
                                    <div>
                                        <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/linkedin.svg`} alt="" /></a>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/youtube.svg`} alt="" />
                                    </div>


                                </div>

                            </div>
                            <div className="connect">
                                <EnquiryForm />
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </>
        )
    }
}


export default Main;