// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './app';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import Main from './components/main';
import Layout from './app'

import AboutUs from './components/pages/about-us';
import Signup from './components/pages/sign-up';
import Course from './components/training-intern/course'
import PapMain from './components/pap/main'
import PapJavaCourse from './components/pap/pap-java-course'
import PapPythonCourse from './components/pap/pap-python-course'
import PapDevOpsCourse from './components/pap/pap-dev-ops-course'
import PapAutoTestCourse from './components/pap/pap-auto-test-course'

import Blogs from './components/blog/blogs';
import Blog from './components/blog/blog';

import Corporate from './components/pages/corporate'

import PrivacyPolicy from './components/pages/privacyPolicy';
import RefundPolicy from './components/pages/refundPolicy';
import TermsAndConditions from './components/pages/termsAndConditions';
import ThankYouPage from './components/pages/thank-you';
// Admin
import Dashboard from './components/admin/dashboard';
import JobApplication from './components/admin/job-application';
import Enquiries from './components/admin/enquiries';
import AdminBlogs from './components/admin/blogs';
import AddBlog from './components/admin/add-blog';

import AdminEvents from './components/admin/events';
import AddEvent from './components/admin/add-event';
import Category from './components/admin/category';
import Courses from './components/admin/courses';
import AddCourse from './components/admin/add-course';
import Subscribed from './components/admin/subscribed';
import EnrolledEvents from './components/admin/enrolled-events';
import Admins from './components/admin/admins'
import AddAdmin from './components/admin/add-admin'
import AddCoupon from './components/admin/add-coupon'
import Coupons from './components/admin/coupons';
import Users from './components/admin/users'
import Transactions from './components/admin/transactions'
import AdminLogin from './components/admin/login';
import EditBlog from './components/admin/edit-blog';
import EditEvent from './components/admin/edit-event';
import EditCourse from './components/admin/edit-course';
class Root extends React.Component {

    render() {
        return(
				<BrowserRouter basename={'/'} >
					<ScrollContext>
						<Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Main}/>
							{/* <Route path={`${process.env.PUBLIC_URL}/pets`} component={Landing}/> */}
                            <Layout>
                              	<Route path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs}/>
								  <Route path={`${process.env.PUBLIC_URL}/sign-up`} component={Signup}/>
								  <Route path={`${process.env.PUBLIC_URL}/corporate-representative`} component={Corporate}/>

								<Route path={`${process.env.PUBLIC_URL}/course/:course_id/:course_name`} component={Course}/>
								<Route path={`${process.env.PUBLIC_URL}/thankyou/:orderId`} component={ThankYouPage}/>
								{/* PAP  */}
								<Route path={`${process.env.PUBLIC_URL}/pap-main`} component={PapMain}/>
								<Route path={`${process.env.PUBLIC_URL}/pap-java-course`} component={PapJavaCourse}/>
								<Route path={`${process.env.PUBLIC_URL}/pap-python-course`} component={PapPythonCourse}/>
								<Route path={`${process.env.PUBLIC_URL}/pap-dev-ops-course`} component={PapDevOpsCourse}/>
								<Route path={`${process.env.PUBLIC_URL}/pap-automation-testing-course`} component={PapAutoTestCourse}/>

								{/* Blogs  */}
								<Route path={`${process.env.PUBLIC_URL}/blogs`} component={Blogs}/>
								<Route path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
								

								<Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>
								<Route path={`${process.env.PUBLIC_URL}/refund-policy`} component={RefundPolicy}/>
								<Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsAndConditions}/>

								{/* Admin */}
								{/* <Route path={`${process.env.PUBLIC_URL}/admin/`} component={AdminLogin}/> */}
								<Route path={`${process.env.PUBLIC_URL}/admin/`} component={Dashboard}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/job-application`} component={JobApplication}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/enquiries`} component={Enquiries}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/blogs`} component={AdminBlogs}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/add-blog`} component={AddBlog}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/edit-blog/:id`} component={EditBlog}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/events`} component={AdminEvents}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/add-event`} component={AddEvent}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/edit-event/:event_id`} component={EditEvent}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/category`} component={Category}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/courses`} component={Courses}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/add-course`} component={AddCourse}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/edit-course/:course_id`} component={EditCourse}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/subscribers`} component={Subscribed}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/enrolled-events`} component={EnrolledEvents}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/list`} component={Admins}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/users`} component={Users}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/transactions`} component={Transactions}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/add-admin`} component={AddAdmin}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/add-coupon`} component={AddCoupon}/>
								<Route path={`${process.env.PUBLIC_URL}/admin/coupons`} component={Coupons}/>
								<Route path={`${process.env.PUBLIC_URL}/admin-login`} component={AdminLogin}/>
								
                            </Layout>
                         </Switch>
					  </ScrollContext>
					</BrowserRouter>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));



