import React, { Component } from 'react';
import axios from 'axios';
import DynamicTable from './dynamic-table';
import Cookies from 'js-cookie';
class Users extends Component {
    constructor(props) {
        super(props)
        this.checkLogin();

        this.state = {
            users: [],
        };
        this.getUsers();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }

    getUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'enrolled-events', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data.status == true) {
            this.setState({
                users: response.data.results,
            });
        }
    };
    render() {
        const columns = [
            // { field: 'id', header: 'ID' },
            { field: 'title', header: 'Event Title' },
            { field: 'event_datetime', header: 'Date & Time' },
            { field: 'first_name', header: 'Name' },
            { field: 'email', header: "Email" },
            { field: 'phone_no', header: 'Contact No.' },
           
            // Add more columns as needed
        ];

        return (
            <>
            <div className='container'>
                <DynamicTable columns={columns} data={this.state.users} />
            </div>
                
            </>
        )
    }
}


export default Users;