import React, { useState } from 'react';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [subscribed, setSubscribed] =useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (!validateEmail(email)) {
      setErrorMessage('Invalid email address');
      return;
    }

    // Assuming you have an API endpoint to handle email subscription
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`+'subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccessMessage('Email subscribed successfully!');
        setSubscribed(true)
      } else {
        setErrorMessage('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="newsletter-area">
      <div className="first">
        <h6>Join our newsletter</h6>
        <p>We’ll send you a nice letter once per week. No spam.</p>
      </div>
      <div className="second">
        <div>
        <input
          type="text"
          className='input-box'
          placeholder='Type in your email'
          value={email}
          onChange={handleEmailChange}
        />
        <button onClick={handleSubmit} >{subscribed?"Subscribed":"Subscribe"}</button>
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      </div>
      
    </div>
  );
};

export default NewsletterForm;
