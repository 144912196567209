import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import SimpleReactValidator from 'simple-react-validator';
import 'primeicons/primeicons.css';
import Cookies from 'js-cookie';
class Categories extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.validator = new SimpleReactValidator();
        this.state = {
            categories: [],
            formData: {
                category: '',
                active: 1,
            },
            showCategoryBox: false,
        };
        this.getCategories();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    getCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'categories', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                categories: response.data.categories,
            });
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    setActiveChecked = (id, checked) => {
        let check = checked ? 1 : 0;
        const activeData = {
            "id": id,
            "active": check,
        }
        fetch(`${process.env.REACT_APP_API_URL}`+'category/active', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(activeData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getCategories();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };
    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'add-category', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.state.formData),
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.status) {
                        this.setState({
                            showCategoryBox: false,
                        })
                        this.getCategories();
                    } else {
                        console.error('Failed to add category');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding category:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    checkBoxActiveBodyTemplate = (rowData) => {
        return (
            <Checkbox
                onChange={(e) => this.setActiveChecked(rowData.id, e.checked)}
                checked={rowData.active === 1}
            />
        );
    };

    deleteCategory = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}`+'category/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getCategories();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    showCategoryBox =()=>{
        this.setState({
            showCategoryBox: true,
        })
    }

    deleteBodyTemplate = (rowData) => {
        return (
            <Button icon="pi pi-times" rounded text raised severity="danger" onClick={() => this.deleteCategory(rowData.id)} style={{ borderRadius: "50%" }} aria-label="Cancel" />
        )
    }
    render() {
        const { formData, showCategoryBox } = this.state;
        const columns = [
            { field: 'id', header: 'ID' },
            { field: 'category', header: 'Category' },
            { field: 'active', header: 'Active' },
            { field: 'action', header: 'Action' },
        ];

        return (
            <>
                <div className='container'>
                    <Button className="rounded p-button font-bold mt-2 mb-2" style={{ textDecoration: "none", color: "white" }} onClick={()=>this.showCategoryBox()}>
                        Add Category
                    </Button>
                    <div className={`category-box mb-5 ${showCategoryBox?'show' : 'hide'}`} style={{ display: "flex", flexDirection: "row" }}>
                        <div className='w-100' style={{ display: "flex", flexDirection: "row" }}>
                            <input type="text" name="category" value={formData.category} className='form-control' onChange={this.handleInputChange} />
                            {this.validator.message('category', formData.category, 'required|category')}
                            <Button className="rounded p-button font-bold" label="submit" style={{ textDecoration: "none", color: "white" }} onClick={this.handleSubmit}></Button>
                        </div>

                    </div>
                    
                    <DataTable
                        value={this.state.categories}
                        stripedRows
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column field={columns[0].field} header={columns[0].header} />
                        <Column field={columns[1].field} header={columns[1].header} />
                        <Column
                            field={columns[2].field}
                            header={columns[2].header}
                            body={this.checkBoxActiveBodyTemplate}
                        />
                        <Column field={columns[3].field} header={columns[3].header} body={this.deleteBodyTemplate} />
                    </DataTable>
                </div>
            </>
        );
    }
}

export default Categories;
