import React, { Component, useState } from 'react';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';
import FAQ from '../common/layout/pap-faq';
import Cookies from 'js-cookie';
import BannerLogo from '../common/layout/banner-logo';
import CouponsModal from '../common/layout/coupons';
import './../../styles/pap-course.css';

class JavaCourse extends Component {
    constructor(props) {
        super(props)
        this.state = {
           viewMorePathways: false,
           course_id: '',
           type: '',
           coupon_modal_show: false,
           amount: 0,
        };
    }
    viewMorePathways = () =>{
        this.setState({
            viewMorePathways: true,
        });
    }
    handleButtonClick = () => {
        // Check if the user is logged in
        if (Cookies.get('token') === undefined) {
            this.props.history.push('/sign-up')
        } else {
            this.downloadPDF();
        }
      };
      downloadPDF = () => {
        // Replace 'your-pdf-file.pdf' with the actual path to your PDF file
        const pdfPath = process.env.PUBLIC_URL + '/assets/document/pap/curriculum/devops-pap.pdf';
      
        // Create a temporary anchor element
        const link = document.createElement('a');
      
        // Set the download attribute and href of the anchor element
        link.download = 'devops-pap.pdf';
        link.href = pdfPath;
      
        // Trigger a click on the anchor element to start the download
        document.body.appendChild(link);
        link.click();
      
        // Remove the anchor element from the document
        document.body.removeChild(link);
      };
      scrollToPricing = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pricing');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    openCouponModal = (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        this.setState({
          amount: amount,
          course_id: course_id,
          type: type,
        }, () => {
          this.setState({
            coupon_modal_show: true,
          });
        });
      };

    closeCouponModal = () => {
        this.setState({
            coupon_modal_show: false,
        })
    };

    startPayment =(amount) =>{
        this.initiatePayment(amount, this.state.course_id, this.state.type)
    }
     

    initiatePayment = async (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        // const { enqData } = this.state;
        // const amountString = document.getElementById(type).innerText
        const amountFloat = parseFloat(amount).toFixed(2);
        // const amountAsNumber = parseFloat(amountFloat);
        const paymentData = {
            user_id: user_id,
            amount: amountFloat,
            course_id: course_id,
            type: type,
        }
        try {
            fetch(`${process.env.REACT_APP_API_URL}` + 'initiate-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            })
                .then(response => response.json())
                .then(data => {
                    // if (data.status) {
                        console.log(data)
                    // }
                    const form = document.createElement('form');
                    form.method = 'post';
                    form.name= 'redirect';
                    form.id = 'nonseamless';
                    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
                    const encRequestInput = document.createElement('input');
                    encRequestInput.type = 'hidden';
                    encRequestInput.name = 'encRequest';
                    encRequestInput.id = 'encRequest';
                    encRequestInput.value = data.data;
                    const accessCodeInput = document.createElement('input');
                    accessCodeInput.type = 'hidden';
                    accessCodeInput.name = 'access_code';
                    accessCodeInput.id = 'access_code';
                    accessCodeInput.value = 'AVDK13KI30BD04KDDB'
                    form.appendChild(encRequestInput);
                    form.appendChild(accessCodeInput)
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }
    render() {
        const { coupon_modal_show, amount } = this.state
        const style={
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/devops/first.svg)`,
  backgroundSize: 'cover',  // or 'contain' depending on your preference
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
        }
        return (
            <div style={{ backgroundColor: "#000" }}>
                <HeaderBlack />
                <CouponsModal isOpen={coupon_modal_show} onPay={this.startPayment} amount={amount} onClose={this.closeCouponModal} />
                <section className='pap-first-section' style={style}>
                    <div className="container">
                        <div className="first-container">
                            <div className='left-container'>
                                <h6>Placement Assurance Program </h6>
                                <h2>DevOps</h2>
                                <p>Embark on an exciting journey through the vast landscape of DevOps, unlocking the wizardry of orchestrating seamless development and operations. Discover the art of automating processes, enhancing collaboration, and ensuring the reliability of software. From mastering the tools that streamline workflows to delving into practices that optimize deployment and monitoring, join us in navigating the intricate web of code and innovation in the world of DevOps.</p>
                                <div className='details-box'>
                                    <div className='details-box-div'>
                                        <h5>Batch <img style={{marginLeft:"2rem"}} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/batch.svg`} alt="" /></h5>
                                        <h4>Monthly</h4>
                                    </div>
                                    <div className='details-box-div'>
                                        <h5>Available Seats <img style={{marginLeft:"2rem"}} src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/seat.svg`} alt="" /></h5>
                                        <h4>8/60</h4>
                                    </div>
                                </div>

                            </div>
                            <div className='right-container'>
                                <div>
                                    <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/devops/first-video-img.svg`} alt="" />
                                    </div>
                                    <button onClick={()=>this.scrollToPricing()}>Apply Now </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='second-section'>
                        <div className="company-banner">
                            <p>Hiring Partner's -</p>
                            <div>
                            <BannerLogo />
                            </div>
                        </div>
                </section> 
                <section className='pap-second-section' style={{ backgroundColor: "#000" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>KEY FEATURES</h2>
                            <div className="features-box">
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/first.svg`} alt="" />
                                        <h6>Job Placement Guarantee</h6>
                                        <p>Participants who successfully complete the program are guaranteed assistance in finding a suitable job within a specific time frame.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Customized Training</h6>
                                        <p>The training curriculum is designed to enhance participants' skills and make them job-ready.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/language.svg`} alt="" />
                                        <h6>Skill Development</h6>
                                        <p>This includes technical skills, soft skills, communication skills, and other abilities necessary for a successful career.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/graph.svg`} alt="" />
                                        <h6>Placement Cell</h6>
                                        <p>The placement cells or teams responsible for liaising with companies, arranging interviews, and facilitating job placements for participants.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/teacher.svg`} alt="" />
                                        <h6>Mentorship</h6>
                                        <p>Mentors will be of 10 to 15 years experienced in indutry. Participants will be having 2 mentors dor the doubts clearing sessiosn.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/clock.svg`} alt="" />
                                        <h6>Resume Building and Interview Preparation</h6>
                                        <p>Guidance on creating effective resumes and conducting successful job interviews. This includes mock interview sessions and feedback to help participants present themselves well to potential employers.</p>
                                    </div>
                                    <div className='content-area'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certificate.svg`} alt="" />
                                        <h6>Internship Opportunities</h6>
                                        <p>Hands-on experience through internships can enhance participants practical skills and increase their chances of getting hired.</p>
                                    </div>
                                </div>
                                <div className='images'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/highlight-main.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="career-pathways" className='pap-third-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container">
                        <div className="first-container">
                            <h2>Career Pathways</h2>
                            <div className="content-area">
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Master the art of streamlining development processes and fostering collaboration between development and operations for efficient software delivery.</p>
                                        <h6>DevOps Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Focus on automating and optimizing the CI/CD pipeline, ensuring seamless integration, testing, and deployment of software changes.</p>
                                        <h6>Continuous Integration/Continuous Deployment (CI/CD) Engineer</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Ensure the reliability and performance of systems by implementing effective monitoring, incident response, and reliability best practices.</p>
                                        <h6>Site Reliability Engineer (SRE)</h6>
                                    </div>
                                </div>
                                <div className='content-box'>
                                    <div className='content-card'>
                                        <p>Specialize in automated testing methodologies, ensuring software reliability and performance through comprehensive testing frameworks.</p>
                                        <h6>Automation Testing Specialist</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Architect and implement cloud-based solutions, leveraging platforms like AWS, Azure, or Google Cloud for scalable and reliable infrastructure.</p>
                                        <h6>Cloud Solutions Architect</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Specialize in integrating security measures into the DevOps pipeline, ensuring secure software development and deployment practices.</p>
                                        <h6>Security DevOps Engineer</h6>
                                    </div>
                                </div>
                                {this.state.viewMorePathways?<div className='content-box'>
                                    <div className='content-card'>
                                        <p>Focus on containerization technologies like Docker and orchestration tools like Kubernetes, ensuring efficient deployment and scaling of applications.</p>
                                        <h6>Containerization and Orchestration Specialist</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Offer expertise as a DevOps consultant, guiding organizations in adopting and optimizing DevOps practices for enhanced efficiency and collaboration.</p>
                                        <h6>DevOps Consultant</h6>
                                    </div>
                                    <div className='content-card'>
                                        <p>Specialize in writing and managing Infrastructure as Code, automating the provisioning and management of infrastructure components for agility and consistency.</p>
                                        <h6>Infrastructure as Code (IaC) Engineer</h6>
                                    </div>
                                </div>: <></>}
                                {!this.state.viewMorePathways?<button className='view-more' onClick={() => this.viewMorePathways()}>view more pathways</button>:<></>}
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section className='pap-fourth-section'>
                    <div className="container">

                        <div className='first-container'>
                            <h2>Learning Journey</h2>
                            <img className='images' src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/learning.png`} alt="" />
                            <div className="content-area">

                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 1</p>
                                            <h6>Begin your journey with DevOps</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Begin your journey with DevOps</h6>
                                            <p>Embark on your DevOps exploration, immersing yourself in the transformative world of efficient software delivery and collaboration, laying the foundation for versatile expertise.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 2</p>
                                            <h6>Data Structures and Algorithms</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Data Structures and Algorithms</h6>
                                            <p>Explore the fundamental elements of efficient coding and problem-solving within the DevOps landscape, focusing on data structures and algorithms crucial for mastering the art of programming.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 3</p>
                                            <h6>Database</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Database</h6>
                                            <p>Dive into the intricacies of data structures and algorithms. Understanding these concepts is essential for efficient coding and problem-solving.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 6</p>
                                            <h6>Build and Present Capstone Projects</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Build and Present Capstone Projects</h6>
                                            <p>Apply your skills to real-world scenarios within the DevOps domain by constructing and presenting impactful capstone projects. Showcase your technical prowess and creativity in comprehensive development ventures.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 5</p>
                                            <h6>Back-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Back-end Development</h6>
                                            <p>Explore the server-side of DevOps, mastering databases, server architecture, and application logic. Ensure the smooth integration of back-end systems for effective software deployment.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                    <div className='content-box'>
                                            <p>Module 4</p>
                                            <h6>Front-end Development</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Front-end Development</h6>
                                            <p> Immerse yourself in the captivating realm of DevOps, where collaboration meets functionality. Learn to create seamless and user-friendly interfaces for efficient software delivery.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 7</p>
                                            <h6>Coding & Hackathons</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Coding & Hackathons</h6>
                                            <p>Sharpen your coding skills and problem-solving acumen through dynamic coding sessions and hackathons within the context of DevOps. Experience the excitement of real-time challenges.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 8</p>
                                            <h6>Preparing for Placement Opportunities</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Preparing for Placement Opportunities</h6>
                                            <p>Equip yourself with the essential skills and knowledge to excel in placement opportunities within the DevOps domain. Prepare for interviews, assessments, and professional success in this dynamic field.</p>
                                        </div>
                                    </div>
                                    <div className="max-content-box">
                                        <div className='content-box'>
                                            <p>Module 9</p>
                                            <h6>Participation in Placement Drives</h6>
                                        </div>
                                        <div className="second-content-box">
                                            <h6>Participation in Placement Drives</h6>
                                            <p>Engage in placement drives within the DevOps industry to connect with potential employers. Highlight your skills, explore job opportunities, and take the first stride towards a thriving career in DevOps.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={()=>this.handleButtonClick()}>Download Curriculum</button>
                        </div>

                    </div>
                </section >
                <section className='pap-fifth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Capstone Projects</h2>
                                <p>Utilising the knowledge you gained from the training, create a application from scratch on your own. Learn how to effectively plan and carry out projects. Get helpful input by presenting your project to a group of industry evaluators. Add the project to your GitHub project portfolio.</p>

                                <h2>What Will You Achieve?</h2>
                                <p>Through our Full Stack Development program, you'll attain the knowledge and practical experience to accomplish the following.</p>

                                <h2>Develop an End-to-End Application:</h2>
                                <p>You'll have the ability to design, build, and deploy a comprehensive application that encompasses both the front-end and back-end components. This hands-on experience ensures you're well-versed in creating user-centric, functional web applications.</p>

                                <h2>Gain Confidence in Your Skills by Presenting the Project to Industry Experts:</h2>
                                <p>As you complete your project, you'll have the opportunity to present it to industry experts, receiving valuable feedback and insights. This exposure boosts your confidence and helps you refine your skills to meet industry standards and expectations.</p>
                                <p>With our Full Stack Development program, you not only learn the ropes of development but also gain the confidence to showcase your abilities to professionals in the field. It's a stepping stone towards a successful and rewarding career in the world of technology.</p>
                            </div>
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/books.svg`} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-sixth-section'>
                    <h2>PROJECTS</h2>
                    <div className="container">
                        <div className="grid-container">
                            <div className="item1 item-box">
                                <h2>Continuous Monitoring with Nagios:</h2>
                                <p>Set up continuous monitoring using Nagios to track the performance and health of systems, enabling proactive issue identification and resolution.</p>
                            </div>
                            <div className="item2 item-box">
                                <h2>Kubernetes Orchestration:</h2>
                                <p>Orchestrate containerized applications using Kubernetes, ensuring scalability, resilience, and efficient resource management.</p>
                            </div>
                            <div className="item3 item-box">
                                <h2>Deploying Applications with Vercel:</h2>
                                <p>Deploy applications seamlessly with Vercel, leveraging its platform for efficient hosting, scaling, and continuous deployment.</p>
                            </div>
                            <div className="item4 item-box">
                                <h2>API Testing with Postman:</h2>
                                <p>Create an end-to-end e-commerce website, where users can buy and sell products, manage inventory, and track sales.</p>
                            </div>
                            <div className="item5 ">
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/startup.svg`} alt="" />
                            </div>
                            <div className="item6 item-box">
                                <h2>Dockerizing Applications:</h2>
                                <p>Containerize applications using Docker, streamlining deployment and ensuring consistency across different environments.</p>
                            </div>
                            <div className="item7 item-box">
                                <h2>Selenium Automation Testing:</h2>
                                <p> Implement automated testing for web applications using Selenium, enhancing the efficiency and reliability of the testing process.</p>
                            </div>
                            <div className="item8 item-box">
                                <h2>Configuration Management with Ansible:</h2>
                                <p>Implement configuration management with Ansible to automate and standardize the setup and maintenance of infrastructure.</p>
                            </div>
                            <div className="item9 item-box">
                                <h2>Setting Up CI/CD Pipeline with Jenkins:</h2>
                                <p>Configure a robust Continuous Integration and Continuous Deployment (CI/CD) pipeline using Jenkins for efficient and automated software delivery.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pap-eight-section'>
                    <h2>WHY REMOTE PROGRAM</h2>
                    <div className="container">
                        <div className="first-container">
                            <div className='images'><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/w-r-p-image.svg`} alt="" /></div>
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-2.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Flexibility:</h2>
                                <p>This flexibility is particularly beneficial for individuals who have busy schedules, live in different time zones, or prefer to learn at their own pace.</p>

                                <h2>Cost-Effectiveness:</h2>
                                <p>Both learners and training providers can save money with remote training.</p>

                                <h2>Global Reach:</h2>
                                <p>Individuals from different parts of the world can participate in the same training program, leading to diverse perspectives and experiences being shared.</p>

                                <h2>basic Learning:</h2>
                                <p>Learners can progress through the content at their own speed, allowing them to grasp concepts thoroughly before moving on.</p>
                            </div> */}
                            <div><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/why-remote-program-1.svg`} alt="" /></div>
                            {/* <div className='details-box'>
                                <h2>Personalized Learning:</h2>
                                <p>This personalized approach ensures that learners receive the most relevant and effective training.</p>

                                <h2>Continuous Learning:</h2>
                                <p>Remote training enables participants to access training materials after the live sessions have ended.</p>

                                <h2>Lifelong Learning: </h2>
                                <p>Remote training encourages a culture of lifelong learning, as individuals can easily enroll in courses, webinars, and workshops to stay up-to-date with the latest industry trends.</p>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className='pap-ninth-section'>
                    <div className="container">
                        <div className="first-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/devops/tools-used/tools.svg`} alt='' />
                        </div>
                    </div>
                </section>
                <section className='pap-tenth-section'>
                    <div className="container">
                        <div className="first-container">
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/certifications.svg`} alt="" />
                        </div>

                    </div>
                </section>
                <section id="pricing" className='pap-eleventh-section' style={{ backgroundColor: "#151515" }}>
                    <div className="container pricing">
                        <h2>Our Expert-Led Courses</h2>
                        <div className='pricing-box'>
                            <div className="left-box">
                                <div className='basic'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/pricing-logo1.svg`} alt="" />
                                    <h4>Basic</h4>
                                    <p>Our basic Placement Assurance Program focuses on thorough training and comprehensive support, ensuring you're well-prepared for a successful career launch.</p>
                                    <div className="row-container">
                                        <div className='basic-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-userfocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/sp-calendar.svg`} alt="" />
                                                <p>5 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='basic-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/basic/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>
                                        </div>

                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                    <h5>₹59999/-</h5>
                                        <button onClick={()=>this.openCouponModal(59999, 'pap-dev-ops', 'basic')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="right-box">
                                <div className='mentor-led'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/pricing-logo2.svg`} alt="" />
                                    <h4>Premium</h4>
                                    <p>Experience live classes, expert mentor guidance, and enhanced placement opportunities with our mentor-led program, ensuring personalized and interactive learning</p>
                                    <div className="row-container">
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/NumberSquareOne.svg`} alt="" />
                                                <p>1:1 Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/UserFocus.svg`} alt="" />
                                                <p>Live + on Demand Videos</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Calendar.svg`} alt="" />
                                                <p>9 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Vector.svg`} alt="" />
                                                <p>Thorough and In-Depth Training</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Kanban.svg`} alt="" />
                                                <p>Real Time Project</p>
                                            </div>
                                        </div>
                                        <div className='mentor-led-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ClockCounterClockwise.svg`} alt="" />
                                                <p>Interview Help and Job Placement Support</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/CassetteTape.svg`} alt="" />
                                                <p>Unlimited Lifetime Class Access</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Money.svg`} alt="" />
                                                <p>Up to 12 LPA Salary Package</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/Question.svg`} alt="" />
                                                <p>Personal Q&A Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/pap-course/pricing/premium/ChalkboardTeacher.svg`} alt="" />
                                                <p>100% Placement Assurance</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='bottom-box'>
                                    <h5>₹79999/-</h5>
                                        <button onClick={()=>this.openCouponModal(79999, 'pap-dev-ops', 'premium')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className='pap-twelfth-section'>
                    <div className="container">
                        <div className="first-container">
                            <div>
                                <h2>Meet the Face behind InternzLearn</h2>
                                <p>Our mentors are full-time traders and economics graduates who have learnt to manage all their finances themselves. They not only understand the human psychology necessary to build a plethora of wealth but also know the financial markets inside out to provide you with tailored support & expert advice.</p>
                                <button>Get in Touch</button>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Saksham Ahuja<span></span></h6>
                            </div>
                            <div>
                                <img src="" alt="" />
                                <h6>Richit Bid<span></span></h6>
                            </div>
                        </div>
                    </div>
                </section> */}
                <FAQ />
                <Footer />

            </div >
        )
    };
};
export default JavaCourse;