import React, { useState } from 'react';
import './../../../styles/common.css'
const FAQ = ({ color }) => {
    return (
        <>
            <section className='bg-color-f0f0f0'>
                <div className='container faq-parent-div'>
                    <h1>Frequently asked questions</h1>
                    <h4>Questions you might ask about our Products and Services</h4>
                    <div className='faq-div'>
                        <div className="col-md-4 faq-image-div" >
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/common/faq.svg`} alt="" />
                        </div>
                        <div className="col-md-8">
                            <div id="accordion">
                                <div className="card" id="card-q-a">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is the difference between Basic and premium programs ?	
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body">
                                        The Basic program spans 1-3 months of training, followed by a 4-5 month internship period. On the other hand, the Premium program encompasses the same training and internship duration as the Basic program, with the additional feature of a 6th month dedicated to placement training. Furthermore, the Premium program extends from the 7th to the 9th month, focusing on providing placement opportunity for participants.
                                        </div>
                                    </div>
                                </div>
                                <div className="card" id="card-q-a">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Who will Train us ? 
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-body">
                                        Industrial experts from top tech companies will be your mentors.	
                                        </div>
                                    </div>
                                </div>
                                <div className="card" id="card-q-a">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How to Convert my Fee into EMI ?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-body">
                                        Internz Learn has established collaborations with a diverse array of EMI partners. We will manage the coordination with these partners to facilitate and provide you with suitable EMI options.
                                        </div>
                                    </div>
                                </div>
                                <div className="card" id="card-q-a">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            What if i Miss any Live Sessions ?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-body">
                                        You will be having an LMS account where we will be assigning recorded sessions of each and every live sessions and You will be having lifetime access for LMS account.	
                                        </div>
                                    </div>
                                </div>
                                <div className="card" id="card-q-a">
                                    <div className="card-header" id="headingFive">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What is the Average Package & What is the Placement Ratio ?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div className="card-body">
                                        Our alumni boast an average placement package of 12.4 LPA, and our placement ratio stands impressively at 91.9%.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6>Still have Questions?</h6>
                            <p>Can't find the answer you’re looking for? Please contact with our customer service. </p>
                            <a href="/#chat-with-us" ><button className='contact-us'>Contact Us</button></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FAQ;

