import React, { useState } from 'react';
import Slider from "react-slick";
import './../../../styles/common.css';
const Grow = ({ color }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
              }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
              }
            ]
    };
    return (
        
        <>
            <section style={{ backgroundColor: "black" }}>
                <div className="container grow">
                    <h3>Grow with internz learn</h3>
                    <h4>Giving you an integrated learning experience is our top priority. We continue to assist you as you begin your career with a fresh outlook with our one-of-a-kind career support services. Gain access to more than 100+ placement partners and explore countless possibilities. </h4>
                    <div className='flex-column grow-content'>
                        <div className='first-row flex-row'>
                            <div className='first-box first-column flex-column'>
                                <div className='box-1 border-design'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/GlobeHemisphereEast.svg`} alt="" />
                                    <h6>Career Mentoring:</h6>
                                    <p>Our career mentoring program pairs you with experienced industry professionals who offer invaluable guidance for your career journey. From resume refinement to interview preparation, we're here to help you succeed in the world of tech. Let us support your growth and empower your potential</p>
                                </div>
                                <div className='box-2 border-design'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/GlobeHemisphereEast.svg`} alt="" />
                                    <h6>Resume Building:</h6>
                                    <p>Crafting an impressive resume is vital for job seekers. Our expert guidance ensures that your resume effectively showcases your talents and skills, giving you a competitive edge in the job market. With our support, you'll have a compelling resume that makes a strong impression on potential employers.</p>
                                </div>
                            </div>
                            <div className='second-column border-design'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/GlobeHemisphereEast.svg`} alt="" />
                                <h6>Profile Enhancement:</h6>
                                <p>Boosting your professional profile is essential in today's competitive job market. We provide the tools and guidance to enhance your professional presence, helping you stand out to potential employers. With our assistance, you'll develop a compelling profile that highlights your expertise and skills, improving your career prospects.</p>
                            </div>
                            <div className='third-column border-design'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/GlobeHemisphereEast.svg`} alt="" />
                                <h6>Interview Preparation:</h6>
                                <p>Preparing for job interviews can be a challenging process, but we've got you covered. Our comprehensive interview preparation program equips you with the skills and confidence needed to excel in interviews. Through mock interviews, feedback, and guidance, you'll gain valuable insights and strategies to showcase your abilities effectively during real interviews, increasing your chances of landing your dream job.</p>
                            </div>
                        </div>
                        <div className='second-row border-design'>
                        <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/GlobeHemisphereEast.svg`} alt="" />
                            <h6>Placement Guarantee:</h6>
                            <p>We stand by our commitment to your success. Our placement guarantee ensures that you receive the support and resources needed to secure a suitable job opportunity within a specific timeframe. If you meet the program's requirements and actively participate, our placement cell will work diligently to connect you with potential employers, increasing your chances of a successful career transition. Your success is our priority.</p>
                        </div>
                    </div>
                    <div className='grow-bottom-box'>
                        <div className='col-md-4'>
                            <h4>A program that turns novices into Full Stack Developers</h4>
                            <h5>We provide various special features<br /> for all of you</h5>
                        </div>
                        <div className='grow-bottom-div col-md-8'>
                            <div>
                        <Slider {...settings}>
                            <div className='grow-bottom-card-white'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/4.svg`} className='m-2' alt="" />
                                <h6>No coding background needed</h6>
                                <p>Get started on your journey as a Full Stack Developer even if you have no prior coding experience</p>
                            </div>
                            <div className='grow-bottom-card-green'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/2.svg`} className='m-2' alt="" />
                                <h6>Engage in Hackathons & Assignments</h6>
                                <p>Sharpen your skills through challenging coding competitions and hands-on assignments</p>
                            </div>
                            <div className='grow-bottom-card-white'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/4.svg`} className='m-2' alt="" />
                                <h6>Work on Real-Time Projects</h6>
                                <p>Apply your knowledge to practical, real-world projects to gain valuable experience.</p>
                            </div>
                            <div className='grow-bottom-card-green'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/2.svg`} className='m-2' alt="" />
                                <h6>Receive guidance for Resume Building & Interview Preparation</h6>
                                <p>Professional help for standout resumes and interview success.</p>
                            </div>
                            <div className='grow-bottom-card-white'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/4.svg`} alt="" />
                                <h6>Benefit from Easy EMI Options</h6>
                                <p>Make your education accessible with convenient and flexible payment options.</p>
                            </div>
                            <div className='grow-bottom-card-green'>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/grow/2.svg`} alt="" />
                                <h6>Enhance your career with Networking Opportunities</h6>
                                <p>Connect with industry professionals and build a strong network for future opportunities.</p>
                            </div>
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Grow;