import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderWhite from '../common/headers/header-white';
import Footer from '../common/footer/footer';
import './../../styles/blogs.css'
import EnquiryForm from '../common/layout/enquiry-form';
class Blog extends Component {
    constructor(props) {
        super(props)
    }
    convertIntoDate = (date) => {
        const timestamp = date;
        const dateObject = new Date(timestamp);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day = String(dateObject.getDate()).padStart(2, '0');

        // Construct the formatted date string with a hyphen separator
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    render() {
        const { blog } = this.props.location.state;
        if(blog == undefined){
            this.props.history.push("/blogs ")
        }
        const blogContent = JSON.parse(blog.content);
        return (
            <div>
                <HeaderWhite />
                <section className='blog-page-section'>
                    <div className="container">
                        <div className="blog">
                            <h6>{blog.tag}</h6>
                            <h2>{blog.title}</h2>
                            <img src={blog.image.startsWith("/assets") ? blog.image : `https://internz-uploads.s3.ap-south-1.amazonaws.com/blogs/${blog.image}`} alt={blog.title} />
                            <div className='flex-row'>
                                <img src={ blog.author_image.startsWith("/assets") ? blog.author_image : `https://internz-uploads.s3.ap-south-1.amazonaws.com/blogs/${blog.author_image}`} alt={blog.title} />
                                <h4>{blog.author_name} &nbsp; </h4>
                                <h5>| &nbsp;{this.convertIntoDate(blog.date)}</h5>
                            </div>
                            <p>{blog.description}</p>
                            {blogContent.map(content => (
                                <>
                                    <h4>{content.heading}</h4>
                                    <p>{content.description}</p>
                                </>
                            ))}
                        </div>
                        <div className="right-container">

                        </div>
                    </div>
                </section>


                <section className='eigth-section container'>
                    <div className="chat-box">
                        <div className="contact">
                            <h5>Chat to our friendly team</h5>
                            <p>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
                            <div className='flex'>
                                <i className="fa-regular fa-envelope"></i>
                                <div>
                                    <h6>Email</h6>
                                    <p>Our friendly team is here to help.</p>
                                    <h6>support@internzlearn.com</h6>
                                </div>
                            </div>
                            <div className='flex'>
                                <i className="fa-solid fa-phone"></i>
                                <div>
                                    <h6>Phone</h6>
                                    <h6>+91 9606904398</h6>
                                    <h6>+91 9606904399</h6>
                                    <h6>+91 9606904363</h6>
                                </div>
                            </div>
                            <div className='social'>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/fb.svg`} alt="" />
                                </div>
                                <div>
                                    <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/insta.svg`} alt="" /></a>
                                </div>
                                {/* <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/twitter.svg`} alt="" />
                                    </div> */}
                                <div>
                                    <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/linkedin.svg`} alt="" /></a>
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/youtube.svg`} alt="" />
                                </div>


                            </div>

                        </div>
                        <div className="connect">
                            <EnquiryForm />
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
export default Blog;
