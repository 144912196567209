import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './styles/admin.css';
import Cookies from 'js-cookie';
import axios from 'axios';

class CourseForm extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.validator = new SimpleReactValidator();

        this.state = {
            categories: [],
            formData: {
                course_name: '',
                duration: 0,
                mentor_name: '',
                mentor_image: '',
                profession: "",
                students: 0,
                image: '',
                background_image: '',
                category: '',
                category_id: '',
                description: '',
                next_batch: '',
                total_seat: 0,
                available_seat: 0,
                project: "",
                about_this_course: [''],
                apply_your_knowledge: "",
                who_is_the_course_for: "",
                video: "",
                course_curriculum: [{ head: '', desc: '' }],
                course_curriculum_image: '',
                tools_used: '',
                active: 1,
                self_paced_amount: 0,
                mentor_led_amount: 0,
            },
        };
        this.getCategories();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }
    getCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'categories', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                categories: response.data.categories,
            });
        }
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleContentChange = (index, field, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                course_curriculum: prevState.formData.course_curriculum.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        }));
    };
    addContent = () => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                course_curriculum: [...prevState.formData.course_curriculum, { head: '', desc: '' }],
            },
        }));
    };

    removeContent = (index) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                course_curriculum: prevState.formData.course_curriculum.filter((item, i) => i !== index),
            },
        }));
    };

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: files[0] },
        }));
    };
    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleSelectChange =(e) =>{
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleSelectCategoryChange = (e) => {
        const { name, value } = e.target;
        const category = this.state.categories.find((cat) => cat.category_id === value);
    
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                category_id: value,
                category: category ? category.category : '', // Use the name property if category is found
            },
        }));
    };

    handleArrayInputChange = (index, value) => {
        const aboutCourse = [...this.state.formData.about_this_course];
        aboutCourse[index] = value;

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                about_this_course: aboutCourse,
            },
        }));
    };
    addArrayInput = () => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                about_this_course: [...prevState.formData.about_this_course, ''], // Add a new empty input
            },
        }));
    };
    removeArrayInput = (index) => {
        const aboutCourse = [...this.state.formData.about_this_course];
        aboutCourse.splice(index, 1);

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                about_this_course: aboutCourse,
            },
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            const formData = new FormData();

            // Append each field to FormData
            Object.entries(this.state.formData).forEach(([key, value]) => {
                if (key === 'course_curriculum') {
                    formData.append(key, JSON.stringify(value));
                } else if(key === 'about_this_course'){
                    formData.append(key, JSON.stringify(value));
                }else {
                    formData.append(key, value);
                }
            });

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}`+'add-course', {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const result = await response.json();
                    if (result.status) {
                        this.props.history.push("/admin/courses")
                    } else {
                        console.error('Failed to add blog');
                    }
                } else {
                    console.error('Failed to communicate with the server');
                }
            } catch (error) {
                console.error('Error adding blog:', error);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    render() {
        const { categories,formData } = this.state;

        return (
            <>
                <div className='add-blog container'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Course Name:</label>
                                <input className='form-control' type="text" name="course_name" value={formData.course_name} onChange={this.handleInputChange} />
                                {this.validator.message('course_name', formData.course_name, 'required|course_name',{ className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Duration:</label>
                                <input className='form-control' type="text" name="duration" value={formData.duration} onChange={this.handleInputChange} />
                                {this.validator.message('duration', formData.duration, 'required|duration',{ className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Image:</label>
                                <input className='form-control' type="file" name="image" onChange={this.handleFileChange} />
                                {this.validator.message('image', formData.image, 'required|image',{ className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Background Image:</label>
                                <input className='form-control' type="file" name="background_image" onChange={this.handleFileChange} />
                                {this.validator.message('background_image', formData.background_image, 'required|background_image',{ className: 'text-danger' })}
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label className='form-label'>Mentor Name:</label>
                                <input className='form-control' type="text" name="mentor_name" value={formData.mentor_name} onChange={this.handleInputChange} />
                                {this.validator.message('mentor_name', formData.mentor_name, 'required|mentor_name',{ className: 'text-danger' })}
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Mentor Profession:</label>
                                <input className='form-control' type="text" name="profession" value={formData.profession} onChange={this.handleInputChange} />
                                {this.validator.message('profession', formData.profession, 'required|profession',{ className: 'text-danger' })}
                            </div>

                            <div className="form-group">
                                <label className='form-label'>Mentor Image:</label>
                                <input className='form-control' type="file" name="mentor_image" onChange={this.handleFileChange} />
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Students:</label>
                                <input className='form-control' type="number" name="students" value={formData.students} onChange={this.handleInputChange} />
                                {this.validator.message('students', formData.students, 'required|students',{ className: 'text-danger' })}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Category</label>
                            <select class="form-select" name="category" id="" onChange={this.handleSelectCategoryChange}>
                                <option value="">Select Category</option>
                                {categories.map((category,index)=>(
                                    <option key={index} value={category.category_id}>{category.category}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Description:</label>
                            <textarea className='form-control' name="description" value={formData.description} onChange={this.handleInputChange} rows="4" />
                            {this.validator.message('description', formData.description, 'required|description',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Next Batch</label>
                            <select class="form-select" name="next_batch" onChange={this.handleSelectChange} id="">
                                <option value="">Select Batch</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Total Seat:</label>
                            <input className='form-control' type="number" name="total_seat" value={formData.total_seat} onChange={this.handleInputChange} />
                            {this.validator.message('total_seat', formData.total_seat, 'required|total_seat',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Available Seat:</label>
                            <input className='form-control' type="number" name="available_seat" value={formData.available_seat} onChange={this.handleInputChange} />
                            {this.validator.message('available_seat', formData.available_seat, 'required|available_seat',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Self Placed Amount:</label>
                            <input className='form-control' type="number" name="self_paced_amount" value={formData.self_paced_amount} onChange={this.handleInputChange} />
                            {this.validator.message('self_paced_amount', formData.self_paced_amount, 'required|self_paced_amount',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Mentor Led Amount:</label>
                            <input className='form-control' type="number" name="mentor_led_amount" value={formData.mentor_led_amount} onChange={this.handleInputChange} />
                            {this.validator.message('mentor_led_amount', formData.mentor_led_amount, 'required|mentor_led_amount',{ className: 'text-danger' })}
                        </div>

                        <div className="form-group">
                            <label className='form-label'>Project Image:</label>
                            <input className='form-control' type="file" name="project" onChange={this.handleFileChange} />
                            {this.validator.message('project', formData.project, 'required|project',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Apply Your Knowledge:</label>
                            <input className='form-control' type="text" name="apply_your_knowledge" value={formData.apply_your_knowledge} onChange={this.handleInputChange} />
                            {this.validator.message('apply_your_knowledge', formData.apply_your_knowledge, 'required|apply_your_knowledge',{ className: 'text-danger' })}
                        </div>

                        <div className="form-group">
                            <label className='form-label'>Who is the course for:</label>
                            <input className='form-control' type="text" name="who_is_the_course_for" value={formData.who_is_the_course_for} onChange={this.handleInputChange} />
                            {this.validator.message('who_is_the_course_for', formData.who_is_the_course_for, 'required|who_is_the_course_for',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Video Image:</label>
                            <input className='form-control' type="file" name="video" onChange={this.handleFileChange} />
                            {this.validator.message('video', formData.video, 'required|video',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Course Curriculum Image:</label>
                            <input className='form-control' type="file" name="course_curriculum_image" onChange={this.handleFileChange} />
                            {this.validator.message('course_curriculum_image', formData.course_curriculum_image, 'required|course_curriculum_image',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Tools Used Image:</label>
                            <input className='form-control' type="file" name="tools_used" onChange={this.handleFileChange} />
                            {this.validator.message('tools_used', formData.tools_used, 'required|tools_used', { className: 'text-danger' })}
                        </div>

                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>About this Course:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addArrayInput} style={{ borderRadius: "50%" }} />
                        </div>
                        {formData.about_this_course.map((name, index) => (
                            <div className='content-box' key={index} style={{ marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    value={name}
                                    className='form-control'
                                    onChange={(e) => this.handleArrayInputChange(index, e.target.value)}
                                />
                                <Button type="button" severity="danger" icon="pi pi-times" style={{ borderRadius: "50%" }} className='mt-1' onClick={() => this.removeArrayInput(index)} />
                            </div>
                        ))}


                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <label className='form-label' style={{ margin: "0px 20px" }}>Course Curriculum:</label>
                            <Button type="button" icon="pi pi-plus" onClick={this.addContent} style={{ borderRadius: "50%" }} />
                        </div>

                        {formData.course_curriculum.map((item, index) => (
                            <div key={index} className='content-box'>
                                <div>
                                    <label className='form-label'>Heading:</label>
                                    <input className='form-control' type="text" value={item.head} onChange={(e) => this.handleContentChange(index, 'head', e.target.value)} />
                                </div>
                                <div>
                                    <label className='form-label'>Description:</label>
                                    <textarea className='form-control' value={item.desc} onChange={(e) => this.handleContentChange(index, 'desc', e.target.value)} />
                                </div>
                                <Button type="button" severity="danger" icon="pi pi-times" style={{ borderRadius: "50%" }} className='mt-1' onClick={() => this.removeContent(index)} />
                            </div>
                        ))}

                        <div class="form-check">
                            <input class="form-check-input" name="active" checked={formData.active === 1} onChange={this.handleCheckboxChange} type="checkbox" id="" />
                            <label class="form-check-label" for="">
                                Active
                            </label>
                        </div>

                        <Button type="submit" severity="success" icon="pi pi-check" className="m-3 rounded p-button font-bold" rounded label="submit" />
                        <Link to="/admin/courses" className="m-3 p-button font-bold" style={{ textDecoration: "none", color: "white" }}>
                            Cancel
                        </Link>
                    </form>
                </div>
            </>
        );
    }
}

export default CourseForm;
