import React, { useState } from 'react';
import Cookies from 'js-cookie';

const EventComponent = ({ event }) => {
  const [enrolled, setEnrolled] = useState(false);

  const handleSubmit = async (event_id) => {
    if (Cookies.get('token') === undefined) {
      window.location = "/sign-up"
    }
    let user_id = Cookies.get('token');
    let data = {
      event_id: event_id,
      user_id: user_id,
    }
    // Assuming you have an API endpoint to handle email subscription
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}` + 'enroll-event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setEnrolled(true)
      }

    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  function formatEventDateTime(eventDateTimeString) {
    const eventDateTime = new Date(eventDateTimeString);

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const day = days[eventDateTime.getDay()];

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };


    return `${day},${eventDateTime.toLocaleString('en-US', options)
      .replace(',', '') // Remove the comma after the day
      .replace(/\//g, '-')}`; // Replace slashes with hyphens

  }
  return (
    <div className="event">
      <div className='event-top'>
        <p className="event-datetime">
          {formatEventDateTime(event.event_datetime)}
        </p>
        <img className="event-image" src={event.image.startsWith("http") ? event.image : `https://internz-uploads.s3.ap-south-1.amazonaws.com/events/${event.image}`} alt={event.title} />
      </div>

      <div className='event-content'>
        <h3>{event.title}</h3>
        <button onClick={() => handleSubmit(event.event_id)} disabled={enrolled ? true : false}>{enrolled ? "Enrolled " : "Enroll now "}<i className="fa-solid fa-arrow-up-right-from-square"></i></button>
      </div>
      <p>{event.description}</p>
    </div>
  );
};

const EventList = ({ events }) => {
  const [showAll, setShowAll] = useState(false);

  // Filter and slice the events based on the 'active' property
  const filteredEvents = showAll
    ? events.filter(event => event.active === 1)
    : events.filter((event, index) => index < 3 && event.active === 1);

  return (
    <div className="events-container">
      {filteredEvents.map(event => (
        <EventComponent key={event.id} event={event} />
      ))}
      {!showAll && events.filter(event => event.active === 1).length > 3 && (
        <button className="show-more-events" onClick={() => setShowAll(true)}>Show More</button>
      )}
    </div>
  );
};

export default EventList;