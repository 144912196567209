import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Pathways = () => {
  const [activeTab, setActiveTab] = useState('first');
  const handleClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section id="pathways">
      <div className='pathways container'>
        <div className='course-box '>
          <div className='heading-box'>
            <h6>Java Full Stack Development </h6>
            <button
              className={`tab-button ${activeTab === 'first' ? 'active' : ''}`}
              onClick={() => handleClick('first')}
            >
              <span></span>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Python Full Stack Development</h6>
            <button
              className={`tab-button ${activeTab === 'second' ? 'active' : ''}`}
              onClick={() => handleClick('second')}
            ><span></span>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Devops Full Stack Development</h6>
            <button
              className={`tab-button ${activeTab === 'third' ? 'active' : ''}`}
              onClick={() => handleClick('third')}
            >
              <span></span>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Automation Testing</h6>
            <button
              className={`tab-button ${activeTab === 'fourth' ? 'active' : ''}`}
              onClick={() => handleClick('fourth')}
            >
              <span></span>
            </button>
          </div>
        </div>
        {/* <div className='course-box-mobile'>
          <div className='heading-box'>
            
            <button
              className={`tab-button ${activeTab === 'first' ? 'active' : ''}`}
              onClick={() => handleClick('first')}
            >
              <h6>Java Full Stack Development </h6>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Python Full Stack Development</h6>
            <button
              className={`tab-button ${activeTab === 'second' ? 'active' : ''}`}
              onClick={() => handleClick('second')}
            ><span></span>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Devops Full Stack Development</h6>
            <button
              className={`tab-button ${activeTab === 'third' ? 'active' : ''}`}
              onClick={() => handleClick('third')}
            >
              <span></span>
            </button>
          </div>
          <div className='heading-box'>
            <h6>Automation Testing</h6>
            <button
              className={`tab-button ${activeTab === 'fourth' ? 'active' : ''}`}
              onClick={() => handleClick('fourth')}
            >
              <span></span>
            </button>
          </div>
        </div> */}

        <div id="first" className={`tab-content content-area ${activeTab === 'first' ? 'active' : ''}`}>
          <div className='content-box'>
            <div className='content-card'>
              <p>Master both the front-end and back-end aspects of web development. Full Stack Developers have the versatility to handle the entire development process, from user interface to server-side scripting.</p>
              <h6>Software Developer</h6>
            </div>
            <div className='content-card'>
              <p>Become a master in creating visually stunning and user-friendly interfaces. As a Front-End Developer, you'll craft the look and feel of web applications, ensuring seamless user experiences.</p>
              <h6>Front-End Developer</h6>
            </div>
            <div className='content-card'>
              <p>Delve into the server-side of web development. Back-End Developers manage databases, server architecture, and application logic. They ensure the smooth functioning of web applications.</p>
              <h6>Back-End Java Developer</h6>
            </div>
          </div>
          <div className='content-box'>
            <div className='content-card'>
              <p>Focus on developing robust and scalable web applications using Java, utilizing frameworks and libraries to enhance functionality, ensuring efficiency and reliability.</p>
              <h6>Java Web Application Developer</h6>
            </div>
            <div className='content-card'>
              <p>Specialize in building Application Programming Interfaces (APIs) using Java, facilitating communication between different software applications, promoting seamless integration.</p>
              <h6>Java API Developer</h6>
            </div>
            <div className='content-card'>
              <p>Leverage Java for data-focused development, working on data processing, storage, and retrieval to support data-driven applications and insights, contributing to informed decision-making.</p>
              <h6>Java Data Engineer</h6>
            </div>
          </div>
          <Link className='view-more' to="/pap-java-course#career-pathways" >view more</Link>
        </div>
        <div id="second" className={`tab-content content-area ${activeTab === 'second' ? 'active' : ''}`}>
          <div className='content-box'>
            <div className='content-card'>
              <p>Master both the front-end and back-end aspects of web development. Full Stack Developers have the versatility to handle the entire development process, from user interface to server-side scripting.</p>
              <h6>Python Full Stack Developer</h6>
            </div>
            <div className='content-card'>
              <p>Craft visually stunning and user-friendly interfaces, specializing in shaping the look and feel of web applications for seamless user experiences with a focus on aesthetics.</p>
              <h6>Front-End Python Developer</h6>
            </div>
            <div className='content-card'>
              <p>Specialize in the server-side of web development, managing databases, server architecture, and application logic for smooth web application functionality, optimizing performance.</p>
              <h6>Back-End Python Developer</h6>
            </div>
          </div>
          <div className='content-box'>
            <div className='content-card'>
              <p>Focus on developing robust and scalable web applications using Python, utilizing frameworks and libraries to enhance functionality, ensuring efficiency and reliability.</p>
              <h6>Python Web Application Developer</h6>
            </div>
            <div className='content-card'>
              <p>Specialize in building Application Programming Interfaces (APIs) using Python, facilitating communication between different software applications, promoting seamless integration.</p>
              <h6>Python API Developer</h6>
            </div>
            <div className='content-card'>
              <p>Leverage Python for data-focused development, working on data processing, storage, and retrieval to support data-driven applications and insights, contributing to informed decision-making.</p>
              <h6>Python Data Engineer</h6>
            </div>
          </div>
          <Link className='view-more' to="/pap-python-course#career-pathways" >view more </Link>
        </div>
        <div id="third" className={`tab-content content-area ${activeTab === 'third' ? 'active' : ''}`}>
          <div className='content-box'>
            <div className='content-card'>
              <p>Master the art of streamlining development processes and fostering collaboration between development and operations for efficient software delivery.</p>
              <h6>DevOps Engineer</h6>
            </div>
            <div className='content-card'>
              <p>Focus on automating and optimizing the CI/CD pipeline, ensuring seamless integration, testing, and deployment of software changes.</p>
              <h6>Continuous Integration/Continuous Deployment (CI/CD) Engineer</h6>
            </div>
            <div className='content-card'>
              <p>Ensure the reliability and performance of systems by implementing effective monitoring, incident response, and reliability best practices.</p>
              <h6>Site Reliability Engineer (SRE)</h6>
            </div>
          </div>
          <div className='content-box'>
            <div className='content-card'>
              <p>Specialize in automated testing methodologies, ensuring software reliability and performance through comprehensive testing frameworks.</p>
              <h6>Automation Testing Specialist</h6>
            </div>
            <div className='content-card'>
              <p>Architect and implement cloud-based solutions, leveraging platforms like AWS, Azure, or Google Cloud for scalable and reliable infrastructure.</p>
              <h6>Cloud Solutions Architect</h6>
            </div>
            <div className='content-card'>
              <p>Specialize in integrating security measures into the DevOps pipeline, ensuring secure software development and deployment practices.</p>
              <h6>Security DevOps Engineer</h6>
            </div>
          </div>
          <Link className='view-more' to="/pap-dev-ops-course#career-pathways" >view more </Link>
        </div>
        <div id="fourth" className={`tab-content content-area ${activeTab === 'fourth' ? 'active' : ''}`}>
          <div className='content-box'>
            <div className='content-card'>
              <p>Master the art of automating testing processes, ensuring the reliability and efficiency of software applications through automated testing frameworks.</p>
              <h6>Automation Testing Engineer</h6>
            </div>
            <div className='content-card'>
              <p>Design and implement comprehensive test automation architectures, providing strategic direction for efficient and scalable automated testing solutions.</p>
              <h6>Test Automation Architect</h6>
            </div>
            <div className='content-card'>
              <p>Focus on continuous testing methodologies, ensuring seamless integration of automated tests throughout the software development lifecycle for rapid and reliable releases.</p>
              <h6>Continuous Testing Specialist</h6>
            </div>
          </div>
          <div className='content-box'>
            <div className='content-card'>
              <p>Specialize in Selenium automation, implementing and maintaining automated test scripts for web applications, ensuring consistent functionality and performance.</p>
              <h6>Selenium Automation Engineer</h6>
            </div>
            <div className='content-card'>
              <p>Concentrate on automating API testing, ensuring the functionality, reliability, and security of API endpoints through automated testing frameworks.</p>
              <h6>API Test Automation Engineer</h6>
            </div>
            <div className='content-card'>
              <p>Excel in performance testing, evaluating the scalability and responsiveness of software applications under varying conditions, ensuring optimal performance.</p>
              <h6>Performance Testing Specialist</h6>
            </div>
          </div>
          <Link className='view-more' to="/pap-automation-testing-course#career-pathways" >view more </Link>
        </div>
      </div>
    </section>
  );
};

export default Pathways;
