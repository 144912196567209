import React, { Component } from 'react';
import Header from './../common/headers/header-white';
import Footer from './../common/footer/footer';
import FAQ from './../common/layout/faq'
import CouponsModal from '../common/layout/coupons';
import './../../styles/course.css'
import Cookies from 'js-cookie';
class Course extends Component {
    constructor(props) {
        super(props)
        this.state = {
            backgroundImage: null,
            course: {
                "course_name": "",
                "duration": 0,
                "mentor_name": "",
                "profession": "",
                "students": 0,
                "image": "",
                "background_image": "",
                "category": "",
                "description": "",
                "next_batch": "",
                "total_seat": 0,
                "available_seat": 0,
                "roadmap": "",
                "project": "",
                "about_this_course": "[\ ]",
                "apply_your_knowledge": "",
                "who_is_the_course_for": "",
                "video": "",
                "course_curriculum": "[\ ]",
                "course_curriculum_image": "",
                "tools_used": "",
                "self_paced_amount": 0,
                "mentor_led_amount": 0,
            },
            course_id: '',
            type: '',
            coupon_modal_show: false,
            amount: 0,
        };

        // this.getCourse();
    }

    scrollToPricing = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pricing');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        const { course_id } = params;    
        fetch(`${process.env.REACT_APP_API_URL}` + 'course/' + course_id)
            .then(response => response.json())
            .then(data => {
                if (data.status == true) {
                    this.setState({
                        course: data.course
                    })
                }
            })
            .catch(error => console.error('Error fetching course:', error));
    }

    openCouponModal = (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        this.setState({
            amount: amount,
            course_id: course_id,
            type: type,
        }, () => {
            this.setState({
                coupon_modal_show: true,
            });
        });
    };

    closeCouponModal = () => {
        this.setState({
            coupon_modal_show: false,
        })
    };

    startPayment = (amount) => {
        this.initiatePayment(amount, this.state.course_id, this.state.type)
    }


    initiatePayment = async (amount, course_id, type) => {
        const user_id = Cookies.get("token")
        if (user_id === undefined) {
            this.props.history.push("/sign-up")
        }
        // const { enqData } = this.state;
        // const amountString = document.getElementById(type).innerText
        const amountFloat = parseFloat(amount).toFixed(2);
        // const amountAsNumber = parseFloat(amountFloat);
        const paymentData = {
            user_id: user_id,
            amount: amountFloat,
            course_id: course_id,
            type: type,
        }
        try {
            fetch(`${process.env.REACT_APP_API_URL}` + 'initiate-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            })
                .then(response => response.json())
                .then(data => {
                    // if (data.status) {
                    console.log(data)
                    // }
                    const form = document.createElement('form');
                    form.method = 'post';
                    form.name = 'redirect';
                    form.id = 'nonseamless';
                    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
                    const encRequestInput = document.createElement('input');
                    encRequestInput.type = 'hidden';
                    encRequestInput.name = 'encRequest';
                    encRequestInput.id = 'encRequest';
                    encRequestInput.value = data.data;
                    const accessCodeInput = document.createElement('input');
                    accessCodeInput.type = 'hidden';
                    accessCodeInput.name = 'access_code';
                    accessCodeInput.id = 'access_code';
                    accessCodeInput.value = 'AVDK13KI30BD04KDDB'
                    form.appendChild(encRequestInput);
                    form.appendChild(accessCodeInput)
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }
    render() {
        // Extracting values from props and state
  const { location: { state }, } = this.props;
  let { course } = state || {}; // Using destructuring with default value
        console.log(course)
  // Checking if location.state.course is undefined, use this.state.course
  if (course == undefined && this.state.course) {
    course = this.state.course;
  }

  console.log(course)

//   if (course.course_name=="") {
//     this.props.history.push("/");
//   }
        const { coupon_modal_show, amount } = this.state
        const style = {
            minHeight: "100vh",
        };
        const courseBackgroundImage = {
            minHeight: "40rem",
            backgroundSize: 'cover',  // or 'contain' depending on your preference
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return (
            <>
                <Header />
                <CouponsModal isOpen={coupon_modal_show} onPay={this.startPayment} amount={amount} onClose={this.closeCouponModal} />
                <section className='first-section ' >
                    <div>
                        <div className='vertical-box hide-in-mobile'>
                            <div className='image-area'>
                                <img src={`https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.video}`} alt="" />
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/first.svg`} alt="" />
                                <h6 className='h6-text'>100% online</h6>
                                <p className='p-text'>Learn from anywhere with an internet connection, making it convenient for your schedule.</p>
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/graph.svg`} alt="" />
                                <h6 className='h6-text'>Beginner Level</h6>
                                <p className='p-text'>Ideal for those new to web development, you'll build foundational skills.</p>
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/language.svg`} alt="" />
                                <h6 className='h6-text'>English</h6>
                                <p className='p-text'>The course is conducted in English, ensuring accessibility for a global audience</p>
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/teacher.svg`} alt="" />
                                <h6 className='h6-text'>Live Instructor</h6>
                                <p className='p-text'>Engage with live instructors who will guide you through the course. Get real-time insights, ask questions, and collaborate with fellow learners.</p>
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/clock.svg`} alt="" />
                                <h6 className='h6-text'>3 Months</h6>
                                <p className='p-text'>This comprehensive program spans three months, providing ample time to delve deep into web development concepts and practice your skills.</p>
                            </div>
                            <div className='content-area'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/vertical-box/certificate.svg`} alt="" />
                                <h6 className='h6-text'>Certification</h6>
                                <p className='p-text'>Upon completion, you will receive a professional certification that validates your proficiency in web development, adding value to your academic and professional journey.</p>
                            </div>
                        </div>
                        <div className='first-area container-fluid' style={{ ...courseBackgroundImage, backgroundImage: `url(https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.background_image})` }}>
                            <div className="col-md-7 left-container">
                                <h6>Full Training Course</h6>
                                <h2>{course.course_name}</h2>
                                <p>{course.description}</p>
                                <div className='details-box'>
                                    <div className='details-box-div'>
                                        <div><h5>Next Batch </h5><img src={`${process.env.PUBLIC_URL}/assets/vector/course/class.svg`} alt="" /></div>
                                        <h4>{course.next_batch}</h4>
                                    </div>
                                    <div className='details-box-div'>
                                        <div ><h5 style={{ marginRight: "5px" }}>Available Seats</h5> <img src={`${process.env.PUBLIC_URL}/assets/vector/course/event.svg`} alt="" /></div>
                                        <h4>{course.available_seat}/{course.total_seat}</h4>
                                    </div>
                                </div>
                                <button onClick={() => this.scrollToPricing()}>Apply Now </button>
                            </div>
                            <div className="col-md-5"></div>
                        </div>
                    </div>
                    <div className='second-area container'>
                        <div className="col-md-7 second-box">
                            <h3>About this course</h3>
                            {course.about_this_course ? JSON.parse(course.about_this_course).slice(0, 4).map((about, index) => (
                                <p key={index}>{about}</p>
                            )) : ''}
                            <h6>Apply Your Knowledge:</h6>
                            <p>{course.apply_your_knowledge}</p>
                            <h6>Who Is This Course For?</h6>
                            <p>{course.who_is_the_course_for}</p>
                        </div>
                        <div className="col-md-5"></div>
                    </div>
                </section>

                <section className='second-section'>
                    <div className='container curriculum' >
                        <div className="col-md-4 hide-in-mobile">
                            <img src={course.course_curriculum_image.startsWith("http") ? course.course_curriculum_image : `https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.course_curriculum_image}`} alt="" />
                        </div>
                        <div className="col-md-8">
                            <h3>Course Curriculum:</h3>
                            {JSON.parse(course.course_curriculum).map((curr, index) => (
                                <div className="card" key={index}>
                                    <div className="card-header" id={`heading${index + 1}`}>
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-link"
                                                data-toggle="collapse"
                                                data-target={`#collapse${index + 1}`}
                                                aria-expanded={index === 0 ? 'true' : 'false'}
                                                aria-controls={`collapse${index + 1}`}
                                            >
                                                - {curr.head} <span> +</span>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id={`collapse${index + 1}`}
                                        className={`collapse ${index === 0 ? 'show' : ''}`}
                                        aria-labelledby={`heading${index + 1}`}
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">{curr.desc}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </section>
                <section className='bg-color-f0f0f0'>
                    <div className='third-section'>
                        <div className="container program-highlights">
                            <h2>Program Highlights:</h2>
                            <div className='first-row'>
                                <div className="col-md-4">
                                    <div className='highlight-card'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/program-highlights/goal.svg`} alt="" />
                                        <h6>Full-Stack Mastery:</h6>
                                        <p>Gain knowledge in both full-stack development.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='highlight-card'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/program-highlights/learning.svg`} alt="" />
                                        <h6>Expert-Led Learning:</h6>
                                        <p>Benefit from expert guidance and practical exercises.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='highlight-card'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/program-highlights/design.svg`} alt="" />
                                        <h6>Responsive Design</h6>
                                        <p>Focus on responsive and user-friendly interface.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='second-row'>
                                <div className="col-md-6">
                                    <div className='highlight-card'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/program-highlights/exam.svg`} alt="" />
                                        <h6>Over 30 Hours of Learning:</h6>
                                        <p>Keep ahead of the curve with the latest technologies and trends.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='highlight-card'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/program-highlights/curriculum-vitae.svg`} alt="" />
                                        <h6>Comprehensive Curriculum:</h6>
                                        <p>Dive into a rich development syllabus.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='container-fluid project-box'>
                    <img src={course.project.startsWith("http") ? course.project : `https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.project}`} alt="project" />
                </section>
                <section className='fifth-section'>
                    <div className="container tools-used">
                        <div className="first-container">
                            <img src={course.tools_used.startsWith("http") ? course.tools_used : `https://internz-uploads.s3.ap-south-1.amazonaws.com/courses/${course.tools_used}`} alt="Tools used" />
                        </div>
                    </div>
                </section>
                <section className='bg-color-f0f0f0' id="pricing">
                    <div className='sixth-section'>
                        <div className="container pricing">
                            <h2>Our Expert-Led Courses</h2>
                            <div className='pricing-box'>
                                <div className="col-md-6">
                                    <div className='self-paced'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/self-paced.svg`} alt="" />
                                        <h4>SELF - PACED</h4>
                                        <p>Learn at your own pace with our extensive library of recorded classes, giving you the flexibility to study anytime, anywhere along with hands-on projects.</p>
                                        <div className="row-container">
                                        <div className='self-paced-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/sp-userfocus.svg`} alt="" />
                                                <p>Recorded Sessions</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/sp-calendar.svg`} alt="" />
                                                <p>2 Months</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/sp-certificate.svg`} alt="" />
                                                <p>Certifications</p>
                                            </div>
                                        </div>
                                        <div className='self-paced-benefit'>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/sp-clock.svg`} alt="" />
                                                <p>Hands on Projects</p>
                                            </div>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className='bottom-box'>
                                            <h5>₹<span>{course.self_paced_amount}</span>/-</h5>
                                            <button onClick={() => this.openCouponModal(course.self_paced_amount, course.course_id, 'self-paced')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='mentor-led'>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/mentor-led.svg`} alt="" />
                                        <h4>MENTOR - LED</h4>
                                        <p>Experience live classes, expert mentor guidance, and enhanced placement opportunities with our mentor-led program, ensuring personalized and interactive learning</p>
                                        <div className="row-container">
                                        <div className='mentor-led-benefit'>
                                        <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/UserFocus.svg`} alt="" />
                                                <p>Live + On-Demand Videos</p>
                                            </div>
                                           
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/ClockCounterClockwise.svg`} alt="" />
                                                <p>Hands on Projects</p>
                                            </div>

                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/Calendar.svg`} alt="" />
                                                <p>3 Months</p>
                                            </div>
                                        </div>
                                        <div className='mentor-led-benefit'>
                                        <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/Certificate.svg`} alt="" />
                                                <p>Certifications</p>
                                            </div>
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/Vector.svg`} alt="" />
                                                <p>Placement Assistance</p>
                                            </div>

                                            
                                            <div className='benefit-box'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/course/pricing/ChalkboardTeacher.svg`} alt="" />
                                                <p>Mock Interview</p>
                                            </div>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className='bottom-box'>
                                            <h5>₹<span>{course.mentor_led_amount}</span>/-</h5>
                                            <button onClick={() => this.openCouponModal(course.mentor_led_amount, course.course_id, 'mentor-led')}>Enroll Now <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container-fluid ceritification">
                    <div className='ceritification container'>
                        <img src={`${process.env.PUBLIC_URL}/assets/vector/course/certifications.svg`} alt="" />
                    </div>
                </section>
                <FAQ />
                <Footer />

            </>
        )
    }
}


export default Course;