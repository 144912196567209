import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../../../styles/pap-main.css'; // Import the CSS file

const PapFirstSection = () => {
    const [activeIndex, setActiveIndex] = useState(true);
    const [firstIndexClicked, setFirstIndexClicked] = useState(false);
    const [secondIndexClicked, setSecondIndexClicked] = useState(false);
    const [thirdIndexClicked, setThirdIndexClicked] = useState(false);
    const [explore, setExplore] =useState(false);
    const handleClearClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(false);
        setThirdIndexClicked(false);
    };

    const handleExplore = () => {
        if (explore){
            setExplore(false);
        } else{
            setExplore(true)
        }
       
    };
    const firstHandleClick = () => {
        setFirstIndexClicked(true);
        setSecondIndexClicked(false);
        setThirdIndexClicked(false);
        box();
    };
    const secondHandleClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(true);
        setThirdIndexClicked(false);
        box();
    };
    const thirdHandleClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(false);
        setThirdIndexClicked(true);
    };
    const scrollToSection = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pathways');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [isHovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(!isHovered);
    };
    const box = () => {
        // const referenceBoxId = 'second-container'; // Replace with your actual ID
        // const referenceBox = document.getElementById(referenceBoxId);
        // const referenceHeight = referenceBox ? referenceBox.scrollHeight : 0;
        // console.log(referenceHeight);
        // // Set the height of all my-box elements to the height of the reference div
        // const myBoxIds = ['first-container', 'second-container', 'third-container']; // Replace with your actual IDs
        // myBoxIds.forEach(id => {
        //   const box = document.getElementById(id);
        //   if (box) {
        //     box.style.height = `${referenceHeight}px`;
        //   }
        // });
    }

    const firstSectionStyle = {
        // paddingTop:"100px",
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/pap/main/main.svg)`,
        backgroundSize: 'cover',  // or 'contain' depending on your preference
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'fixed',
    }

    return (
        <section style={firstSectionStyle} className="first-section-container hide-in-mobile">
            <div className={`container container-box`}>
                <div className={`container ${firstIndexClicked || secondIndexClicked || thirdIndexClicked ? 'hide-div' : 'show-div'}`}>
                    <div className="first-card-box col-md-12">
                        <h1>Your Career our Responsibility</h1>
                        <h4>A Placement Assurance Program (PAP) is a program or initiative offered by Internz Learn to help the students secure job placements or employment opportunities after completing the courses or programs. The primary goal of a Placement Assurance Program is to increase the chances of graduates or final year students who are pursuing and finding suitable employment in their chosen field.</h4>
                        <div className='explore-div'>
                        <button onClick={handleExplore}>Explore courses <i className="fa-solid fa-angles-down"></i></button>
                        <div className={`${explore?'show':'hide'} explore-courses`} >
                        <a href="/pap-java-course">Java Full Stack </a>
                        <a href="/pap-python-course">Python Full Stack </a>
                        <a href="/pap-dev-ops-course">DevOps </a>
                        <a href="/pap-automation-testing-course">Automation Testing </a>
                        </div>
                        </div>
                        
                        <div className='circle-parent-container'>
                            <div className="circle-container">
                                <div className="circle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse1.svg)`, backgroundSize: "cover" }}></div>
                                <div className="circle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse2.svg)`, backgroundSize: "cover" }}></div>
                                <div className="circle" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/banner-logo/Ellipse3.svg)`, backgroundSize: "cover" }}></div>
                                <div className="circle-text">15000+ Happy Students</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`slide-item ${firstIndexClicked ? 'shift-left' : ''}`} >
                    <div className='flex-row' style={{height:"100%"}}>
                        <div className='title-box' style={{ backgroundColor: "#000" }}>
                            <button className={`${!firstIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => firstHandleClick()}><i className="fa-solid fa-plus"></i></button>
                            <button className={`${firstIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => handleClearClick()}><i className="fa-solid fa-x"></i></button>
                            <h3 className={`${firstIndexClicked ? 'show-content-h3' : 'hide-content-h3'}`}>How it works?</h3>
                        </div>
                        <div className={`my-box ${firstIndexClicked ? 'show-content' : 'hide-content'}`} style={{ backgroundColor: "#000" }}>
                            <div className="container content-box" id="first-container">
                                <h2><span className='span-1'>INTERNZ</span><span className='span-2'>L</span><span className='span-3'>EARN</span></h2>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/how-it-works.svg`} alt="" />
                                <div className='container-fluid second-row-container'>
                                    <h2>Who Should Join?</h2>
                                    <p>Our Placement Assurance Programs cater to a diverse audience, making them suitable for various individuals at different stages of their careers and educational journeys. Here's who can benefit from these programs:</p>
                                    <div className='flex-row card-area'>
                                        <div className='card-box'>
                                            <h3>1. Recent Graduates:</h3>
                                            <p>Graduates who are entering the job market for the first time can bridge the gap between their academic learning and real-world job requirements through our Placement Assurance Programs.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>2. Career Changers:</h3>
                                            <p>Those transitioning to a new career or industry can gain the necessary skills and insights to succeed in their new field with our program.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>3. Job Seekers:</h3>
                                            <p>Individuals actively searching for employment but facing challenges in securing a job can benefit from our job placement support, resume building, and interview preparation.</p>
                                        </div>
                                    </div>
                                    <div className="flex-row card-area">
                                        <div className='card-box' >
                                            <h3>4. College Students:</h3>
                                            <p>Students looking to gain practical experience through internships can find relevant opportunities through our program. Some programs also offer internship placement assistance.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>5. Mid-Career Professionals:</h3>
                                            <p>Professionals seeking career advancement or more challenging roles can enhance their prospects through skill enhancement workshops and networking opportunities.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>6. Career Re-Starters:</h3>
                                            <p>Individuals who took a career break for various reasons and are now reentering the workforce can update their skills and restart their careers with our tailored support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`slide-item ${secondIndexClicked ? 'shift-left' : ''}`} >
                    <div className='flex-row this-div' style={{height:"100%"}}>
                        <div className='title-box' style={{ backgroundColor: "#1A1A1A" }}>
                            <button className={`${!secondIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => secondHandleClick()}><i className="fa-solid fa-plus"></i></button>
                            <button className={`${secondIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => handleClearClick()}><i className="fa-solid fa-x"></i></button>
                            <h3 className={`${secondIndexClicked ? 'show-content-h3' : 'hide-content-h3'}`}>Courses</h3>
                        </div>
                        <div className={`my-box ${secondIndexClicked ? 'show-content' : 'hide-content'}`} style={{ backgroundColor: "#1A1A1A" }}>
                            <div className="container content-box-2" id="second-container">
                                <h2>Programs We Offer</h2>
                                <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring to be a Java Full Stack Developer, Python Full Stack Developer, Automation Testing Expert with Selenium, or a DevOps Professional, we have the perfect program for you.</p>
                                <div className='first-row-container'>
                                    <div className='flex-row card-area'>
                                        <div className="card-box">
                                            <h3>Java Full Stack</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href="/pap-java-course"><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                        <div className="card-box">
                                            <h3>Python Full Stack</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href="/pap-python-course"><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                    </div>
                                    <div className='flex-row card-area'>
                                        <div className="card-box">
                                            <h3>DevOps</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href='/pap-dev-ops-course'><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                        <div className="card-box">
                                            <h3>Automation Testing</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href='/pap-automation-testing-course'><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='second-row-container'>
                                    <h3>Career Services</h3>
                                    <p>Practice essential skills to be ready for interview possibilities. Obtain assistance with developing your online résumé and profile. Practice interviews with professionals to improve your interviewing abilities. Through a career mentoring session, get solutions to your questions about your professional life. You'll be equipped to ace interviews at the conclusion of the program</p>
                                    <div className='second-card-box'>
                                        <h6>Build an exciting resume and digital profile for recruiters</h6>
                                        <p>Craft a standout resume and LinkedIn profile to make a strong impression on recruiters, highlighting your skills and achievements for a competitive edge in the job market.</p>
                                    </div>
                                    <div className='second-card-box'>
                                        <h6>Sharpen interview skills with mock interview sessions</h6>
                                        <p>Enhance interview skills with mock sessions, getting valuable feedback on responses and body language to boost confidence and excel in job interviews.</p>
                                    </div>
                                    <div className='second-card-box'>
                                        <h6>Resolve any career-related queries have clarity and confidence</h6>
                                        <p>Consult experienced mentors or career coaches for clarity on your career path, empowering you to make informed decisions about job changes, progression, skills, and other career concerns</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`slide-item ${thirdIndexClicked ? 'shift-left' : ''}`}>
                    <div className='flex-row' style={{height:"100%"}}>
                        <div className='title-box' style={{ backgroundColor: "#2B2B2B" }}>
                            <button className={`${!thirdIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => thirdHandleClick()}><i className="fa-solid fa-plus"></i></button>
                            <button className={`${thirdIndexClicked ? 'show-content' : 'hide-content'}`} onClick={() => handleClearClick()}><i className="fa-solid fa-x"></i></button>
                            <h3 className={`${thirdIndexClicked ? 'show-content-h3' : 'hide-content-h3'}`}>Key Highlights</h3>
                        </div>
                        <div className={`my-box ${thirdIndexClicked ? 'show-content' : 'hide-content'}`} style={{ backgroundColor: "#2B2B2B" }}>
                            <div className="container content-box-3" id="third-container">
                                <h2>Program Highlights:</h2>

                                <div className='first-row-container'>
                                    <div className="fourth-about-container container">
                                        <div className="about-cards">
                                            <div className="colorful-card card1">
                                                <h3>Personalized Career Counseling:</h3>
                                                <p>Receive one-on-one career guidance tailored to your goals and aspirations.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset1.svg`} alt="" />
                                                {/* <h4>Our Story:</h4>
                                                <p>Our journey began as a collective dream of a small group of passionate educators and tech enthusiasts. What started as a shared vision has blossomed into a hub of transformative learning experiences that bridge the gap between theoretical knowledge and practical application in the professional realm.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Heart.svg`} alt="" /> */}
                                            </div>
                                            <div className="colorful-card card2">
                                            
                                                <h3>Comprehnsive Skill Workshop:</h3>
                                                <p>Participate in a range of skill-building workshops designed to enhance your abilities.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset2.svg`} alt="" />
                                                {/* <h4>Team and Culture:</h4>
                                                <p>Our team is a vibrant mix of educators, industry veterans, and enthusiastic innovators. Fuelled by a common passion for empowering learners, we've created a culture that thrives on collaboration, open communication, and a relentless pursuit of excellence.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/UsersThree.svg`} alt="" /> */}
                                            </div>
                                            <div className="colorful-card card3">
                                            
                                                <h3>Live Classes & LMS Access:</h3>
                                                <p>Attend live classes and enjoy access to the Learning Management System (LMS) for flexible learning.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset3.svg`} alt="" />
                                                {/* <h4>Milestones:</h4>
                                                <p>We're immensely proud of our numerous achievements, not just in the realm of educational innovation, but in the transformative experiences we've provided to countless learners. The positive impact and success stories of our students remain the cornerstone of our achievements.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Trophy.svg`} alt="" /> */}
                                            </div>
                                            <div className="colorful-card card4">
                                            
                                                <h3>Relevant Real-Time Projects:</h3>
                                                <p>Apply your knowledge to real-world projects that reflect industry demands.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset4.svg`} alt="" />
                                                {/* <h4>Clientele or Impact:</h4>
                                                <p>Our impact extends far beyond the walls of our virtual classrooms. Our programs have nurtured the careers of individuals, transforming them into industry-ready professionals who are equipped to tackle real-world challenges.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/FinnTheHuman.svg`} alt="" /> */}
                                            </div>
                                            {/* <div className="colorful-card card5">
                                                <h3>Networking Sessions & Industry Seminars:</h3>
                                                <p>Benefit from networking opportunities and stay informed through industry seminars.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset5.svg`} alt="" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="fourth-about-container container">
                                        <div className="about-cards">
                                            <div className="colorful-card card1">
                                            
                                                <h3>Placement Portal for Enrolled Students:</h3>
                                                <p>Access a specialized portal for placement opportunities and updates.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset6.svg`} alt="" />
                                                {/* <h4>Our Story:</h4>
                                                <p>Our journey began as a collective dream of a small group of passionate educators and tech enthusiasts. What started as a shared vision has blossomed into a hub of transformative learning experiences that bridge the gap between theoretical knowledge and practical application in the professional realm.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Heart.svg`} alt="" /> */}
                                            </div>
                                            <div className="colorful-card card2">
                                                {/* <h4>Team and Culture:</h4>
                                                <p>Our team is a vibrant mix of educators, industry veterans, and enthusiastic innovators. Fuelled by a common passion for empowering learners, we've created a culture that thrives on collaboration, open communication, and a relentless pursuit of excellence.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/UsersThree.svg`} alt="" /> */}
                                                 
                                                <h3>Assignments & Coding Practice:</h3>
                                                <p>Engage in assignments and coding practice exercises tailored to the topics covered</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset7.svg`} alt="" />
                                            </div>
                                            <div className="colorful-card card3">
                                                {/* <h4>Milestones:</h4>
                                                <p>We're immensely proud of our numerous achievements, not just in the realm of educational innovation, but in the transformative experiences we've provided to countless learners. The positive impact and success stories of our students remain the cornerstone of our achievements.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Trophy.svg`} alt="" /> */}
                                                                                            
                                            <h3>Resume Building Workshops:</h3>
                                            <p>Attend workshops to craft an impressive resume that stands out to employers.</p>
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset8.svg`} alt="" />
                                            </div>
                                            <div className="colorful-card card4">
                                                {/* <h4>Clientele or Impact:</h4>
                                                <p>Our impact extends far beyond the walls of our virtual classrooms. Our programs have nurtured the careers of individuals, transforming them into industry-ready professionals who are equipped to tackle real-world challenges.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/FinnTheHuman.svg`} alt="" /> */}
                                                                                                
                                                <h3>Interview Preparation & Mock Interviews:</h3>
                                                <p>Receive guidance on interview preparation and practice through mock interviews.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset9.svg`} alt="" />
                                            </div>
                                            {/* <div className="colorful-card card5">
                                                <h3>Certificates Related to Tools & Languages:</h3>
                                                <p>Earn certificates related to the tools and languages you master during the program.</p>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset10.svg`} alt="" />
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className='flex-row first-row'>
                                        <div>
                                            <div className='card-box-first large'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset1.svg`} alt="" />
                                                <h3>Personalized Career Counseling:</h3>
                                                <p>Receive one-on-one career guidance tailored to your goals and aspirations.</p>
                                            </div>
                                        </div>
                                        <div className='flex-row-wrap'>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset2.svg`} alt="" />
                                                <h3>Comprehensive Skill Workshop:</h3>
                                                <p>Participate in a range of skill-building workshops designed to enhance your abilities.</p>
                                            </div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset3.svg`} alt="" />
                                                <h3>Live Classes & LMS Access:</h3>
                                                <p>Attend live classes and enjoy access to the Learning Management System (LMS) for flexible learning.</p>
                                            </div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset4.svg`} alt="" />
                                                <h3>Relevant Real-Time Projects:</h3>
                                                <p>Apply your knowledge to real-world projects that reflect industry demands.</p>
                                            </div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset5.svg`} alt="" />
                                                <h3>Networking Sessions & Industry Seminars:</h3>
                                                <p>Benefit from networking opportunities and stay informed through industry seminars.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className='flex-row first-row'>
                                        <div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset6.svg`} alt="" />
                                                <h3>Placement Portal for Enrolled Students:</h3>
                                                <p>Access a specialized portal for placement opportunities and updates.</p>
                                            </div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset7.svg`} alt="" />
                                                <h3>Assignments & Coding Practice:</h3>
                                                <p>Engage in assignments and coding practice exercises tailored to the topics covered</p>
                                            </div>
                                        </div>
                                        <div className='card-box-first large'>
                                            <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset8.svg`} alt="" />
                                            <h3>Resume Building Workshops:</h3>
                                            <p>Attend workshops to craft an impressive resume that stands out to employers.</p>
                                        </div>
                                        <div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset9.svg`} alt="" />
                                                <h3>Interview Preparation & Mock Interviews:</h3>
                                                <p>Receive guidance on interview preparation and practice through mock interviews.</p>
                                            </div>
                                            <div className='card-box-first'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/key-highlight/asset10.svg`} alt="" />
                                                <h3>Certificates Related to Tools & Languages:</h3>
                                                <p>Earn certificates related to the tools and languages you master during the program.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <p>Upon completion, you will receive a professional certification that validates your proficiency in web development, adding value to your academic and professional journey. Upon completion, you will receive a professional certification that validates your proficiency in web development, adding value to your academic and professional journey.journey. Upon completion, you will receive a professional certification that validates your proficiency in web development, adding value to your academic and professional journey.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PapFirstSection;
