import React, { Component } from 'react';
import RatingStar from './rating-star'
import './../../../styles/common.css'

class Testimonials extends Component {


    render() {
        return (
            <>
                <section className='dark-testimonial-section container'>
                    {/* <div className=' container'> */}
                    <div className='testimonial-heading'><h6>Don't Just Take Our Word for It, Hear from Our Students</h6></div>
                    <div className="row testimonial-card">
                        <div className="">
                            <RatingStar />
                            <p>InternzLearn has been a game-changer for my career. The mentorship and guidance I received were instrumental in shaping my skills and making me industry-ready. A heartfelt thank you for the incredible support!</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/girl-avatar.svg`} alt="" />
                                <div>
                                    <h4>Mansi</h4>
                                    <h5>Web Development</h5>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>InternzLearn exceeded my expectations with their outstanding mentorship and guidance. The commitment to making students industry-ready is truly remarkable. Thank you for helping me pave the way for a successful career.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Harshit Singh</h4>
                                    <h5>Cyber Security</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>I can't express my gratitude enough for the mentorship and guidance provided by InternzLearn. Their commitment to student success is evident. Thank you for the invaluable experience. A heartfelt thank you.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Rahul Kumar</h4>
                                    <h5>Artificial Intelligence</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>InternzLearn exceeded my expectations with their outstanding mentorship and guidance. The commitment to making students industry-ready is truly remarkable. Thank you for helping me pave the way for a successful career.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Suraj Sharma</h4>
                                    <h5>Data Science</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>Thank you, InternzLearn, for the exceptional mentorship and guidance that played a pivotal role in my career development. The commitment to making students industry-ready. Grateful for the valuable experience.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Rohit</h4>
                                    <h5>VLSI</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>InternzLearn has been my guiding light in the professional realm. The mentorship and guidance received were beyond valuable. A heartfelt thank you for your commitment to shaping future industry leaders.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Harendra</h4>
                                    <h5>Sales & Marketing</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>My sincere gratitude to InternzLearn for the exceptional mentorship and guidance. Their commitment to making students industry-ready is evident in every aspect of their program. Thank you for being a catalyst in my career journey.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Yash</h4>
                                    <h5>Cloud Computing</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>InternzLearn's mentorship and guidance have been a cornerstone in my career growth. The commitment to student success is unwavering, and I am truly grateful for the invaluable experience. Thank you.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Apurv</h4>
                                    <h5>Python</h5>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <RatingStar />
                            <p>Thank you, InternzLearn, for the transformative mentorship and guidance. Your commitment to making students industry-ready sets you apart. Grateful for the skills and knowledge gained under your mentorship.</p>
                            <div className='profile'>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/profile/boy-avatar.svg`} alt="" />
                                <div>
                                    <h4>Yashpal</h4>
                                    <h5>Digital Marketing</h5>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* </div> */}
                </section>
            </>
        )
    }
}


export default Testimonials;