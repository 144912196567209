import React, { useState, Component } from 'react';
import Cookies from 'js-cookie';
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import './styles/modal.css'; // Import your CSS file for styling

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailOrUsername: '',
            loginPassword: '',
            loginMessage: '',
            loginStatus: false,
            login: false,
            showModal: false,
        };
    }
    handleLoginClick = () => {
        this.setState({
            showModal: true,
        })
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
        })
    };
    handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    handleLoginSubmit = (e) => {
        e.preventDefault();

        // Add API call logic here
        const logInData = {
            emailOrUsername: this.state.emailOrUsername,
            password: this.state.loginPassword,
        };

        // Example API call using fetch
        fetch(`${process.env.REACT_APP_API_URL}` + 'admin/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logInData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == true) {
                    this.setState({
                        login: true,
                        loginStatus: true,
                        loginMessage: data.message,
                    })
                    const expirationHours = 1; // Set the desired expiration in hours
                    const currentDate = new Date();
                    currentDate.setTime(currentDate.getTime() + expirationHours * 60 * 60 * 1000); // Convert hours to milliseconds
                    Cookies.set('ghpackedfghpstfxdexh', data.token, { expires: currentDate });
                    Cookies.set('username', data.name, { expires: currentDate });
                    this.props.history.push('/admin/dashboard/')
                } else {
                    this.setState({
                        login: true,
                        loginStatus: false,
                        loginMessage: data.message,
                    })
                }
            })
            .catch(error => {
                console.error('Error login: ', error);
                // Handle error or display an error message to the user
            });
    };


    render() {
        const show={
            display: "block"
        }
        const hide={
            display: "none"
        }
        return (
            <div >
                <div className="modal-overlay-admin auth-page-section-login">
                    <div className="modal-content-admin">
                        {/* Add your login form or content here */}
                        <h2 className='login'>Login</h2>
                        <form onSubmit={this.handleLoginSubmit}>
                            <div className="login-box">
                                <div className="form-group">
                                    <label className='form-label'>
                                        Email / Username:
                                    </label>
                                    <input
                                        type="text"
                                        name="emailOrUsername"
                                        className='form-control'
                                        value={this.state.emailOrUsername}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>
                                        Password:
                                    </label>
                                    <input
                                        type="password"
                                        name="loginPassword"
                                        className='form-control'
                                        value={this.state.loginPassword}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>

                                <button type="submit">Log In</button>
                                {/* <h6>Forgot Password?</h6> */}
                                {/* <h5>Not a member?<span onClick={()=>this.handleModalClose()}>Sign up</span></h5> */}
                                <div class="alert alert-success" style={this.state.login ? this.state.loginStatus ? show : hide : hide} role="alert">
                                        {this.state.loginMessage}
                                    </div>
                                    <div class="alert alert-danger" style={this.state.login ? this.state.loginStatus ? hide : show : hide} role="alert">
                                        {this.state.loginMessage}
                                    </div>
                            </div>

                        </form>
                    </div>
                </div>
                {/* )} */}
            </div>
        );
    };
};
export default LoginModal;
// export default LoginModal;
