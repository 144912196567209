import React, { Component } from 'react';
import Testimonials from './../common/layout/testimonials';
import HeaderBlack from '../common/headers/header-black';
import Footer from '../common/footer/footer';
import EnquiryForm from '../common/layout/enquiry-form';
import CountUpBanner from '../common/layout/count-up-banner';
import './../../styles/about.css'
class AboutUs extends Component {

    render(){
        const first={
            height: "120vh",
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/first-section/first-background.svg)`,
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        const third={
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/third-section/Lingkaran.svg)`,
            backgroundSize: 'contain', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
        }
        const fourth = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/background.svg)`,
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return (
            <>
            <div style={{backgroundColor:"#000"}}>
            <HeaderBlack />
            <section className='' style={first}>
                <div className="first-about-container container">
                    <div>
                    <h1>Welcome to InternzLearn - Where Learning Meets Innovation!</h1>
                    <p>InternzLearn was born out of a simple yet powerful idea: to redefine education by empowering individuals with industry-aligned skills and real-world experiences. With a heartbeat for learning and an eye for innovation, we strive to reimagine the educational landscape.</p>
                    </div>
                </div>
            </section>
            <CountUpBanner />
            <section className='second-about-section'>
                <div className='second-about-container container'>
                    <div className="image-card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/second-section/22.svg)`, backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
                        <h6>Visionary Education:</h6>
                        <p> We pioneer innovative learning, weaving advanced technology and practical experience into our programs, ensuring students are prepared for the dynamic landscape of tomorrow's industries</p>
                    </div>
                    <div className="image-card top-gap" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/second-section/23.svg)`, backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
                        <h6>Expert-Led Learning:</h6>
                        <p>Our faculty comprises industry veterans and seasoned mentors who bring their real-world experience into the classroom, enriching the education with practical insights.</p>
                    </div>
                    <div className="image-card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/second-section/24.svg)`, backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
                        <h6>Personalized Growth:</h6>
                        <p>Tailored educational journeys enable each student to grow personally and professionally, fostering an environment of diverse learning styles and individualized progress.</p>
                    </div>
                    <div className="image-card top-gap" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/about/second-section/25.svg)`, backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
                        <h6>Career Empowerment:</h6>
                        <p>We go beyond education by nurturing career prospects, equipping our students with the tools and confidence to thrive in today's competitive job market.</p>
                    </div>

                </div>
            </section>
            <section className='third-about-section' style={third}>
                <div className="third-about-container container">
                    <h2>Mission and Values</h2>
                    <p>At InternzLearn, we're driven by a profound mission: to redefine the landscape of education. Our journey pivots around the fundamental belief that learning isn't confined to classrooms but extends as a lifelong quest. Our commitment is firm—we aim to cultivate an ecosystem that thrives on curiosity, innovation, and immersive hands-on learning experiences.</p>
                    <p>Within our core, inclusivity, adaptability, and an unwavering dedication to continuous growth form the cornerstone of our values. We tirelessly champion a future where learning transcends boundaries and evolves as an engaging, diverse, and adaptive process. It's this very commitment that propels us to offer a learning platform that fosters personal growth, innovation, and boundless curiosity.</p>
                </div>
            </section>
            <section className='fourth-about-section' style={fourth}>
                <div className="fourth-about-container container">
                    <h2>Embarking on Excellence: Our Evolutionary Journey</h2>
                    <div className="about-cards">
                        <div className="colorful-card card1">
                            <h4>Our Story:</h4>
                            <p>Our journey began as a collective dream of a small group of passionate educators and tech enthusiasts. What started as a shared vision has blossomed into a hub of transformative learning experiences that bridge the gap between theoretical knowledge and practical application in the professional realm.</p>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Heart.svg`} alt="" />
                        </div>
                        <div className="colorful-card card2">
                            <h4>Team and Culture:</h4>
                            <p>Our team is a vibrant mix of educators, industry veterans, and enthusiastic innovators. Fuelled by a common passion for empowering learners, we've created a culture that thrives on collaboration, open communication, and a relentless pursuit of excellence.</p>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/UsersThree.svg`} alt="" />
                        </div>
                        <div className="colorful-card card3">
                            <h4>Milestones:</h4>
                            <p>We're immensely proud of our numerous achievements, not just in the realm of educational innovation, but in the transformative experiences we've provided to countless learners. The positive impact and success stories of our students remain the cornerstone of our achievements.</p>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Trophy.svg`} alt="" />
                        </div>
                        <div className="colorful-card card4">
                            <h4>Clientele or Impact:</h4>
                            <p>Our impact extends far beyond the walls of our virtual classrooms. Our programs have nurtured the careers of individuals, transforming them into industry-ready professionals who are equipped to tackle real-world challenges.</p>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/FinnTheHuman.svg`} alt="" />
                        </div>
                        <div className="colorful-card card5">
                            <h4>Innovation and Future Vision:</h4>
                            <p>Innovation is not just a buzzword for us; it's our guiding principle. We're committed to constantly seeking newer, more effective pedagogies, and pioneering technologies to ensure our learners are well-prepared for the evolving professional landscape.</p>
                            <img src={`${process.env.PUBLIC_URL}/assets/vector/about/fourth-section/Brandy.svg`} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <section className='eigth-section container'>
                        <div className="chat-box">
                            <div className="contact">
                                <h5>Chat to our friendly team</h5>
                                <p>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
                                <div className='flex'>
                                    <i className="fa-regular fa-envelope"></i>
                                    <div>
                                        <h6>Email</h6>
                                        <p>Our friendly team is here to help.</p>
                                        <h6>support@internzlearn.com</h6>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <i className="fa-solid fa-phone"></i>
                                    <div>
                                        <h6>Phone</h6>
                                        <h6>+91 9606904398</h6>
                                        <h6>+91 9606904399</h6>
                                        <h6>+91 9606904363</h6>
                                    </div>
                                </div>
                                <div className='social'>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/fb.svg`} alt="" />
                                    </div>
                                    <div>
                                        <a href="https://www.instagram.com/internz_learn/" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/insta.svg`} alt="" /></a>
                                    </div>
                                    {/* <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/twitter.svg`} alt="" />
                                    </div> */}
                                    <div>
                                        <a href="https://www.linkedin.com/company/internzlearn01/" target='_blank'><img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/linkedin.svg`} alt="" /></a>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/vector/main/chat-section/youtube.svg`} alt="" />
                                    </div>


                                </div>

                            </div>
                            <div className="connect">
                               <EnquiryForm dark={true} />
                            </div>
                        </div>
                    </section>
            <Footer />
            </div>
            </>
        )
    }
}


export default AboutUs;