import React, { useState } from 'react';
import Slider from "react-slick";
import './../../../styles/common.css';
const Unique = ({ color }) => {
    return (
        <>
            <section>
                <div className='container unique'>
                    <h6>Our Unique Educational Offerings</h6>
                    <div className='unique-content-area flex-row '>
                        <div className='unique-content-box'>
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/first.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-white'>Features that Motivate You:</div>
                                <p>- Simple Learning through a Well-Structured Curriculum </p>
                                <p>- LMS Access </p>
                                <p>- Weekly Goals to Keep You Inspired </p>
                                <p>- Solving Different Level Programming Puzzles </p>
                                <p>- Compile & Execute in a Unified Code Environment</p>
                            </div>
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/second.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-blue'>30-Minute Turnaround for Classification:</div>
                                <p>- 1:1 Meeting with Our Qualified Teaching Assistants Through Voice Calls & Chat</p>
                                <p>- Immediate support for problem-solving and clarification.</p>
                            </div>
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/third.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-white'>Comprehensive Placement Program to Prepare You for Employment:</div>
                                <p>- Access to Professional Resumes, Benefits of the Hirist Spotlight, and Particular Attention to Soft Skills</p>
                                <p>- Special attention to honing essential soft skills.</p>
                                <p>- Polished resume creation for a professional image.</p>
                            </div>
                        </div>
                        <div className='images hide-in-mobile'><img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/center.svg`} alt="" /></div>
                        <div className='unique-content-box second-box' >
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/fourth.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-blue'>Mentors from the Top Industries to Support Your Growth:</div>
                                <p>- 1:1 Resume Building Sessions, Mock Interviews in the Selected Domains, and Career Guidance</p>
                                <p>- Structured Reviews to Improve Your Performance</p>
                                <p>- Gain the Opportunity to Be Referred to Your Mentor's Firm</p>
                            </div>
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/fifth.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-white'>Most Adaptable Program Available in the IT Sector:</div>
                                <p>- Learning Autonomy</p>
                                <p>- Customizable learning paths for individual preferences.</p>
                                <p>- Flexibility in learning pace and style.</p>
                            </div>
                            <div className='unique-content' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/vector/pap/main/unique/sixth.svg)`,backgroundSize:"cover"}}>
                                <div className='content-header-blue'>Results:</div>
                                <p>- Trackable results in terms of skill enhancement and progress.</p>
                                <p>- Data-driven insights to measure program effectiveness.</p>
                                <p>- Witness the tangible outcomes of your dedication and hard work.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Unique