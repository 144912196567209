import React, { Component } from 'react';
import axios from 'axios';
import AddBlog from './add-course';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import Cookies from 'js-cookie';
class Courses extends Component {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.state = {
            courses: [],
        };
        this.getCourses();
    }
    checkLogin= ()=>{
        if (Cookies.get('ghpackedfghpstfxdexh') === undefined) {
           window.location= '/admin-login'
        } 
    }

    getCourses = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'all-courses', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.data.status === true) {
            this.setState({
                courses: response.data.results,
            });
        }
    };

    convertIntoDateTime =(datetime)=>{
        const timestamp = datetime; // Assuming blog.date is a timestamp

// Convert the timestamp to a Date object
const dateObject = new Date(timestamp);

// Get individual date components
const year = dateObject.getFullYear();
const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
const day = String(dateObject.getDate()).padStart(2, '0');

// Get individual time components
const hours = String(dateObject.getHours() % 12 || 12).padStart(2, '0');
const minutes = String(dateObject.getMinutes()).padStart(2, '0');
const ampm = dateObject.getHours() >= 12 ? 'PM' : 'AM';

// Construct the formatted date-time string with a hyphen separator
const formattedDateTime = `${day}-${month}-${year} | ${hours}:${minutes} ${ampm}`;

return formattedDateTime;

    }
    

    setActiveChecked = (id, checked) => {
        let check = checked ? 1 : 0;
        const activeData = {
            "id": id,
            "active": check,
        }
        fetch(`${process.env.REACT_APP_API_URL}`+'course/active', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(activeData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getCourses();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    checkBoxActiveBodyTemplate = (rowData) => {
        return (
            <Checkbox
                onChange={(e) => this.setActiveChecked(rowData.id, e.checked)}
                checked={rowData.active === 1}
            />
        );
    };

    deleteCourse = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}`+'course/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.getCourses();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    deleteBodyTemplate =(rowData) =>{
        return(
            <Button icon="pi pi-times" rounded text raised severity="danger" onClick={()=>this.deleteCourse(rowData.id)} style={{borderRadius:"50%"}} aria-label="Cancel" />
        )
    }

    editCourse = (course_id) => {
        this.props.history.push(`/admin/edit-course/${course_id}`)
    }
    editBodyTemplate =(rowData) =>{
        return(
            <Button icon="pi pi-file-edit" rounded text raised severity="warning" onClick={()=>this.editCourse(rowData.course_id)} style={{borderRadius:"50%"}} aria-label="Cancel" />
        )
    }
    render() {
        const columns = [
            { field: 'id', header: 'ID' },
            { field: 'course_name', header: 'Name' },
            { field: 'category', header: 'Category' },
            { field: 'active', header: 'Active' },
            { field: 'edit', header: 'Edit' },
            { field: 'action', header: 'Action' },
        ];

        return (
            <>
                <div className='container'>
                    <Link to="/admin/add-course" className="p-button font-bold mt-2 mb-2" style={{textDecoration:"none",color:"white"}}>
                        Add Course
                    </Link>
                    <DataTable
                        value={this.state.courses}
                        stripedRows
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column field={columns[0].field} header={columns[0].header} />
                        <Column field={columns[1].field} header={columns[1].header} />
                        <Column field={columns[2].field} header={columns[2].header} />
                        <Column
                            field={columns[3].field}
                            header={columns[3].header}
                            body={this.checkBoxActiveBodyTemplate}
                        />
                        <Column field={columns[4].field} header={columns[4].header} body={this.editBodyTemplate} />
                        <Column field={columns[5].field} header={columns[5].header} body={this.deleteBodyTemplate} />
                    </DataTable>
                </div>
            </>
        );
    }
}

export default Courses;
