import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
// import store from '../../../store';
import NavBar from "./navbarBlack";
// import SideBar from "./common/sidebar";
// import CartContainer from "./../../../containers/CartContainer";
// import TopBar from "./common/topbar";
import LogoImage from "./black-logo";
// import {changeCurrency} from '../../../actions'
// import {connect} from "react-redux";

class HeaderWhite extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
        setTimeout(function () {
            const loaderWrapper = document.querySelector(".loader-wrapper");
            if (loaderWrapper) {
                loaderWrapper.style.display = "none";
            }
        }, 2000);

        this.setState({ open: true });
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
            } else
                document.getElementById("sticky").classList.add('fixed');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
        }
    }

    // changeLanguage(lang) {
    //     store.dispatch(IntlActions.setLocale(lang))
    // }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if (openmyslide) {
            openmyslide.classList.add('open-side')
        }
    }
    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

    load = () => {
        this.setState({ isLoading: true });
        fetch().then(() => {
            // deal with data fetched
            this.setState({ isLoading: false })
        })
    };

    render() {

        return (
            <div>
                <header id="sticky" className="sticky">
                    {this.state.isLoading ? <Pace color="#27ae60" /> : null}
                    <div className="mobile-fix-option"></div>


                    <div className="container" style={{ padding: "0px" }}>
                        <NavBar />
                    </div>
                </header>

            </div>
        )
    }
}

export default HeaderWhite;