import React, { useState, useEffect } from 'react';
import './../../../styles/pap-main.css'; // Import the CSS file

const PapFirstSection = () => {
    const [activeIndex, setActiveIndex] = useState(true);
    const [firstIndexClicked, setFirstIndexClicked] = useState(false);
    const [secondIndexClicked, setSecondIndexClicked] = useState(false);
    const [thirdIndexClicked, setThirdIndexClicked] = useState(false);

    const handleClearClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(false);
        setThirdIndexClicked(false);
    };

    const firstHandleClick = () => {
        setFirstIndexClicked(true);
        setSecondIndexClicked(false);
        setThirdIndexClicked(false);
        box();
    };
    const secondHandleClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(true);
        setThirdIndexClicked(false);
        box();
    };
    const thirdHandleClick = () => {
        setFirstIndexClicked(false);
        setSecondIndexClicked(false);
        setThirdIndexClicked(true);
    };
    const scrollToSection = () => {
        // Find the DOM element of the target section
        const sectionElement = document.getElementById('pathways');

        // Check if the section element exists
        if (sectionElement) {
            // Scroll to the section
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [isHovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(!isHovered);
    };
    const box = () => {
        // const referenceBoxId = 'second-container'; // Replace with your actual ID
        // const referenceBox = document.getElementById(referenceBoxId);
        // const referenceHeight = referenceBox ? referenceBox.scrollHeight : 0;
        // console.log(referenceHeight);
        // // Set the height of all my-box elements to the height of the reference div
        // const myBoxIds = ['first-container', 'second-container', 'third-container']; // Replace with your actual IDs
        // myBoxIds.forEach(id => {
        //   const box = document.getElementById(id);
        //   if (box) {
        //     box.style.height = `${referenceHeight}px`;
        //   }
        // });
    }

    useEffect(() => {
        // // Get all the my-box elements
        // const myBoxes = document.querySelectorAll('.first-section-container .my-box');

        // // Find the maximum height among all my-box elements
        // const maxHeight = Math.max(...Array.from(myBoxes).map(box => box.scrollHeight));

        // // Set the height of all my-box elements to the maximum height
        // myBoxes.forEach(box => {
        //   box.style.height = `${maxHeight}px`;
        // });
    }, []);
    const firstSectionStyle = {
        paddingTop: "100px",
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/vector/pap/main/main.svg)`,
        backgroundSize: 'cover',  // or 'contain' depending on your preference
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }

    return (
        <section className="first-section-container-mobile show-in-mobile">
            <div className={`container container-box`}>
                    <div className='flex-row'>
                        <div className="my-box show-content" style={{ backgroundColor: "#000" }}>
                            <div className="container content-box" id="first-container">
                                <h2><span className='span-1'>INTERNZ</span><span className='span-2'>L</span><span className='span-3'>EARN</span></h2>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/how-it-works.svg`} alt="" style={{width:"100%"}}/>
                                <div className='container-fluid second-row-container-mobile'>
                                    <h2>Who Should Join?</h2>
                                    <p>Our Placement Assurance Programs cater to a diverse audience, making them suitable for various individuals at different stages of their careers and educational journeys. Here's who can benefit from these programs:</p>
                                    <div className='flex-row card-area'>
                                        <div className='card-box'>
                                            <h3>1. Recent Graduates:</h3>
                                            <p>Graduates who are entering the job market for the first time can bridge the gap between their academic learning and real-world job requirements through our Placement Assurance Programs.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>2. Career Changers:</h3>
                                            <p>Those transitioning to a new career or industry can gain the necessary skills and insights to succeed in their new field with our program.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>3. Job Seekers:</h3>
                                            <p>Individuals actively searching for employment but facing challenges in securing a job can benefit from our job placement support, resume building, and interview preparation.</p>
                                        </div>
                                    </div>
                                    <div className="flex-row card-area">
                                        <div className='card-box' >
                                            <h3>4. College Students:</h3>
                                            <p>Students looking to gain practical experience through internships can find relevant opportunities through our program. Some programs also offer internship placement assistance.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>5. Mid-Career Professionals:</h3>
                                            <p>Professionals seeking career advancement or more challenging roles can enhance their prospects through skill enhancement workshops and networking opportunities.</p>
                                        </div>
                                        <div className='card-box'>
                                            <h3>6. Career Re-Starters:</h3>
                                            <p>Individuals who took a career break for various reasons and are now reentering the workforce can update their skills and restart their careers with our tailored support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container content-box-2" id="second-container">
                                <h2>Programs We Offer</h2>
                                <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring to be a Java Full Stack Developer, Python Full Stack Developer, Automation Testing Expert with Selenium, or a DevOps Professional, we have the perfect program for you.</p>
                                <div className='first-row-container'>
                                    <div className='flex-row card-area'>
                                        <div className="card-box">
                                            <h3>Java Full Stack</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href="/pap-java-course"><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                        <div className="card-box">
                                            <h3>Python Full Stack</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href="/pap-python-course"><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                    </div>
                                    <div className='flex-row card-area'>
                                        <div className="card-box">
                                            <h3>DevOps</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href='/pap-dev-ops-course'><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                        <div className="card-box">
                                            <h3>Automation Testing</h3>
                                            <p>Explore our diverse range of programs designed to empower you in your journey to success. Whether you're aspiring .</p>
                                            <a href='/pap-automation-testing-course'><button>Explore now <i className="fa-solid fa-arrow-up-right-from-square"></i></button></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='second-row-container'>
                                    <h3>Career Services</h3>
                                    <p>Practice essential skills to be ready for interview possibilities. Obtain assistance with developing your online résumé and profile. Practice interviews with professionals to improve your interviewing abilities. Through a career mentoring session, get solutions to your questions about your professional life. You'll be equipped to ace interviews at the conclusion of the program</p>
                                    <div className='second-card-box'>
                                        <h6>Build an exciting resume and digital profile for recruiters</h6>
                                        <p>Craft a standout resume and LinkedIn profile to make a strong impression on recruiters, highlighting your skills and achievements for a competitive edge in the job market.</p>
                                    </div>
                                    <div className='second-card-box'>
                                        <h6>Sharpen interview skills with mock interview sessions</h6>
                                        <p>Enhance interview skills with mock sessions, getting valuable feedback on responses and body language to boost confidence and excel in job interviews.</p>
                                    </div>
                                    <div className='second-card-box'>
                                        <h6>Resolve any career-related queries have clarity and confidence</h6>
                                        <p>Consult experienced mentors or career coaches for clarity on your career path, empowering you to make informed decisions about job changes, progression, skills, and other career concerns</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    );
};

export default PapFirstSection;
