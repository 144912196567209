import React, { Component } from 'react';
import axios from 'axios';
import DynamicTable from './dynamic-table';
class Enquiries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enquiries: [],
        };
        this.getEnquiries();
    }

    getEnquiries = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}` + 'enquiries', {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data.status == true) {
            this.setState({
                enquiries: response.data.results,
            });
        }
    };
    render() {
        const columns = [
            { field: 'id', header: 'ID' },
            { field: 'name', header: 'Name' },
            { field: 'email', header: 'Email' },
            { field: 'reason', header: 'Reason' },
            { field: 'message', header: 'Message' },
            // Add more columns as needed
        ];

        return (
            <>
            <div className='container'>
                <DynamicTable columns={columns} data={this.state.enquiries} />
            </div>
                
            </>
        )
    }
}


export default Enquiries;