import React, { useState } from 'react';
import './../../../styles/common.css'
const PeerLearning = ({ color }) => {
    return (
        <>
            <section className='bg-color-161515'>
                <div className='container peer-learning'>
                    <h1>Peer Learning</h1>
                    <h4>Collaborate with fellow learners and share knowledge</h4>
                    <div className='learning-box'>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Class Notifications:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/Envelope.svg`} alt="" />
                            </div>
                            <p>Stay informed via email and GCR updates.</p>
                        </div>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Hackathons:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/CodeBlock.svg`} alt="" />
                            </div>
                            <p>Participate in coding challenges on Leetcode, Hackerrank, and HackerEarth.</p>
                        </div>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Career Services:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/SuitcaseSimple.svg`} alt="" />
                            </div>
                            <p>Access interview preparation and job application support.</p>
                        </div>
                        </div>
                        <div className='learning-box'>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Major Announcements:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/TextIndent.svg`} alt="" />
                            </div>
                            <p>Keep track of project submissions and assignments.</p>
                        </div>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Collaborative Learning:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/ArrowsIn.svg`} alt="" />
                            </div>
                            <p>Engage in enriching class discussions.</p>
                        </div>
                        <div className='learning-card'>
                            <div className='flex'>
                                <h6>Mentors:</h6>
                                <img src={`${process.env.PUBLIC_URL}/assets/vector/pap/main/peer-learning/Student.svg`} alt="" />
                            </div>
                            <p>Receive guidance and additional training through doubt-clearing sessions and retraining.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PeerLearning;

